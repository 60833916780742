<template>
	<div class="home internal-container" style="width: 100%; height: 100%">
		<q-layout class="header-bar" view="hHh Lpr fff">
			<q-toolbar class="bg-primary text-white">
				<q-toolbar-title style="padding: 12px !important"
					>Supplier Name Mappings</q-toolbar-title
				>
			</q-toolbar>
			<q-page-container>
				<div class="pa-4 layout-container">
					<div class="row">
						<div class="col-6 q-pa-md">
							<q-card class="p-0">
								<q-card-section class="bg-accent text-white">
									<div class="text-h6">New Mapped Name</div>
								</q-card-section>

								<div class="row q-pa-md">
									<div class="col-12">
										<q-form @submit="validate()" @reset="OnReset()">
											<q-input
												outlined
												style="width: 100%"
												v-model="_supplierNameMapping.Name"
												label="Mapping Name"
												lazy-rules
												:rules="[
													(val) =>
														(val && val.length > 0) || 'Please type something',
												]"
											/>
											<br />
											<q-generic-select
												:items="_SupplierStore.getSuppliers"
												name="SupplierName"
												labeling="Suppliers"
												v-model="_selectedSupplier"
												:rules="_rules"
											></q-generic-select>

											<q-space />

											<q-separator />
											<q-card-actions class="q-pa-md">
												<q-btn
													label="Submit"
													type="submit"
													color="primary"
													:loading="_isLoading"
												/>
												<q-btn
													label="Reset"
													type="reset"
													color="primary"
													flat
													class="q-ml-sm"
												/>
											</q-card-actions>
										</q-form>
									</div>
								</div>
							</q-card>
						</div>
						<div class="col-6 q-pa-md">
							<mapped-table :items="_mappingName"> </mapped-table>
						</div>
					</div>
				</div>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { BaseService } from '@/services/BaseService';
import { supplierStore } from '@/store/suppliers';
import MappedTable from '@/components/MappedTable.vue';
import QGenericSelect from '@/components/QGenericSelect.vue';

// const { validate } = useFormChild();
interface SupplierNameMapping {
	Name: string;
	SupplierId: number;
	AccountNumber: string;
}

const _rules = ref([(val: any) => val != null || 'This field is required']);
const _SupplierStore = supplierStore();
const _service = new BaseService();
const _mappingName = ref([]);
const _isLoading = ref<boolean>(false);
const _selectedSupplierId: any = ref(undefined);
const _supplierNameMapping = ref<SupplierNameMapping>({
	Name: '',
	SupplierId: _selectedSupplierId ?? 0,
	AccountNumber: '',
});
const _selectedSupplier = ref(null);

function getMappings() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/Mapping')
		.then((mapping: any) => {
			_mappingName.value = mapping;
		});
}

async function validate(): Promise<void> {
	_supplierNameMapping.value.SupplierId = _selectedSupplier.value?.SupplierId;
	_isLoading.value = true;
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/Mapping/Create',
			_supplierNameMapping.value
		)
		.then((mapping: any) => {
			_mappingName.value = mapping;
			getMappings();
			_isLoading.value = false;
		});
}

function OnReset() {
	_supplierNameMapping.value.Name = '';
	_selectedSupplier.value = null;
}
onMounted(() => {
	getMappings();
});
</script>
