<template>
	<div class="q-ma-none">
		<div id="gz-dropzone" v-show="_isDropzoneVisible">
			<div style="pointer-events: none">Drop the file here...</div>
		</div>
		<q-dialog v-model="_isConfirmDialogVisible">
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title> Upload Spreadsheet - Confirm </q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					Please confirm that you have selected the correct Supplier for the
					file you are uplading
				</q-card-section>
				<q-card-section>
					<div><b>File: </b>{{ _spreadsheetName }}</div>
					<div><b>Supplier: </b>{{ _selectedSupplier?.SupplierName }}</div>
				</q-card-section>
				<q-card-section>
					<q-checkbox
						v-model="_isUploadConfirmed"
						left-label
						label="I confirm that the supplier selected matches the file being uploaded"
					/>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Cancel"
						color="accent"
						@click="_isConfirmDialogVisible = false"
					/>
					<q-btn
						outline
						color="positive"
						label="Submit"
						:disable="!_isUploadConfirmed"
						:loading="_isLoading"
						@click="PostFile"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="_isNotifyDialogVisible">
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title> Upload Spreadsheet - Notice </q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					Spreadsheet has been sent off to the server for processing, please
					wait 15 minutes and refresh this screen.
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Confirm"
						color="accent"
						@click="_isNotifyDialogVisible = false"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="_isSupplierDialogVisible" persistent>
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title>Upload Spreadsheet - Pick Supplier</q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					<p>Uploading: {{ _spreadsheetName }}</p>
					<q-select
						outlined
						:options="_suppliers"
						v-model="_selectedSupplier"
						label="Supplier"
						:rules="_supplierSelectRules"
						:option-value="'SupplierId'"
						:option-label="'SupplierName'"
					></q-select>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Cancel"
						color="accent"
						@click="_isSupplierDialogVisible = false"
					/>
					<q-btn
						outline
						color="positive"
						label="Submit"
						@click="
							{
								_isSupplierDialogVisible = false;
								_isConfirmDialogVisible = true;
							}
						"
						:loading="_isLoading"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<style lang="scss" scoped>
.dialog-color {
	background-color: var(--q-primary);
	color: white;
}

#gz-dropzone {
	position: absolute;
	top: 64;
	background: rgba(0, 0, 0, 0.5);
	align-content: center;
	text-align: center;
	color: white;
	font-size: xxx-large;
	width: 100%;
	height: 86%;
	z-index: 20;
}
</style>

<script setup lang="ts">
//Minimal implementation for now, going to move it to components later
import { BaseService } from '@/services/BaseService';
import { DataMatchingFile } from 'greenzonegateway.classes/lib/classes';
import { Supplier } from 'greenzonegateway.classes/lib/classes';
import { onMounted, onBeforeUnmount, defineEmits, ref } from 'vue';

const emit = defineEmits(['matchedUpdate']);

const _suppliers = ref<Supplier[]>([]);
const _selectedSupplier = ref<Supplier>();
const _spreadsheet = ref<string>();
const _spreadsheetName = ref('');

const _isLoading = ref(false);
const _isDropzoneVisible = ref(false);
const _isSupplierDialogVisible = ref(false);
const _isNotifyDialogVisible = ref(false);
const _isConfirmDialogVisible = ref(false);
const _isUploadConfirmed = ref(false);

const _service = new BaseService();
const _reader = new FileReader();

const _supplierSelectRules = [(v: any) => !!v || 'Supplier is required'];

onMounted(() => {
	SubscribeEvents();
});

onBeforeUnmount(() => {
	UnsubscribeEvents();
});

function EnableDrag(event: DragEvent) {
	if (event.dataTransfer?.items.length) {
		event.dataTransfer.dropEffect = 'copy';
		event.preventDefault();
	}
}

function HandleDrop(event: DragEvent) {
	_isDropzoneVisible.value = false;
	if (event.dataTransfer?.items.length) {
		[...event.dataTransfer.items].forEach((item: DataTransferItem) => {
			if (item.kind === 'file') {
				const fileIn = item.getAsFile() as Blob;
				_reader.readAsDataURL(fileIn);
				_spreadsheetName.value = fileIn.name;
				_isSupplierDialogVisible.value = true;
				GetSuppliers();
			}
		});
	}
	event.preventDefault();
}

function ShowDropzone(event: DragEvent) {
	_isDropzoneVisible.value = true;
	event.preventDefault();
}

function HideDropzone(event?: DragEvent) {
	_isDropzoneVisible.value = false;
	_isSupplierDialogVisible.value = false;
	if (event) {
		event.preventDefault();
	}
}

function SubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.addEventListener('dragenter', ShowDropzone);
	dropzone?.addEventListener('dragenter', EnableDrag);
	dropzone?.addEventListener('dragover', EnableDrag);
	dropzone?.addEventListener('dragleave', HideDropzone);
	dropzone?.addEventListener('drop', HandleDrop);
	_reader.addEventListener(
		'load',
		() => {
			// this will then display a text file
			let result = _reader.result as string;
			if (result) {
				_spreadsheet.value = result.split(',')[1];
				_isSupplierDialogVisible.value = true;
			}
		},
		false
	);
}

function UnsubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.removeEventListener('dragenter', ShowDropzone);
	dropzone?.removeEventListener('dragenter', EnableDrag);
	dropzone?.removeEventListener('dragover', EnableDrag);
	dropzone?.removeEventListener('dragleave', HideDropzone);
	dropzone?.removeEventListener('drop', HandleDrop);
	_reader.removeEventListener(
		'load',
		() => {
			return;
		},
		false
	);
}

function GetSuppliers() {
	_isLoading.value = true;
	_service
		.Get<Supplier[]>('DataMatchingWeights/Suppliers')
		.then((suppliers) => {
			_suppliers.value = suppliers;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

type DataOut = {
	File: string;
	FileName: string;
	SupplierId: number;
};

function PostFile() {
	if (!_selectedSupplier.value || !_spreadsheet.value) {
		return;
	}
	let dataOut: DataOut = {
		File: _spreadsheet.value,
		FileName: _spreadsheetName.value,
		SupplierId: _selectedSupplier.value.SupplierId,
	};
	_isLoading.value = true;
	_service
		.Post<DataOut>('/Files/DataMatchingWeights/Upload', dataOut)
		.then(() => {
			HideDropzone();
			_isNotifyDialogVisible.value = true;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}
</script>
