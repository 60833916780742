import script from "./HomeView.vue?vue&type=script&lang=ts&setup=true"
export * from "./HomeView.vue?vue&type=script&lang=ts&setup=true"

import "./HomeView.vue?vue&type=style&index=0&id=3bc8f7a7&lang=scss"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/.pnpm/vue-cli-plugin-quasar@5.1.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QToolbarTitle,QPageSticky,QFab,QIcon,QFabAction,QBtn});
