import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-ma-none" }
const _hoisted_2 = { id: "gz-dropzone" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  align: "right",
  style: {"color":"red"}
}

import { BaseService } from '@/services/BaseService';
import { DataMatchingFile } from 'greenzonegateway.classes/lib/classes';
import { Supplier } from 'greenzonegateway.classes/lib/classes';
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';

type DataOut = {
	Files: { File: string; FileName: string }[];
	SupplierId: number | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GZDragAndDrop',
  emits: ['matchedUpdate'],
  setup(__props, { emit: __emit }) {

//Minimal implementation for now, going to move it to components later
const emit = __emit;

const _suppliers = ref<SuppAccsSupplier[]>([]);
const _selectedSupplier = ref<SuppAccsSupplier>();
const _spreadsheetName = ref('');
const _files = ref<{ File: string; FileName: string }[]>([]);

const _isLoading = ref(false);
const _isDropzoneVisible = ref(false);
const _isSupplierDialogVisible = ref(false);
const _isNotifyDialogVisible = ref(false);
const _isConfirmDialogVisible = ref(false);
const _isUploadConfirmed = ref(false);

const _service = new BaseService();

const _supplierSelectRules = [(v: any) => !!v || 'Supplier is required'];

onMounted(() => {
	SubscribeEvents();
});

onBeforeUnmount(() => {
	UnsubscribeEvents();
});

const _additionalFiles = computed(() => {
	return _files.value.slice(1);
});

function EnableDrag(event: DragEvent) {
	if (event.dataTransfer?.items.length) {
		event.dataTransfer.dropEffect = 'copy';
		event.preventDefault();
	}
}

async function HandleExtraFiles(event: InputEvent) {
	_files.value = [_files.value[0]];
	if (!event?.target?.files) {
		return;
	}
	for (let i = 0; i < event.target.files.length; i++) {
		HandleFileUpload(event.target.files[i]);
	}
}

async function HandleDrop(event: DragEvent) {
	_isDropzoneVisible.value = false;
	if (event.dataTransfer?.items.length) {
		[...event.dataTransfer.items].forEach(async (item: DataTransferItem) => {
			if (item.kind === 'file') {
				const fileIn = item.getAsFile() as Blob;
				await HandleFileUpload(fileIn);
				_spreadsheetName.value = _files.value[0].FileName;
				_isSupplierDialogVisible.value = true;
				GetSuppliers();
			}
		});
	}
	event.preventDefault();
}

function blobToBase64(fileIn: Blob) {
	return new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.onload = () => {
			const resultString = reader.result as string;
			const resultStringSplit = resultString.split(',');
			return resolve(
				btoa(resultStringSplit[resultStringSplit.length - 1] as string)
			);
		};
		reader.readAsDataURL(fileIn);
	});
}

async function HandleFileUpload(fileIn: Blob) {
	_files.value.push({
		File: await blobToBase64(fileIn),
		FileName: fileIn.name,
	});
}

function ShowDropzone(event: DragEvent) {
	_isDropzoneVisible.value = true;
	event.preventDefault();
}

function HideDropzone(event?: DragEvent) {
	_isDropzoneVisible.value = false;
	_isSupplierDialogVisible.value = false;
	if (event) {
		event.preventDefault();
	}
}

function SubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.addEventListener('dragenter', ShowDropzone);
	dropzone?.addEventListener('dragenter', EnableDrag);
	dropzone?.addEventListener('dragover', EnableDrag);
	dropzone?.addEventListener('dragleave', HideDropzone);
	dropzone?.addEventListener('drop', HandleDrop);
}

function UnsubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.removeEventListener('dragenter', ShowDropzone);
	dropzone?.removeEventListener('dragenter', EnableDrag);
	dropzone?.removeEventListener('dragover', EnableDrag);
	dropzone?.removeEventListener('dragleave', HideDropzone);
	dropzone?.removeEventListener('drop', HandleDrop);
}

class SuppAccsSupplier extends Supplier {
	constructor() {
		super();
		this.MaxFilesPerUpload = 0;
	}
	MaxFilesPerUpload: number;
}

function GetSuppliers() {
	_isLoading.value = true;
	_service
		.Get<SuppAccsSupplier[]>('DataMatchingWeights/Suppliers')
		.then((suppliers) => {
			_suppliers.value = suppliers;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostFile() {
	if (!_selectedSupplier.value || !_files.value.length) {
		console.warn('No spreadsheet detected!');
		return;
	}
	let dataOut: DataOut = {
		Files: _files.value,
		SupplierId: _selectedSupplier.value.SupplierId,
	};
	_isLoading.value = true;
	_service
		.Post<DataOut>('/Files/DataMatchingWeights/Upload', dataOut)
		.then(() => {
			_isConfirmDialogVisible.value = false;
			HideDropzone();
			_files.value = [];
			_isNotifyDialogVisible.value = true;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, _cache[9] || (_cache[9] = [
      _createElementVNode("div", { style: {"pointer-events":"none"} }, "Drop the file here...", -1)
    ]), 512), [
      [_vShow, _isDropzoneVisible.value]
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _isConfirmDialogVisible.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_isConfirmDialogVisible).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar, { class: "dialog-color" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_toolbar_title, null, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Upload Spreadsheet - Confirm ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" Please confirm that you have selected the correct Supplier for the file you are uplading ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("b", null, "File: ", -1)),
                  _createTextVNode(_toDisplayString(_spreadsheetName.value), 1)
                ]),
                _createElementVNode("div", null, [
                  _cache[13] || (_cache[13] = _createElementVNode("b", null, "Supplier: ", -1)),
                  _createTextVNode(_toDisplayString(_selectedSupplier.value?.SupplierName), 1)
                ]),
                (_additionalFiles.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _cache[14] || (_cache[14] = _createElementVNode("b", null, "Additional Files: ", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_additionalFiles.value, (file) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: file.FileName
                        }, _toDisplayString(file.FileName), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  modelValue: _isUploadConfirmed.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_isUploadConfirmed).value = $event)),
                  "left-label": "",
                  label: "I confirm that the supplier selected matches the file being uploaded"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  label: "Cancel",
                  color: "accent",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
							{
								_files.value = [];
								_isConfirmDialogVisible.value = false;
							}
						})
                }),
                _createVNode(_component_q_btn, {
                  outline: "",
                  color: "positive",
                  label: "Submit",
                  disable: !_isUploadConfirmed.value,
                  loading: _isLoading.value,
                  onClick: PostFile
                }, null, 8, ["disable", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_dialog, {
      modelValue: _isNotifyDialogVisible.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_isNotifyDialogVisible).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar, { class: "dialog-color" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_toolbar_title, null, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Upload Spreadsheet - Notice ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Spreadsheet has been sent off to the server for processing, please wait 15 minutes and refresh this screen. ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  label: "Confirm",
                  color: "accent",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_isNotifyDialogVisible.value = false))
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_dialog, {
      modelValue: _isSupplierDialogVisible.value,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_isSupplierDialogVisible).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar, { class: "dialog-color" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_toolbar_title, null, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Upload Spreadsheet - Pick Supplier")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _cache[20] || (_cache[20] = _createElementVNode("b", null, "Uploading:", -1)),
                _createTextVNode(" " + _toDisplayString(_spreadsheetName.value) + " ", 1),
                _createVNode(_component_q_select, {
                  outlined: "",
                  options: _suppliers.value,
                  modelValue: _selectedSupplier.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_selectedSupplier).value = $event)),
                  label: "Supplier",
                  rules: _supplierSelectRules,
                  "option-value": 'SupplierId',
                  "option-label": 'SupplierName'
                }, null, 8, ["options", "modelValue"]),
                (_selectedSupplier.value && _selectedSupplier.value.MaxFilesPerUpload > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[19] || (_cache[19] = _createElementVNode("b", null, "Additional Uploads: ", -1)),
                      _createElementVNode("input", {
                        type: "file",
                        multiple: "",
                        onInput: HandleExtraFiles
                      }, null, 32),
                      _createElementVNode("p", null, " Max additional files: " + _toDisplayString(_selectedSupplier.value?.MaxFilesPerUpload - 1), 1),
                      (_additionalFiles.value.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _cache[18] || (_cache[18] = _createElementVNode("b", null, "Additional Files Added: ", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_additionalFiles.value, (file) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: file.FileName
                              }, _toDisplayString(file.FileName), 1))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (_files.value.length > _selectedSupplier.value?.MaxFilesPerUpload)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Too many files uploaded! "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  label: "Cancel",
                  color: "accent",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => {
							{
								_files.value = [];
								_isSupplierDialogVisible.value = false;
							}
						})
                }),
                _createVNode(_component_q_btn, {
                  outline: "",
                  color: "positive",
                  label: "Submit",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => {
							{
								_isSupplierDialogVisible.value = false;
								_isConfirmDialogVisible.value = true;
							}
						}),
                  loading: _isLoading.value
                }, null, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})