import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"width":"230px"} }
const _hoisted_2 = {
  class: "col q-pa-md",
  style: {"width":"366px"}
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { style: {"padding-left":"56px"} }
const _hoisted_7 = { class: "row full-height padding: 8px;" }
const _hoisted_8 = {
  class: "col-10",
  style: {"align-content":"end"}
}
const _hoisted_9 = { class: "full-width row flex-center q-gutter-sm" }

import { computed, nextTick, onMounted, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { GZPagination } from '@gz/quasar-components-vue3';
import { DataMatchingFile, User } from 'greenzonegateway.classes/lib/classes';
import { mdiInformationOutline, mdiFilterVariant } from '@quasar/extras/mdi-v7';

import router from '@/router';
import GZIndicator from '@/components/GZIndicator.vue';
import GZDragAndDrop from '@/components/GZDragAndDrop.vue';

enum DataMatchingFileStatusEnum {
	Processing = 0,

	Processed = 1,

	Completed = 2,
}

interface UpdateSheetStatusDto {
	FileId: number;
	Status: DataMatchingFileStatusEnum;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OverweightDataMatching',
  setup(__props) {

const _service = new BaseService();

const _isLoading = ref(false);
const _isDialogVisible = ref(false);
const _isShowProcessing = ref(true);
const _isShowProcessed = ref(true);
const _isShowComplete = ref(false);

const _search = ref('');
const _statsData = ref();
const _items = ref<DataMatchingFile[]>([]);
const _users = ref<User[]>();
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});
const columns = [
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.SupplierName,
	},
	{
		name: 'FileName',
		label: 'File Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.FileName,
	},
	{
		name: 'AssignedTo',
		label: 'Assigned To',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.AssignedToFullName || 'Unassigned',
	},
	{
		name: 'ImportDate',
		label: 'Import Date',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.ImportDate.Display,
	},
	{
		name: 'UploadedBy',
		label: 'Uploaded By',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.UploadedByFullName,
	},
];

const _itemsComputed = computed(() => {
	if (!_items.value.length) {
		return [];
	}

	let itemsToReturn: DataMatchingFile[] = [];

	if (_isShowProcessing.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 0)
		);
	}
	if (_isShowProcessed.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 1)
		);
	}
	if (_isShowComplete.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 2)
		);
	}

	if (_search.value) {
		const needle = _search.value.toLowerCase();
		itemsToReturn = itemsToReturn.filter((row: DataMatchingFile) => {
			return (
				row.SupplierName?.toLowerCase().includes(needle) ||
				row.FileName?.toLowerCase().includes(needle) ||
				row.AssignedToFullName?.toLowerCase().includes(needle) ||
				row.ImportDate?.Display.toLowerCase().includes(needle) ||
				row.UploadedByFullName?.toLowerCase().includes(needle)
			);
		});
	}

	return itemsToReturn;
});

onMounted(() => {
	GetSpreadSheetFiles();
	GetUsers();
});

function FiltersEnabled() {
	if (
		_isShowProcessing.value ||
		_isShowProcessed.value ||
		_isShowComplete.value
	) {
		return true;
	}
	return false;
}

function GetFileStatusColor(number: number) {
	if (number === 2) {
		return 'var(--q-positive)';
	} else if (number === 1) {
		return 'var(--q-warning)';
	} else if (number === 0) {
		return 'var(--q-negative)';
	}
}

function GetToolTipStatus(enumNum: number) {
	return DataMatchingFileStatusEnum[enumNum];
}

function GetMarkSheetOptionName(option: DataMatchingFileStatusEnum) {
	switch (option) {
		case DataMatchingFileStatusEnum.Processed:
			return 'Complete';
		case DataMatchingFileStatusEnum.Completed:
			return 'Revert Complete';
		default:
			return 'Still Processing...';
	}
}

function OpenDataMatchingDetails(row: { Id: number }) {
	router.push({
		path: `/supplieraccounts/weightdatamatching/spreadsheets/details/${row.Id}`,
	});
}

function ChangeSheetStatus(row: DataMatchingFile, index: number) {
	switch (row.Status) {
		case DataMatchingFileStatusEnum.Processed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Completed);
			break;
		case DataMatchingFileStatusEnum.Completed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Processed);
			break;
		default:
			return;
	}
}

function GetStats() {
	_service.Get<any>('/DataMatchingWeights/Statistics').then((dataIn: any) => {
		_statsData.value = dataIn;
		_statsData.value.Percentage += '%';
	});
}

function GetUsers() {
	if (_users.value && _users.value.length) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<User[]>('DataMatchingWeights/Users')
		.then((response) => {
			_users.value = response;
		})
		.catch((err) => {
			console.log(err);
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function GetSpreadSheetFiles() {
	_items.value = [];
	_service
		.Get<DataMatchingFile[]>('DataMatchingWeights/Processed')
		.then((response) => {
			_items.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

function PostAssignUser(UserId: string, FileId: number) {
	let data: { UserId: string; FileId: number } = {
		UserId,
		FileId,
	};
	_service
		.Post('DataMatchingWeights/AssignUser', data)
		.then((response: DataMatchingFile[]) => {
			if (!_items.value) {
				return;
			}
			let fileIndex = _items.value?.findIndex((file) => file.Id === FileId);
			_items.value[fileIndex] = response[0];
			_items.value.push(); //Just for vue reactivity
		})
		.catch((err) => {
			console.log(err);
		});
}

function PostSheetStatus(id: number, status: DataMatchingFileStatusEnum) {
	let data: UpdateSheetStatusDto = {
		FileId: id,
		Status: status,
	};
	_service
		.Post<UpdateSheetStatusDto>('/DataMatchingWeights/UpdateSheetStatus', data)
		.then((response: DataMatchingFile[]) => {
			if (_items.value) {
				const index = _items.value.findIndex(
					(item: DataMatchingFile) => item.Id === response[0].Id
				);
				_items.value[index] = response[0];
				_items.value.push(); //Just for vue reactivity
			}
		});
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pr-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, null, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Weight Data Matching - Matched Spreadsheets")
          ])),
          _: 1
        }),
        _createVNode(_component_q_input, {
          type: "text",
          outlined: "",
          dense: "",
          label: "Filter",
          modelValue: _search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_search).value = $event)),
          dark: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          round: "",
          flat: !FiltersEnabled(),
          outline: FiltersEnabled(),
          size: "large",
          class: "q-ml-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _unref(mdiFilterVariant),
              size: "md"
            }, null, 8, ["name"]),
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("span", null, "Filters", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_menu, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_q_toggle, {
                    modelValue: _isShowProcessing.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_isShowProcessing).value = $event)),
                    label: "Show Processing"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_toggle, {
                    modelValue: _isShowProcessed.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_isShowProcessed).value = $event)),
                    label: "Show Processed"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_toggle, {
                    modelValue: _isShowComplete.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_isShowComplete).value = $event)),
                    label: "Show Completed"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["flat", "outline"]),
        _createVNode(_component_q_btn, {
          onClick: GetStats,
          round: "",
          flat: "",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _unref(mdiInformationOutline),
              size: "md"
            }, null, 8, ["name"]),
            _createVNode(_component_q_menu, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "row" }, [
                    _createElementVNode("h5", null, "Overall Datamatching Statistics")
                  ], -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "row" }, [
                    _createElementVNode("h6", null, "(for the past 3 months)")
                  ], -1)),
                  _createElementVNode("div", _hoisted_3, " Imported Lines: " + _toDisplayString(_statsData.value?.TotalImportedLines || '...Loading'), 1),
                  _createElementVNode("div", _hoisted_4, " Auto Saved Lines: " + _toDisplayString(_statsData.value?.TotalLinesAutoSaved || '...Loading'), 1),
                  _createElementVNode("div", _hoisted_5, " Overall Percentage: " + _toDisplayString(_statsData.value?.Percentage || '...Loading'), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(GZDragAndDrop),
      _createVNode(_component_q_table, {
        columns: columns,
        rows: _itemsComputed.value,
        loading: _isLoading.value,
        pagination: _pagination.value,
        "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_pagination).value = $event)),
        style: {"height":"80vh","position":"relative","width":"100%"},
        "hide-pagination": "",
        "row-key": "Id",
        selection: "single",
        "binary-state-sort": "",
        "return-object": "",
        flat: "",
        "table-header-style": {
					backgroundColor: _ctx.$q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}
      }, {
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_td, null, {
                default: _withCtx(() => [
                  _createVNode(GZIndicator, {
                    color: GetFileStatusColor(props.row.Status)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(GetToolTipStatus(props.row.Status)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ]),
                _: 2
              }, 1024),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                return (_openBlock(), _createBlock(_component_q_td, {
                  style: {"padding":"0px","padding-left":"16px"},
                  key: col.name,
                  props: props,
                  onClick: ($event: any) => (OpenDataMatchingDetails(props.row))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(GetToolTipStatus(props.row.Status)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("p", null, _toDisplayString(col.value), 1)
                      ])
                    ]),
                    _createVNode(_component_q_menu, {
                      "context-menu": "",
                      "touch-position": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_list, {
                          dense: "",
                          style: {"width":"200px"}
                        }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                              clickable: "",
                              style: {"padding":"16px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, {
                                  onClick: ($event: any) => (ChangeSheetStatus(props.row, props.rowIndex))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(GetMarkSheetOptionName(props.row.Status)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)), [
                              [_directive_close_popup]
                            ]),
                            _createVNode(_component_q_item, {
                              clickable: "",
                              style: {"padding":"16px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item_section, null, {
                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                    _createTextVNode(" Asign to... ")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_q_item_section, { side: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, { name: "mdi-chevron-right" })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_menu, {
                                  anchor: "top end",
                                  self: "top start"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_list, {
                                      dense: "",
                                      style: {"width":"200px"}
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_users.value, (user) => {
                                          return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                            key: user.UserId,
                                            onClick: ($event: any) => (
														PostAssignUser(
															user.AzureId.toString(),
															props.row.Id
														)
													),
                                            disable: _isLoading.value,
                                            clickable: "",
                                            style: {"padding":"16px"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_item_section, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(user.FullName), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick", "disable"])), [
                                            [_directive_close_popup]
                                          ])
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props", "onClick"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "no-data": _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_q_icon, {
              size: "2em",
              name: "mdi-alert"
            }),
            _cache[12] || (_cache[12] = _createElementVNode("span", null, "No data available", -1))
          ])
        ]),
        _: 1
      }, 8, ["rows", "loading", "pagination", "table-header-style"]),
      _createVNode(_unref(GZPagination), {
        "items-length": _itemsComputed.value.length,
        pagination: _pagination.value,
        "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_pagination).value = $event))
      }, null, 8, ["items-length", "pagination"])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _isDialogVisible.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_isDialogVisible).value = $event)),
      style: {"width":"500px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { height: "150px" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_space),
                _withDirectives((_openBlock(), _createBlock(_component_q_btn, { color: "primary" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })), [
                  [_directive_close_popup]
                ]),
                _createVNode(_component_q_space)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})