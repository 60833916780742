<template>
	<div class="q-gutter-md col-12" style="margin-left: 0">
		<!-- <q-badge color="secondary" multi-line> Model: "{{ model }}" </q-badge> -->
		<q-select
			outlined
			:model-value="modelValue"
			:options="_options"
			:label="labeling"
			:rules="rules"
			:loading="loading"
			:disable="disable"
			@input="$emit('update:modelValue', $event.target.value)"
			@filter="filterFn"
			@update:modelValue="handleModelValueUpdate"
			use-input
			map-options
			emit-value
			clearable
			options-selected-class="text-deep-orange"
			dropdown-icon="mdi-menu-down"
			clear-icon="mdi-close"
			no-error-icon
		>
			<template v-slot:no-option>
				<q-item>
					<q-item-section class="text-grey"> No results </q-item-section>
				</q-item>
			</template>

			<template v-slot:selected-item="scope">
				<q-chip
					dense
					@remove="scope.removeAtIndex(scope.index)"
					:tabindex="scope.tabindex"
					color="white"
					text-color="secondary"
					class="q-ma-none"
					v-if="GetLabel(scope, name) != ''"
				>
					<q-avatar rounded color="primary" text-color="white">
						{{ GetLabel(scope, name) }}</q-avatar
					>
					{{ GetName(scope, name) }}
				</q-chip>
			</template>
			<template v-slot:option="scope">
				<q-item v-bind="scope.itemProps">
					<q-item-section avatar>
						<q-avatar rounded color="primary" text-color="white">
							{{ GetLabel(scope, name) }}</q-avatar
						>
					</q-item-section>
					<q-item-section>
						<q-item-label> {{ GetName(scope, name) }}</q-item-label>
						<q-item-label> {{ GetSubName(scope, secondLabel) }}</q-item-label>
						<q-item-label v-if="labeling === 'Customer'">{{
							scope.opt.CustomerAddress.Postcode
						}}</q-item-label>
					</q-item-section>
				</q-item>
			</template>
		</q-select>
	</div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
	items: Array,
	name: String,
	subName: String,
	labeling: String,
	secondLabel: String,
	postcodeSearch: Boolean,
	loading: Boolean,
	disable: Boolean,
	modelValue: null,
	rules: null,
});
const _options = ref(props.items);

const filterFn = (val: string, update: (callback: () => void) => void) => {
	if (val === '') {
		update(() => {
			_options.value = props.items;
		});
		return;
	}
	update(() => {
		const needle = val.toLowerCase();

		_options.value = _options.value.filter(
			(item) => item[props.name].toLowerCase().indexOf(needle) > -1
		);

		return;
	});
};
function GetLabel(item: any, parameter: string) {
	if (!item.opt[parameter]) return '';
	else
		return item.opt[parameter]
			.split(' ')
			.slice(0, 2)
			.map((n) => n[0])
			.join('');
}

function GetName(name: object, parameter: string) {
	if (!name.opt[parameter]) return '';
	else return name.opt[parameter];
}
function GetSubName(name: any, parameter: string) {
	if (!name.opt[parameter]) return '';
	else return name.opt[parameter];
}
const emit = defineEmits(['update:modelValue']);

const handleModelValueUpdate = (newValue: any) => {
	emit('update:modelValue', newValue);
};
</script>
