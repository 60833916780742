import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-gutter-md col-12",
  style: {"margin-left":"0"}
}

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'QGenericSelect',
  props: {
	items: Array,
	name: String,
	subName: String,
	labeling: String,
	secondLabel: String,
	postcodeSearch: Boolean,
	loading: Boolean,
	disable: Boolean,
	modelValue: null,
	rules: null,
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;
const _options = ref(props.items);

const filterFn = (val: string, update: (callback: () => void) => void) => {
	if (val === '') {
		update(() => {
			_options.value = props.items;
		});
		return;
	}
	update(() => {
		const needle = val.toLowerCase();

		_options.value = _options.value.filter(
			(item) => item[props.name].toLowerCase().indexOf(needle) > -1
		);

		return;
	});
};
function GetLabel(item: any, parameter: string) {
	if (!item.opt[parameter]) return '';
	else
		return item.opt[parameter]
			.split(' ')
			.slice(0, 2)
			.map((n) => n[0])
			.join('');
}

function GetName(name: object, parameter: string) {
	if (!name.opt[parameter]) return '';
	else return name.opt[parameter];
}
function GetSubName(name: any, parameter: string) {
	if (!name.opt[parameter]) return '';
	else return name.opt[parameter];
}
const emit = __emit;

const handleModelValueUpdate = (newValue: any) => {
	emit('update:modelValue', newValue);
};

return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_select, {
      outlined: "",
      "model-value": __props.modelValue,
      options: _options.value,
      label: __props.labeling,
      rules: __props.rules,
      loading: __props.loading,
      disable: __props.disable,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      onFilter: filterFn,
      "onUpdate:modelValue": handleModelValueUpdate,
      "use-input": "",
      "map-options": "",
      "emit-value": "",
      clearable: "",
      "options-selected-class": "text-deep-orange",
      "dropdown-icon": "mdi-menu-down",
      "clear-icon": "mdi-close",
      "no-error-icon": ""
    }, {
      "no-option": _withCtx(() => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { class: "text-grey" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" No results ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      "selected-item": _withCtx((scope) => [
        (GetLabel(scope, __props.name) != '')
          ? (_openBlock(), _createBlock(_component_q_chip, {
              key: 0,
              dense: "",
              onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
              tabindex: scope.tabindex,
              color: "white",
              "text-color": "secondary",
              class: "q-ma-none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_avatar, {
                  rounded: "",
                  color: "primary",
                  "text-color": "white"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(GetLabel(scope, __props.name)), 1)
                  ]),
                  _: 2
                }, 1024),
                _createTextVNode(" " + _toDisplayString(GetName(scope, __props.name)), 1)
              ]),
              _: 2
            }, 1032, ["onRemove", "tabindex"]))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((scope) => [
        _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_avatar, {
                  rounded: "",
                  color: "primary",
                  "text-color": "white"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(GetLabel(scope, __props.name)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(GetName(scope, __props.name)), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_q_item_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(GetSubName(scope, __props.secondLabel)), 1)
                  ]),
                  _: 2
                }, 1024),
                (__props.labeling === 'Customer')
                  ? (_openBlock(), _createBlock(_component_q_item_label, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(scope.opt.CustomerAddress.Postcode), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040)
      ]),
      _: 1
    }, 8, ["model-value", "options", "label", "rules", "loading", "disable"])
  ]))
}
}

})