<template>
	<q-card style="width: 800px">
		<q-toolbar class="bg-accent text-white">
			<q-toolbar-title class="text-h5">Bulk Update Invoice</q-toolbar-title>
		</q-toolbar>
		<q-form ref="bulkModalForm">
			<q-card-section>
				<div class="row-6 q-pb-md" v-if="type === 2">
					<GZGenericSelect
						:multiple="false"
						:items="_suppliersStore.getSuppliers"
						name="SupplierName"
						label="Supplier"
						v-model="_supplier"
						@update:modelValue="_delaySageReferences"
						:rules="_nameRules"
					></GZGenericSelect>

					<GZGenericSelect
						:multiple="false"
						:items="_sageReferences"
						v-model="_sageReference"
						name="Reference"
						label="Sage Reference"
						:rules="_nameRules"
					></GZGenericSelect>
				</div>
				<div class="row-6 q-pb-md" v-else-if="type === 1">
					<GZGenericSelect
						:multiple="false"
						:loading="_customersStore.isLoading"
						:disabled="_customersStore.isLoading"
						:items="_customers"
						name="CustomerName"
						label="Customer"
						v-model="_customer"
						:rules="_nameRules"
					></GZGenericSelect>

					<q-input
						label="Site Postcode Search"
						outlined
						@update:modelValue="_delayedGetSites"
						v-model="_postcode"
					></q-input>
				</div>
				<div class="row-6 q-pb-md" v-else>
					<q-input
						label="From Date"
						v-model="_updatedStartDate"
						outlined
						:rules="[fromToDateRule, requiredDateRule]"
						reactive-rules
					>
						<template v-slot:append>
							<q-icon name="mdi-calendar-range" class="cursor-pointer">
								<q-popup-proxy
									cover
									transition-show="scale"
									transition-hide="scale"
								>
									<q-date
										v-model="_updatedStartDate"
										mask="DD/MM/YYYY"
										title="Date"
									>
										<div class="row items-center justify-end">
											<q-btn v-close-popup label="Close" color="primary" flat />
										</div>
									</q-date>
								</q-popup-proxy>
							</q-icon>
						</template>
					</q-input>

					<q-input
						label="To Date"
						v-model="_updatedEndDate"
						:rules="[fromToDateRule, requiredDateRule]"
						reactive-rules
						outlined
					>
						<template v-slot:append>
							<q-icon name="mdi-calendar-range" class="cursor-pointer">
								<q-popup-proxy
									cover
									transition-show="scale"
									transition-hide="scale"
								>
									<q-date v-model="_updatedEndDate" mask="DD/MM/YYYY">
										<div class="row items-center justify-end">
											<q-btn v-close-popup label="Close" color="primary" flat />
										</div>
									</q-date>
								</q-popup-proxy>
							</q-icon>
						</template>
					</q-input>
				</div>
			</q-card-section>

			<q-card-actions align="right">
				<q-btn
					flat
					label="Cancel"
					color="accent"
					@click="_emit('close', true)"
				/>
				<q-btn
					outline
					color="positive"
					label="Submit"
					@click.prevent.stop="Submit()"
					:loading="_isLoading"
				/>
			</q-card-actions>
		</q-form>
	</q-card>
</template>
<script lang="ts" setup>
import { defineProps, ref, defineEmits, computed } from 'vue';
import { date, debounce } from 'quasar';
import { BaseService } from '@/services/BaseService';
import moment from 'moment';
import { customerStore } from '@/store/customer';
import { supplierStore } from '@/store/suppliers';
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import {
	ICustomer,
	ISite,
	Supplier,
} from 'greenzonegateway.classes/lib/classes';
import { ISageReference } from 'greenzonegateway.classes/lib/classes/Generic';

const _nameRules = [(v: string) => !!v || 'Field is required'];
const _supplierNameRules = [
	(v: string) => v != null || 'This field is required',
];

const _customersStore = customerStore();
const _suppliersStore = supplierStore();
const _filteredCustomers = ref<ICustomer[]>([]);
const _service = new BaseService();
const _emit = defineEmits(['close', 'update:bulk']);
const _customer = ref<ICustomer | null>(null);
const _supplier = ref<Supplier | null>(null);
const _isLoading = ref(false);
const _updatedItems = ref();
const _updatedStartDate = ref();
const _updatedEndDate = ref();
const startDate = ref();
const endDate = ref();
const _postcode = ref('');
const _site = ref<ISite[]>([]);
const props = defineProps({
	selectedItems: Array<any>,
	type: Number,
});
const bulkModalForm = ref();

const fromToDateRule = (value: string): boolean | string => {
	const from = date.extractDate(_updatedStartDate.value, 'DD/MM/YYYY');
	const to = date.extractDate(_updatedEndDate.value, 'DD/MM/YYYY');

	return from <= to || 'From Date must be earlier than or equal to To Date';
};

const requiredDateRule = (value: string): boolean | string => {
	return value ? true : 'Date Required';
};

const _sageReferences = ref<ISageReference[]>();
const _sageReference = ref<ISageReference | null>();
const _delaySageReferences = debounce(GetSageReferences, 500);
const _delayedGetSites = debounce(GetPostCodes, 500);

async function GetSageReferences() {
	if (_supplier.value?.SupplierId) {
		_sageReferences.value = [];
		_sageReference.value = null;
		_service
			.Get<any>(
				'/Suppliers/List/SageReferences?supplierId=' +
					_supplier.value.SupplierId
			)
			.then((response: any) => {
				let list = [];
				list = response;
				for (let i = 0; i < list.length; i++) {
					_sageReferences.value?.push(list[i].SageReference);
				}
			});
	}
}
const _customers = computed({
	get() {
		return _filteredCustomers.value.length > 0
			? _filteredCustomers.value
			: _customersStore.customers;
	},
	set(val) {
		_customersStore.customers = val;
	},
});

async function GetPostCodes() {
	_customersStore.isLoading = true;
	_service
		.Get<any>('/Customers/AI/GetPostCodes?postcode=' + _postcode.value)
		.then((postcode: any) => {
			_site.value = postcode;
			let list = [];
			for (let i = 0; i < _site.value.length; i++) {
				list.push(_site.value[i].Customer);
			}

			_filteredCustomers.value = [...new Set(list)] as ICustomer[];
		})
		.finally(() => {
			_customersStore.isLoading = false;
		});
}
async function Submit(): Promise<void> {
	_isLoading.value = true;
	_updatedItems.value = props.selectedItems;
	let ids: any[] = [];
	for (let i = 0; i < _updatedItems.value.length; i++) {
		ids.push(_updatedItems.value[i].ProcessedSupplierInvoice.Id);
	}
	if (props.type === 1) {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/CustomerId',
								value: _customer.value?.CustomerId ?? 0,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.Customer =
								_customer.value;
							_emit('update:bulk', _updatedItems);
						}
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	} else if (props.type === 2) {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/SupplierId',
								value: _supplier.value?.SupplierId ?? 0,
							},
							{
								op: 'replace',
								path: '/SageReferenceId',
								value: _sageReference.value?.Id ?? null,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.Supplier =
								_supplier.value;
							_updatedItems.value[i].ProcessedSupplierInvoice.SageReference =
								_sageReference.value;
							_emit('update:bulk', _updatedItems);
						}
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	} else {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				startDate.value =
					moment(_updatedStartDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
					'T00:00:00';
				endDate.value =
					moment(_updatedEndDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
					'T00:00:00';
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/StartDate',
								value: startDate.value,
							},
							{
								op: 'replace',
								path: '/EndDate',
								value: endDate.value,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.StartDate =
								startDate;
							_updatedItems.value[i].ProcessedSupplierInvoice.EndDate = endDate;
						}
						_emit('update:bulk', _updatedItems);
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	}
}
</script>
