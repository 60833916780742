<template>
	<q-layout>
		<q-page-container>
			<GZLoadingData
				v-if="!userStore().IsLoggedIn"
				:loadingDataText="
					!userStore().IsLoggedIn
						? 'Please Login Using the Popup Provided'
						: 'Logging In...'
				"
				:project="'Supplier Accounts'"
			></GZLoadingData>
			<router-view v-else />
		</q-page-container>
	</q-layout>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue';
import { useQuasar } from 'quasar';
const $q = useQuasar();
import { GZLoadingData } from '@gz/quasar-components-vue3';
import { userStore } from '@/store/users';
onBeforeMount(() => {
	userStore().InitialiseSubscriptions();
	const storedTheme = localStorage.getItem('dark_theme');
	if (storedTheme === 'true') {
		if (storedTheme === 'true') {
			$q.dark.set(true);
		} else {
			$q.dark.set(false);
		}
	}
});
</script>

<style scoped lang="scss">
.internal-container {
	height: 100%;
}
</style>

<style lang="scss">
@import './styles/base-styles.scss';

.layout-container {
	margin-left: 56px;
}
.internal-container {
	@include devices(tablet) {
		overflow-y: scroll;
		overflow-x: hidden;
	}

	@include devices(mobile) {
	}
}

.layout-container {
	margin-left: 56px;
	height: calc(100% - var(--header-bar-height));

	@include devices(tablet) {
		margin-left: 0;
	}

	@include devices(mobile) {
	}
}

/* height */
::-webkit-scrollbar {
	height: 11px;
}
/* width */
::-webkit-scrollbar {
	width: 11px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	/* background: var(--v-primary-darken2); */
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.q-chip .row {
	flex-wrap: nowrap !important;
}

.q-select .q-field__input {
	width: auto !important;
}
</style>
