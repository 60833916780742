<template>
	<div style="position: absolute; height: calc(100% - 64px); width: 100%">
		<q-toolbar class="bg-accent text-white q-pr-none">
			<q-toolbar-title
				>Weight Data Matching - Matched Spreadsheets</q-toolbar-title
			>
			<q-input
				type="text"
				v-model="_wildcardSearch"
				label="Search"
				outlined
				dense
				dark
			></q-input>
			<q-btn :icon="mdiTrashCan" @click="_isBinsMode = true" class="q-ml-sm"
				>Bins</q-btn
			>
			<q-btn :icon="mdiHome" @click="_isBinsMode = false" class="q-mx-sm"
				>Sites</q-btn
			>
		</q-toolbar>
		<div
			style="
				position: absolute;
				overflow-y: auto;
				height: calc(100% - 64px);
				width: calc(100% - 56px);
				left: 56px;
			"
			v-if="_isBinsMode"
		>
			<q-table
				:columns="_columnsBins"
				:rows="_computedBins"
				:loading="_isLoading"
				title="Bins"
				v-model:pagination="_pagination"
				style="height: calc(100% - 56px)"
				hide-pagination
				row-key="name"
				binary-state-sort
				return-object
				flat
				:table-header-style="{
					backgroundColor: $q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}"
			>
				<template #body-cell="props">
					<q-td
						:props="props"
						@click="OpenBinDialog(props.row)"
						class="bin-site-overflow"
					>
						{{ props.value }}
					</q-td>
					<q-menu context-menu touch-position>
						<q-list dense style="width: 200px">
							<q-item
								clickable
								v-close-popup
								@click="RemoveRow(props.row, 0)"
								style="padding: 16px"
							>
								<q-item-section> Delete </q-item-section>
							</q-item>
						</q-list>
					</q-menu>
				</template>
				<template #body-cell-SiteAddress="props">
					<q-td :props="props">
						{{ props.row.MappedSite?.Address?.AddressLine1 }} <br />
						{{ props.row.MappedSite?.Address?.Postcode }} <br />
						{{ props.row.MappedSite?.Address?.Town }}
					</q-td>
				</template>

				<template v-slot:no-data>
					<div class="full-width row flex-center q-gutter-sm">
						<q-icon size="2em" name="mdi-alert" />
						<span>No data available</span>
					</div>
				</template>
			</q-table>
			<GZPagination
				:items-length="_computedBins.length"
				v-model:pagination="_pagination"
			></GZPagination>
		</div>
		<div
			style="
				position: absolute;
				overflow-y: auto;
				height: calc(100% - 64px);
				width: calc(100% - 56px);
				left: 56px;
			"
			v-else
		>
			<q-table
				:columns="_columnsSites"
				:rows="_computedSites"
				:loading="_isLoading"
				title="Sites"
				v-model:pagination="_pagination2"
				style="height: calc(100% - 56px)"
				hide-pagination
				row-key="Id"
				binary-state-sort
				return-object
				flat
				:table-header-style="{
					backgroundColor: $q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}"
			>
				<template #body-cell="props">
					<q-td
						:props="props"
						@click="OpenSiteDialog(props.row)"
						class="bin-site-overflow"
					>
						{{ props.value }}
					</q-td>
					<q-menu context-menu touch-position>
						<q-list dense style="width: 200px">
							<q-item
								clickable
								v-close-popup
								@click="RemoveRow(props.row, 1)"
								style="padding: 16px"
							>
								<q-item-section> Delete </q-item-section>
							</q-item>
						</q-list>
					</q-menu>
				</template>
				<template v-slot:no-data>
					<div class="full-width row flex-center q-gutter-sm">
						<q-icon size="2em" name="mdi-alert" />
						<span>No data available</span>
					</div>
				</template>
			</q-table>
			<GZPagination
				:items-length="_computedSites.length"
				v-model:pagination="_pagination2"
			></GZPagination>
		</div>
		<SiteMappingModal
			ref="siteMappingModal"
			@submit="SiteDialogComplete"
			:isBinSiteMode="true"
			:siteMappingData="_siteMappingData"
		></SiteMappingModal>
		<BinMappingModal
			ref="binMappingModal"
			@submit="BinDialogComplete"
			:isBinSiteMode="true"
			:binMappingData="_binMappingData"
		></BinMappingModal>
	</div>
</template>

<script setup lang="ts">
import { BaseService } from '@/services/BaseService';
import { onMounted, ref, nextTick, computed } from 'vue';
import { GZPagination } from '@gz/quasar-components-vue3';
import {
	SiteMappingModal,
	BinMappingModal,
} from '../../components/WeightDataMatching';
import { mdiTrashCan, mdiHome } from '@quasar/extras/mdi-v7';

const _service = new BaseService();

const _columnsBins = ref([
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		style: 'width: 320px; max-width: 320px',
		field: (row: any) => row.Supplier?.SupplierName,
	},
	{
		name: 'Bin',
		label: 'Bin',
		align: 'left',
		sortable: true,
		style: 'width: 300px; max-width: 300px',
		field: (row: any) => row.Bin,
	},
	{
		name: 'WasteStream',
		label: 'Waste Stream',
		align: 'left',
		sortable: true,
		style: 'width: 250px; max-width: 250px',
		field: (row: any) => row.WasteStream,
	},
	{
		name: 'WasteStreamGroup',
		label: 'Waste Stream Group',
		align: 'left',
		sortable: true,
		style: 'width: 180px; max-width: 180px',
		field: (row: any) => row.WasteStreamGroup,
	},
	{
		name: 'SiteAddress',
		label: 'Site Address',
		align: 'left',
		sortable: true,
		style: 'width: 180px; max-width: 180px',
		field: (row: any) => row.MappedSite?.SiteReference || 'N/A',
	},
	{
		name: 'CreatedTime',
		label: 'Created Time',
		align: 'left',
		sortable: true,
		style: 'width: 160px; max-width: 160px',
		field: (row: any) => row.CreatedDate?.Display,
	},
	{
		name: 'CreatedBy',
		label: 'Created By',
		align: 'left',
		sortable: true,
		style: 'width: 200px; max-width: 200px',
		field: (row: any) => row.CreatedBy,
	},
	{
		name: 'LastUsed',
		label: 'Last Used',
		align: 'left',
		sortable: true,
		style: 'width: 160px; max-width: 160px',
		field: (row: any) => row.LastUsedDate?.Display,
	},
]);

const _columnsSites = ref([
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		style: 'width: 320px; max-width: 320px',
		field: (row: any) => row.Supplier?.SupplierName,
	},
	{
		name: 'SiteDescription',
		label: 'Site Description',
		align: 'left',
		style: 'width: 570px; max-width: 570px',

		sortable: true,
		field: (row: any) => row.SiteDescription,
	},
	{
		name: 'SiteRef',
		label: 'Site Ref',
		align: 'left',
		sortable: true,
		style: 'width: 380px; max-width: 380px',
		field: (row: any) => row.SiteRef,
	},
	{
		name: 'SitePostcode',
		label: 'Site Postcode',
		align: 'left',
		sortable: true,
		field: (row: any) => row.SitePostcode,
	},
	{
		name: 'CreatedTime',
		label: 'Created Time',
		align: 'left',
		sortable: true,
		field: (row: any) => row.CreatedDate?.Display,
	},
	{
		name: 'CreatedBy',
		label: 'Created By',
		align: 'left',
		style: 'width: 200px; max-width: 200px',
		sortable: true,
		field: (row: any) => row.CreatedBy,
	},
	{
		name: 'LastUsed',
		label: 'Last Used',
		align: 'left',
		sortable: true,
		field: (row: any) => row.LastUsedDate?.Display,
	},
]);

const _itemsBins = ref([]);
const _itemsSites = ref([]);

const siteMappingModal = ref<typeof SiteMappingModal>();
const binMappingModal = ref<typeof BinMappingModal>();

const _siteMappingData = ref();
const _binMappingData = ref();

const _binSelected = ref();
const _siteSelected = ref();

const _wildcardSearch = ref('');

const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _pagination2 = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _isBinsMode = ref(false);
const _isLoading = ref(false);

const _computedBins = computed(() => {
	if (_wildcardSearch.value && _isBinsMode.value) {
		return _itemsBins.value.filter((bin: any) => {
			return (
				bin.Supplier?.SupplierName?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.Bin?.toLowerCase().includes(_wildcardSearch.value.toLowerCase()) ||
				bin.WasteStream?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.WasteStreamGroup?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.SiteReference?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.AddressLine1?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.Postcode?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.Town?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.CreatedBy?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.CreatedDate?.Display.includes(_wildcardSearch.value.toLowerCase())
			);
		});
	}
	return _itemsBins.value;
});

const _computedSites = computed(() => {
	if (_wildcardSearch.value && !_isBinsMode.value) {
		return _itemsSites.value.filter((site: any) => {
			return (
				site.Supplier?.SupplierName?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SiteDescription?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SiteRef?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SitePostcode?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.CreatedDate?.Display?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.CreatedBy?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.LastUsedDate?.Display?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				)
			);
		});
	}
	return _itemsSites.value;
});

onMounted(() => {
	GetDataBins();
	GetDataSites();
});

function OpenBinDialog(row: any) {
	_binSelected.value = row;
	const payload = {
		GroupedResult: {
			SupplierName: row?.Supplier?.SupplierName,
			MatchedSiteName: row.MappedSite?.SiteName,
			MatchedSiteMap: { SiteId: row.MappedSite?.SiteId },
		},
		BinResult: {
			MatchedBinMapping: {
				WasteStreamGroup: row.WasteStreamGroup,
				WasteStream: row.WasteStream,
				Bin: row.Bin,
				Id: row.Id,
			},
		},
		MappedBinResult: {
			ServiceTypeName: row.MappedService?.ServiceTypeName,
			ServiceDescription: row.MappedService?.ServiceDescription,
		},
	};
	_binMappingData.value = payload;
	nextTick(() => {
		binMappingModal.value?.Open();
	});
}

function OpenSiteDialog(row: any) {
	_siteSelected.value = row;
	const payload = {
		GroupedResult: {
			SupplierName: row?.Supplier?.SupplierName,
			MatchedSiteName: row.MappedSite?.SiteName,
			MatchedSitePostcode: row.MappedSite?.Address?.Postcode,
			MatchedSiteAddress: row.MappedSite?.Address?.AddressLine1,
			SupplierId: row.Supplier?.SupplierId,
		},
	};
	_siteMappingData.value = payload;
	nextTick(() => {
		siteMappingModal.value?.Open();
	});
}

function BinDialogComplete(ServiceId: number) {
	const operations = [
		{
			op: 'replace',
			path: '/ServiceId',
			value: ServiceId,
		},
	];
	nextTick(() => {
		UpdateBinRow(_binSelected.value, operations);
	});
}

function SiteDialogComplete(SiteId: number) {
	const operations = [
		{
			op: 'replace',
			path: '/SiteId',
			value: SiteId,
		},
	];
	nextTick(() => {
		UpdateSiteRow(_siteSelected.value, operations);
	});
}

function RemoveRow(row: any, siteBin: number) {
	const operations = [
		{
			op: 'replace',
			path: '/Status',
			value: 2, //enum
		},
	];
	if (siteBin === 0) {
		UpdateBinRow(row, operations, true);
	}
	if (siteBin === 1) {
		UpdateSiteRow(row, operations, true);
	}
}

function UpdateBinRow(row: any, operations: any[], remove = false) {
	const payload = {
		binMapId: row.Id,
		note: remove
			? 'Mapping Management - Reject'
			: 'Mapping Management - Update',
		operations: operations,
	};
	_service
		.Patch<any>('DataMatchingWeights/Mapping/BinMap', payload)
		.then((response) => {
			if (remove) {
				UpdateTableRowData(row.Id, 0, null, true);
			} else {
				UpdateTableRowData(row.Id, 0, response[0]);
			}
		})
		.catch((err) => {
			console.log(err);
		});
}

function UpdateSiteRow(row: any, operations: any[], remove = false) {
	const payload = {
		siteMapId: row.Id,
		note: remove
			? 'Mapping Management - Reject'
			: 'Mapping Management - Update',
		operations: operations,
	};
	_service
		.Patch<any>('DataMatchingWeights/Mapping/SiteMap', payload)
		.then((response) => {
			if (remove) {
				UpdateTableRowData(row.Id, 1, null, true);
			} else {
				UpdateTableRowData(row.Id, 1, response[0]);
			}
		})
		.catch((err) => {
			console.log(err);
		});
}

function UpdateTableRowData(
	id: string | number,
	siteBin: number,
	data?: any,
	remove = false
) {
	if (siteBin === 0) {
		let index = _itemsBins.value.findIndex((bin: any) => {
			return bin.Id === id;
		});
		if (remove) {
			_itemsBins.value.splice(index, 1);
		} else if (!remove && data) {
			_itemsBins.value[index] = data;
		}
	}
	if (siteBin === 1) {
		let index = _itemsSites.value.findIndex((site: any) => {
			return site.Id === id;
		});
		if (remove) {
			_itemsSites.value.splice(index, 1);
		} else if (!remove && data) {
			_itemsSites.value[index] = data;
		}
	}
}

function GetDataBins() {
	_service
		.Get<any>('DataMatchingWeights/Mapping/BinMaps')
		.then((response) => {
			_itemsBins.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

function GetDataSites() {
	_service
		.Get<any>('/DataMatchingWeights/Mapping/SiteMaps')
		.then((response) => {
			_itemsSites.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}
</script>

<style>
.bin-site-overflow {
	white-space: pre-wrap !important;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
