import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "menu-container",
  class: "q-pa-md col"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "heading-b" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "heading-b" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "heading-b" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "heading-b" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "heading-b" }
const _hoisted_20 = {
  key: 0,
  class: "row",
  style: {"margin-top":"16px !important","margin-bottom":"16px !important"}
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_23 = { class: "row" }
const _hoisted_24 = {
  class: "col",
  style: {"text-align":"center"}
}
const _hoisted_25 = {
  key: 0,
  style: {"display":"inline"}
}
const _hoisted_26 = {
  class: "col",
  style: {"text-align":"center"}
}
const _hoisted_27 = {
  key: 0,
  style: {"display":"inline"}
}
const _hoisted_28 = { class: "row" }
const _hoisted_29 = {
  class: "col",
  style: {"text-align":"center"}
}
const _hoisted_30 = {
  key: 0,
  style: {"display":"inline"}
}
const _hoisted_31 = {
  class: "col",
  style: {"text-align":"center"}
}
const _hoisted_32 = {
  key: 0,
  style: {"display":"inline"}
}
const _hoisted_33 = {
  key: 0,
  class: "q-pb-sm"
}
const _hoisted_34 = { class: "q-pl-sm" }
const _hoisted_35 = { class: "q-pl-md" }

import { ref, computed, watch } from 'vue';
import {
	ActualLiftStatusEnum,
	DataMatchingBinResultDTO,
	DataMatchingOverrideStatusDto,
	DataMatchingOverrideWeightDto,
	DataMatchingScheduleDTOShallow,
	WeightLimitsDTO,
} from 'greenzonegateway.classes/lib/classes';
import { GZSimpleEntryPopup } from '@gz/quasar-components-vue3';
import { BaseService } from '@/services/BaseService';

//defines

export default /*@__PURE__*/_defineComponent({
  __name: 'SidePanel',
  props: {
	drawerData: { type: Object, required: true },
	isMenuOpen: Boolean,
},
  emits: ['binChanged', 'scheduleChanged'],
  setup(__props, { emit: __emit }) {

const _emit = __emit;
const _props = __props;
const _service = new BaseService();
const _weightLimits = ref<WeightLimitsDTO>({
	ReportImportId: 0,
	CustomerOverweightPricePerKg: null,
	SupplierOverweightPricePerKg: null,
	CustomerLimitKg: null,
	SupplierLimitKg: null,
	IsCustomerCharged: false,
});

//flags
const _isWeightInputLoading = ref(false);
const _isSupplierCustomerWeightsLoading = ref(false);

const _weightInputRules = computed(() => {
	if (_newScheduleWeight.value) {
		if (_newScheduleWeight.value > 20000) {
			return 'Value cannot exceed 20,000';
		}
		if (_newScheduleWeight.value < 0) {
			return 'Value must be positive';
		}
		if (CountDecimals(_newScheduleWeight.value) > 2) {
			return 'Value at most can have 2 decimal points';
		}
	}
	return false;
});

//vars
const _newScheduleWeight = ref<number>();

function CountDecimals(num: number) {
	let asText = num.toString();
	let [_, back] = asText.split('.');
	if (back) {
		return back.length;
	}
	return 0;
}

watch(
	() => _props.drawerData?.BinResult,
	(oldVal: any, newVal: any) => {
		if (_props.isMenuOpen && oldVal !== newVal) {
			LoadWeightsData(_props.drawerData?.BinResult);
		}
	},
	{ deep: true }
);

//#region API Calls
function LoadWeightsData(binResult: DataMatchingBinResultDTO) {
	_weightLimits.value = {
		ReportImportId: 0,
		CustomerOverweightPricePerKg: null,
		SupplierOverweightPricePerKg: null,
		CustomerLimitKg: null,
		SupplierLimitKg: null,
		IsCustomerCharged: false,
	};
	_isSupplierCustomerWeightsLoading.value = true;
	_service
		.Get<WeightLimitsDTO>(
			`/DataMatchingWeights/WeightLimits?reportImportId=${binResult.ReportImportId}`
		)
		.then((response: WeightLimitsDTO) => {
			_weightLimits.value = response;
		})
		.finally(() => {
			_isSupplierCustomerWeightsLoading.value = false;
		});
}

function PostNewWeight(service: DataMatchingScheduleDTOShallow) {
	let data: DataMatchingOverrideWeightDto = {
		ReportImportId: _props.drawerData?.ReportImportId,
		Ssid: service.ScheduleId,
		NewWeight: _newScheduleWeight.value || null,
		IgnoreNewWeight: true,
	};
	_service
		.Post<DataMatchingOverrideWeightDto>(
			'DataMatchingWeights/OverrideWeight',
			data
		)
		.then((schedule: DataMatchingScheduleDTOShallow[]) => {
			_emit('binChanged', {
				schedule: schedule,
				internalSiteRowId: _props.drawerData?.InternalSiteRowId,
			});
		});
}

function PostStatus(service: any, liftStatus: ActualLiftStatusEnum | null) {
	let data: DataMatchingOverrideStatusDto = {
		ReportImportId: _props.drawerData?.ReportImportId,
		Ssid: service.ScheduleId,
		IgnoreNewActualLiftStatus: true,
		NewStatus: liftStatus,
	};
	_service
		.Post<DataMatchingOverrideStatusDto>(
			'DataMatchingWeights/OverrideStatus',
			data
		)
		.then((dataOut: DataMatchingScheduleDTOShallow) => {
			_emit('binChanged', {
				schedule: dataOut,
				internalSiteRowId: _props.drawerData?.InternalSiteRowId,
			});
		});
}
//#endregion

return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_markup_table = _resolveComponent("q-markup-table")!
  const _component_q_spinner_oval = _resolveComponent("q-spinner-oval")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "heading-a" }, "Container Type")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(__props.drawerData?.BinResult?.Bin || 'N/A'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "heading-a" }, "Quantity")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(__props.drawerData?.BinResult?.MatchedQuantity || 'N/A'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "heading-a" }, "On-Site")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(__props.drawerData?.BinResult?.onSite || 'N/A'), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "heading-a" }, "Weight")
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(__props.drawerData?.BinResult?.SupplierActualWeightKgDisplay || 'N/A'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", { class: "heading-a" }, "Date Collected")
          ], -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(__props.drawerData?.BinResult?.LiftDate?.Display || 'N/A'), 1)
          ])
        ])
      ])
    ]),
    _createVNode(_component_q_separator, { class: "q-mt-md" }),
    (__props.drawerData?.BinResult?.MatchedSchedules?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode(_component_q_markup_table, {
            style: {"width":"480px","max-width":"480px"},
            flat: "",
            dense: ""
          }, {
            default: _withCtx(() => [
              _cache[14] || (_cache[14] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Date"),
                  _createElementVNode("th", null, "Weight"),
                  _createElementVNode("th", null, "Lift Status"),
                  _createElementVNode("th", null, "New Weight"),
                  _createElementVNode("th", null, "New Lift Status")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.drawerData?.BinResult?.MatchedSchedules, (matchedSchedule) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: matchedSchedule.ScheduleId
                  }, [
                    _createElementVNode("th", null, _toDisplayString(matchedSchedule.ScheduleDate?.Display), 1),
                    _createElementVNode("th", null, _toDisplayString(matchedSchedule.ActualWeightKg?.Name), 1),
                    _createElementVNode("th", null, _toDisplayString(matchedSchedule.ActualLiftStatus?.Name), 1),
                    _createElementVNode("th", {
                      onClick: ($event: any) => {
								{
									_newScheduleWeight.value = matchedSchedule.NewActualWeightKg?.Value;
									matchedSchedule.dialogOpen = true;
								}
							}
                    }, [
                      _createTextVNode(_toDisplayString(matchedSchedule.NewActualWeightKg?.Name) + " ", 1),
                      (!__props.drawerData.BinResult?.WeightEntered?.Display)
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: "mdi-pencil"
                          }))
                        : _createCommentVNode("", true),
                      (!__props.drawerData.BinResult?.WeightEntered?.Display)
                        ? (_openBlock(), _createBlock(_unref(GZSimpleEntryPopup), {
                            key: 1,
                            modelValue: matchedSchedule.dialogOpen,
                            "onUpdate:modelValue": ($event: any) => ((matchedSchedule.dialogOpen) = $event),
                            onClickOutside: ($event: any) => {
									{
										matchedSchedule.dialogOpen = false;
									}
								}
                          }, {
                            body: _withCtx(() => [
                              _createVNode(_component_q_input, {
                                modelValue: _newScheduleWeight.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_newScheduleWeight).value = $event)),
                                loading: _isWeightInputLoading.value,
                                error: !!_weightInputRules.value,
                                type: "number",
                                label: "New Weight (kg)",
                                dense: "",
                                "hide-bottom-space": "",
                                onKeydown: _withKeys(($event: any) => (
											!_weightInputRules.value ? PostNewWeight(matchedSchedule) : null
										), ["enter"]),
                                onKeyup: _withKeys(($event: any) => (
											!_weightInputRules.value
												? (matchedSchedule.dialogOpen = false)
												: null
										), ["enter"])
                              }, null, 8, ["modelValue", "loading", "error", "onKeydown", "onKeyup"]),
                              (_weightInputRules.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_weightInputRules.value), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue", "onClickOutside"]))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_21),
                    _createElementVNode("th", null, _toDisplayString(matchedSchedule.NewActualLiftStatus?.Name), 1),
                    (!__props.drawerData.BinResult?.WeightEntered?.Display)
                      ? (_openBlock(), _createBlock(_component_q_menu, {
                          key: 0,
                          "context-menu": "",
                          "touch-position": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, {
                              dense: "",
                              style: {"width":"200px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item, { clickable: "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                                        _createTextVNode("Enter Actual Lift Status")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, { side: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-chevron-right" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_menu, {
                                      anchor: "top end",
                                      self: "top start"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_list, {
                                          dense: "",
                                          style: {"width":"200px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).Empty
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                _createTextVNode("Empty")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).WastedJourney
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                _createTextVNode("Wasted Journey")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).Contaminated
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                _createTextVNode("Contaminated")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).Collected
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                _createTextVNode("Collected")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).MissedLift
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                _createTextVNode("Missed Lift")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                              clickable: "",
                                              onClick: ($event: any) => (
													PostStatus(
														matchedSchedule,
														_unref(ActualLiftStatusEnum).DifferentDay
													)
												)
                                            }, {
                                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                _createTextVNode("Different Day")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])), [
                                              [_directive_close_popup]
                                            ]),
                                            _withDirectives((_openBlock(), _createBlock(_component_q_item, { clickable: "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, {
                                                  onClick: ($event: any) => (PostStatus(matchedSchedule, null))
                                                }, {
                                                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                    _createTextVNode("No Status")
                                                  ])),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)), [
                                              [_directive_close_popup]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_separator, { class: "q-mb-md" }),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", null, [
          _cache[15] || (_cache[15] = _createTextVNode(" Customer Limit: ")),
          (!_isSupplierCustomerWeightsLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_weightLimits.value?.CustomerLimitKg ?? 'N/A'), 1))
            : (_openBlock(), _createBlock(_component_q_spinner_oval, {
                key: 1,
                style: {"margin-bottom":"3px"}
              }))
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", null, [
          _cache[16] || (_cache[16] = _createTextVNode(" Supplier Limit: ")),
          (!_isSupplierCustomerWeightsLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_weightLimits.value?.SupplierLimitKg ?? 'N/A'), 1))
            : (_openBlock(), _createBlock(_component_q_spinner_oval, {
                key: 1,
                style: {"margin-bottom":"3px"}
              }))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", null, [
          _cache[17] || (_cache[17] = _createTextVNode(" Customer Charge: ")),
          (!_isSupplierCustomerWeightsLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_weightLimits.value?.CustomerOverweightPricePerKg ?? 'N/A'), 1))
            : (_openBlock(), _createBlock(_component_q_spinner_oval, {
                key: 1,
                style: {"margin-bottom":"3px"}
              }))
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", null, [
          _cache[18] || (_cache[18] = _createTextVNode(" Supplier Charge: ")),
          (!_isSupplierCustomerWeightsLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_weightLimits.value?.SupplierOverweightPricePerKg ?? 'N/A'), 1))
            : (_openBlock(), _createBlock(_component_q_spinner_oval, {
                key: 1,
                style: {"margin-bottom":"3px"}
              }))
        ])
      ])
    ]),
    _createVNode(_component_q_separator, { class: "q-my-md" }),
    _createElementVNode("div", null, [
      _cache[19] || (_cache[19] = _createElementVNode("h5", null, "Audit Log", -1)),
      (!__props.drawerData.Audit.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, " No audit data recorded against this item "))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.drawerData.Audit, (audit) => {
        return (_openBlock(), _createElementBlock("div", {
          key: audit.ImportHistoryId,
          class: "q-pb-sm"
        }, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("b", null, _toDisplayString(audit.CreatedAt.Display), 1)
          ]),
          _createElementVNode("div", _hoisted_35, _toDisplayString(audit.StepDetail), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})