import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full-width row flex-center q-gutter-sm" }




export default /*@__PURE__*/_defineComponent({
  __name: 'MappedTable',
  props: {
	items: Array,
	itemKey: String,
	itemsPerPage: Number,
	headers: {
		type: Array,
		default: () => [
			{
				name: 'Name',
				align: 'left',
				field: 'Name',
				label: 'Mapped Name',
				sortable: true,
			},
			{
				name: 'Supplier',
				align: 'left',
				field: 'Supplier.SupplierName',
				label: 'Supplier Name',
				sortable: true,
			},
		],
	},
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_table, {
      rows: __props.items,
      columns: __props.headers,
      "row-key": __props.itemKey,
      "rows-per-page-options": [0],
      "virtual-scroll-sticky-size-start": 20,
      height: "700px",
      style: {"height":"80vh","position":"relative","width":"100%"},
      class: "overflow-y-auto",
      "virtual-scroll": ""
    }, {
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, { key: "Name" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.Name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { key: "SupplierName" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.Supplier.SupplierName), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "no-data": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_icon, {
            size: "2em",
            name: "mdi-alert"
          }),
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "No data available", -1))
        ])
      ]),
      _: 1
    }, 8, ["rows", "columns", "row-key"])
  ]))
}
}

})