import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "q-pa-md"
}
const _hoisted_5 = ["onMouseover", "onMouseleave"]
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = {
  key: 1,
  style: {"min-height":"238px","align-content":"center","text-align":"center"}
}

import { BaseService } from '@/services/BaseService';
import { GZDraggableModal } from '@gz/quasar-components-vue3';
import {
	DataMatchingBin,
	DatamatchingGroupedResultDTO,
	DataMatchingScheduleSelectDTO,
	DataMatchingSelectedSchedulesDto,
} from 'greenzonegateway.classes/lib/classes';
import {
	ref,
	nextTick,
	onMounted,
} from 'vue';

//defines

export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleSelectModal',
  props: {
	reportImportId: String,
	InternalSiteRowId: Number,
},
  emits: ['binUpdated'],
  setup(__props, { expose: __expose, emit: __emit }) {

__expose({ Close, Open });
const _emit = __emit;
const _props = __props;
const _service = new BaseService();

//refs
const scheduleMappingModal = ref<typeof GZDraggableModal>();

//flags
const _isLoading = ref(false);

//vars
const _selectedBin = ref<DataMatchingBin>();
const _binsOnSite = ref<DataMatchingBin[]>([]);
const _selectedSchedules = ref<number[]>([]);

//#region Exposed functions
function Close() {
	Reset();
	scheduleMappingModal.value?.hide();
}

function Open() {
	scheduleMappingModal.value?.show();
	nextTick(() => GetSchedule());
}
//#endregion

function Reset() {
	_selectedBin.value = undefined;
	_binsOnSite.value = [];
}

function GetScheduleColor(schedule: any) {
	if (schedule.IsSelected) {
		return 'green';
	}
	if (schedule.IsVisible) {
		return 'gray';
	}
	return 'rgba(0,0,0,0)';
}

function AddScheduleToList(ScheduleId: number) {
	if (!_selectedBin.value) {
		return;
	}
	_selectedBin.value.Schedules.forEach((schedule) => {
		if (schedule.ScheduleId === ScheduleId) {
			schedule.IsSelected = schedule.IsSelected ? false : true;
		} else {
			schedule.IsSelected = false;
		}
	});
	RefreshSelectedSchedules();
}

function RefreshSelectedSchedules() {
	_selectedSchedules.value = [];
	_binsOnSite.value.forEach((bin) => {
		bin.Schedules.forEach((schedule) => {
			if (schedule.IsSelected) {
				_selectedSchedules.value.push(schedule.ScheduleId);
			}
		});
	});
}

function PrimeSelectedSchedules() {
	_binsOnSite.value.forEach((bin) => {
		bin.Schedules.forEach((schedule) => {
			if (schedule?.IsSelected) {
				_selectedSchedules.value.push(schedule.ScheduleId);
			}
		});
	});
}

//#region API Calls
function GetSchedule() {
	if (!_props.reportImportId) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<DataMatchingScheduleSelectDTO>(
			`DataMatchingWeights/MatchedSchedules?reportImportId=${_props.reportImportId}`
		)
		.then((schedules) => {
			_binsOnSite.value = schedules.BinsOnSite;
			if (_binsOnSite.value.length) {
				_selectedBin.value = _binsOnSite.value[0];
			}
			PrimeSelectedSchedules();
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostSchedule() {
	const _data: DataMatchingSelectedSchedulesDto = {
		ReportImportId: Number(_props.reportImportId),
		SelectedSchedules: _selectedSchedules.value,
	};
	_service
		.Post<DataMatchingSelectedSchedulesDto>(
			'DataMatchingWeights/UpdateMatchedSchedules',
			_data
		)
		.then((site: DatamatchingGroupedResultDTO) => {
			_emit('binUpdated', {
				site: site,
				internalSiteRowId: _props.InternalSiteRowId,
				reportImportId: _props.reportImportId,
			});
			Close();
		});
}
//#endregion

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_unref(GZDraggableModal), {
    ref_key: "scheduleMappingModal",
    ref: scheduleMappingModal
  }, {
    header: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-accent text-white" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { class: "text-h5" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_selectedBin.value ? _selectedBin.value.ServiceDescription + ' -' : '') + " Schedule Select", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"min-width":"500px","min-height":"370px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_q_select, {
                      modelValue: _selectedBin.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_selectedBin).value = $event)),
                      options: _binsOnSite.value,
                      loading: _isLoading.value,
                      "option-label": 'ServiceDescription',
                      class: "full-width",
                      label: "Selected Bin",
                      dense: "",
                      outlined: ""
                    }, null, 8, ["modelValue", "options", "loading"])
                  ]),
                  (_selectedBin.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row" }, [
                          _createElementVNode("div", { class: "col-1" }),
                          _createElementVNode("div", { class: "col" }, "Date"),
                          _createElementVNode("div", { class: "col" }, "Weight"),
                          _createElementVNode("div", { class: "col" }, "Status")
                        ], -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_selectedBin.value.Schedules, (schedule) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "row q-pt-md",
                            key: schedule.ScheduleId
                          }, [
                            _createElementVNode("div", {
                              class: "col-1",
                              onMouseover: ($event: any) => (schedule.IsVisible = true),
                              onMouseleave: ($event: any) => (schedule.IsVisible = false)
                            }, [
                              _createVNode(_component_q_icon, {
                                name: "mdi-check",
                                style: _normalizeStyle([{"background-color":"rgba(0, 0, 0, 0.05)"}, { color: GetScheduleColor(schedule) }]),
                                onClick: ($event: any) => (AddScheduleToList(schedule.ScheduleId))
                              }, null, 8, ["onClick", "style"])
                            ], 40, _hoisted_5),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(schedule.ScheduleDate?.Display), 1),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(schedule.ActualWeightKg.Name), 1),
                            _createElementVNode("div", _hoisted_8, _toDisplayString(schedule.ActualLiftStatus.Name), 1)
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
                        _createElementVNode("span", { style: {"color":"gray"} }, "Select a bin from dropdown above", -1)
                      ])))
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, { onClick: Close }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: PostSchedule
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Save")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}
}

})