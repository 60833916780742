<template>
	<div style="position: absolute; height: calc(100% - 128px); width: 100%">
		<q-toolbar class="bg-accent text-white q-pr-none">
			<q-toolbar-title
				>Weight Data Matching - Spreadsheet Details</q-toolbar-title
			>
			<q-btn
				:icon-right="mdiArrowDownThick"
				class="q-mr-sm"
				@click="DownloadServiceReport"
				>Report</q-btn
			>
			<q-input
				type="text"
				outlined
				dense
				label="Filter"
				v-model="_search"
				dark
			></q-input>
			<q-btn
				round
				:flat="!FiltersEnabled()"
				:outline="FiltersEnabled()"
				size="large"
				class="q-ml-sm"
			>
				<q-icon :name="mdiFilterVariant" size="md"></q-icon>
				<q-tooltip location="top">
					<span>Filters</span>
				</q-tooltip>
				<q-menu>
					<div style="width: 230px">
						<q-toggle
							v-model="_showProblemsOnly"
							label="Show Problems Only"
						></q-toggle>
						<q-toggle
							v-model="_showUncommittedOnly"
							label="Show Uncommitted Only"
						></q-toggle>
						<q-toggle
							v-model="_showIgnoredOnly"
							label="Show Ignored Only"
						></q-toggle>
					</div>
				</q-menu>
			</q-btn>
			<q-btn round flat size="large" class="q-mx-sm">
				<q-icon :name="mdiCog" size="md" />
				<q-tooltip location="top">
					<span>Settings</span>
				</q-tooltip>
				<q-menu>
					<div style="width: 200px">
						<q-toggle
							v-model="_settings.slimGroups"
							@click="SaveSettings"
							label="Slim Groups"
							class="q-pr-md"
						></q-toggle>
						<q-toggle
							v-model="_settings.slimItems"
							@click="SaveSettings"
							label="Slim Items"
							class="q-pr-md"
						></q-toggle>
					</div>
				</q-menu>
			</q-btn>
		</q-toolbar>
		<GZSlideout
			style="
				position: absolute;
				overflow-y: hidden;
				width: calc(100% - 56px);
				left: 56px;
			"
			:selectedItems="_selected"
			:breakpoint="900"
			:layoutStyle="{ height: 'calc(60% - 64px)' }"
			:pageContainerStyle="{ height: 'calc(100% - 142px)' }"
			:overlay="false"
			:drawerStyle="{ overflow: 'hidden !important', height: '100%' }"
			drawerWidth="520"
			v-model="_drawerOut"
		>
			<template #menu>
				<q-toolbar class="bg-accent" style="height: 32px; background-color">
					<div class="row full-width">
						<div class="col-2">
							<q-btn flat dense class="q-ma-sm" @click="_drawerOut = false">
								<q-icon name="mdi-chevron-double-right" color="white"></q-icon>
							</q-btn>
						</div>
						<div class="col-7" style="align-self: center; text-align: center">
							<span
								v-if="_drawerData.BinResult?.WeightEntered?.Display"
								style="color: white"
								>Saved On:
								{{ _drawerData.BinResult?.WeightEntered?.Display }}</span
							>
						</div>
						<div class="col-3" style="text-align: center">
							<q-btn
								dense
								outline
								color="white"
								class="q-ma-sm q-px-sm"
								:loading="_isLoading"
								@click="
									PostBinSave(
										_drawerData.ReportImportId,
										_drawerData.InternalSiteRowId,
										!!_drawerData.BinResult?.WeightEntered?.Display
									)
								"
							>
								{{
									!_drawerData.BinResult?.WeightEntered?.Display
										? 'Save'
										: 'Undo'
								}}
							</q-btn>
						</div>
					</div>
				</q-toolbar>
				<q-scroll-area style="height: 80%" id="side-panel-container">
					<SidePanel
						class="q-pb-md"
						:isMenuOpen="_drawerOut"
						:drawerData="_drawerData"
						@binChanged="HandleSidePanelBinChanged"
					></SidePanel>
				</q-scroll-area>
			</template>
			<template #content>
				<q-table
					ref="datamatchingTable"
					:columns="columns"
					:rows="_itemsComputed"
					:fixed-header="true"
					:loading="_isLoading"
					:filter="_search"
					:filter-method="SearchTable"
					:row-key="(row) => row.InternalSiteRowId"
					v-model:pagination="_pagination"
					style="height: calc(100% - 42px)"
					hide-pagination
					virtual-scroll
					binary-state-sort
					square
					flats
				>
					<template #body="props">
						<DetailsSiteRow
							:settings="_settings"
							:propsIn="props"
							@expand="LoadOnSiteData(props.row)"
							@update:propsIn="props = $event"
							@showModalScheduleMapping="ShowModalScheduleMapping"
							@showModalBinMappings="ShowBinMappingsModal"
							@showModalSiteMappings="ShowSiteMappingsModal"
							@openSidePanel="OpenSidePanel"
							@clearSelections="ClearSelections"
						/>
						<q-tr
							v-show="props.row.expand"
							:key="BinResult.ReportImportId"
							:style="{
								backgroundColor: BinResult.selected ? 'rgba(0,0,0,0.04)' : null,
							}"
							v-for="(BinResult, index) in props.row.BinResults"
							@click="
								OpenSidePanel(BinResult, index, props.row);
								ClearSelections();
								BinResult.selected = true;
							"
						>
							<q-menu context-menu touch-position>
								<q-list dense style="width: 200px">
									<q-item
										clickable
										@click="
											PostIgnoreBin(
												BinResult.IsIgnored,
												props.row.InternalSiteRowId,
												BinResult.ReportImportId
											)
										"
										v-close-popup
									>
										<q-item-section>{{
											BinResult.IsIgnored ? 'Re-list Bin' : 'Ignore Bin'
										}}</q-item-section>
									</q-item>
								</q-list>
							</q-menu>
							<DetailsBinRow
								v-if="
									(_showIgnoredOnly && BinResult.IsIgnored) ||
									(!_showIgnoredOnly && !BinResult.IsIgnored)
								"
								:settings="_settings"
								:binResult="BinResult"
								:row="props.row"
								:rowIndex="props.rowIndex"
								@update:propsIn="props = $event"
								@showModalScheduleMapping="ShowModalScheduleMapping"
								@showModalBinMappings="ShowBinMappingsModal"
								@clearSelections="ClearSelections"
							/>
						</q-tr>
					</template>
					<template v-slot:no-data>
						<div class="full-width row flex-center q-gutter-sm">
							<q-icon size="2em" name="mdi-alert" />
							<span>No data available or it has been filtered out</span>
						</div>
					</template>
				</q-table>
				<GZPagination
					class="q-pt-sm q-px-sm"
					style="width: 100%"
					:items-length="_itemsComputed.length"
					:pagination="_pagination"
					:rowsPerPageList="_rowsPerPageOptions"
				></GZPagination>
			</template>
		</GZSlideout>
		<SiteMappingModal
			ref="siteMappingModal"
			@siteUpdated="UpdateSiteRow"
			:siteMappingData="_siteMappingData"
		></SiteMappingModal>
		<BinMappingModal
			ref="binMappingModal"
			@binUpdated="UpdateSiteRow"
			:binMappingData="_binMappingData"
		></BinMappingModal>
		<ScheduleSelectModal
			ref="scheduleMappingModal"
			@binUpdated="UpdateSiteRow"
			:reportImportId="_reportImportId"
			:InternalSiteRowId="_scheduleRowInternalSiteId"
		></ScheduleSelectModal>
	</div>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref, nextTick } from 'vue';
import { BaseService } from '@/services/BaseService';
import {
	SidePanel,
	SiteMappingModal,
	BinMappingModal,
	ScheduleSelectModal,
	DetailsSiteRow,
	DetailsBinRow,
} from '../components/WeightDataMatching';
import { GZSlideout, GZPagination } from '@gz/quasar-components-vue3';
import { useRoute } from 'vue-router';
import { QTable } from 'quasar';
import {
	mdiCog,
	mdiFilterVariant,
	mdiArrowDownThick,
} from '@quasar/extras/mdi-v7';

import {
	DatamatchDataEntryDto,
	DataMatchingBinResultDTO,
	DatamatchingGroupedResultDTO,
	DataMatchingImportHistoryDTO,
	DataMatchingScheduleDTOShallow,
	OnSiteCountDto,
} from 'greenzonegateway.classes/lib/classes';
import moment from 'moment';

const datamatchingTable = ref<QTable>();
const binMappingModal = ref<typeof BinMappingModal>();
const siteMappingModal = ref<typeof SiteMappingModal>();
const scheduleMappingModal = ref<typeof ScheduleSelectModal>();

const _reportImportId = ref();
const _scheduleRowInternalSiteId = ref();

function ShowModalScheduleMapping(propsIn: {
	rowData: DatamatchingGroupedResultDTO;
	bin: DataMatchingBinResultDTO;
}) {
	_reportImportId.value = propsIn.bin.ReportImportId;
	_scheduleRowInternalSiteId.value = propsIn.rowData.InternalSiteRowId;
	binMappingModal.value?.Close();
	siteMappingModal.value?.Close();
	scheduleMappingModal.value?.Open();
}

const _route = useRoute();
const _service = new BaseService();
const _items = ref<DatamatchingGroupedResultDTOInternal[]>([]);
const _selected = ref();
const _search = ref('');
const _isLoading = ref(false);
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 40,
});
const _siteMappingData = ref({
	GroupedResult: {},
	RowIndex: 0,
	InternalSiteRowId: 0,
});
const _binMappingData = ref({
	GroupedResult: {},
	BinResult: {},
	InternalSiteRowId: 0,
});

interface DataMatchingBinResultDTOInternal extends DataMatchingBinResultDTO {
	InternalSiteRowId: number | null | undefined;
}

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number;
	expand: boolean;
}

const _drawerOut = ref(false);
const _drawerData = ref({
	Audit: [] as DataMatchingImportHistoryDTO[],
	ReportImportId: '',
	BinResult: {} as DataMatchingBinResultDTOInternal,
	InternalSiteRowId: 0,
});
const _rowsPerPageOptions = ref([10, 20, 30, 40]);
const _showProblemsOnly = ref(false);
const _showUncommittedOnly = ref(false);
const _showIgnoredOnly = ref(false);
const _settings = ref<{ slimGroups: boolean; slimItems: boolean }>({
	slimGroups: false,
	slimItems: false,
});

const _itemsComputed = computed(() => {
	if (!_items.value.length) {
		return [];
	}
	let itemsToReturn: DatamatchingGroupedResultDTO[] = _items.value;
	if (_search.value) {
		const needle = _search.value.toLowerCase();
		itemsToReturn = itemsToReturn.filter(
			(row: DatamatchingGroupedResultDTO) => {
				return (
					row.MatchedSiteAddress?.toLowerCase().includes(needle) ||
					row.MatchedSiteCity?.toLowerCase().includes(needle) ||
					row.MatchedSiteName?.toLowerCase().includes(needle) ||
					row.MatchedSitePostcode?.toLowerCase().includes(needle) ||
					row.SupplierSiteAddress?.toLowerCase().includes(needle) ||
					row.SupplierSiteCity?.toLowerCase().includes(needle) ||
					row.SupplierSiteName?.toLowerCase().includes(needle) ||
					row.SupplierSitePostcode?.toLowerCase().includes(needle)
				);
			}
		);
	}
	if (_showProblemsOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) => !!items.HasProblems
		);
	}
	if (_showUncommittedOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) => !items.IsSiteCommitted
		);
	}
	if (_showIgnoredOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) =>
				items.BinResults?.find((binResult) => binResult.IsIgnored)
		);
	}
	if (!_showIgnoredOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) =>
				items.BinResults?.find((binResult) => !binResult.IsIgnored)
		);
	}
	return itemsToReturn;
});

const columns = [
	{
		name: (row: DatamatchingGroupedResultDTO) => row.SupplierName,
		label: 'Supplier Report',
		align: 'left',
		sortable: false,
	},
	{},
	{
		name: 'GreenzoneAI',
		label: 'Greenzone AI',
		align: 'left',
		sortable: false,
	},
	{},
];

interface SiteUpdateEvent {
	internalSiteRowId: number;
	site: DatamatchingGroupedResultDTO[];
	reportImportId: number | string;
}

function FiltersEnabled() {
	if (
		_showProblemsOnly.value ||
		_showUncommittedOnly.value ||
		_showIgnoredOnly.value
	) {
		return true;
	}
	return false;
}

function UpdateSiteRow(event: SiteUpdateEvent) {
	let index = _items.value.findIndex(
		(item) => item.InternalSiteRowId === event.internalSiteRowId
	);
	event.site[0].InternalSiteRowId = event.internalSiteRowId;
	_items.value[index] = event.site[0];
	nextTick(() => {
		_items.value[index].expand = true;
		LoadOnSiteData(_items.value[index]);
		LoadAudit(event.reportImportId.toString());
		_drawerData.value.BinResult =
			_items.value[index].BinResults[_drawerData.value.BinIndex];
	});
}

function SearchTable(rows: DatamatchingGroupedResultDTO[], searchVal: string) {
	const needle = searchVal.toLowerCase();
	const rowsOut = rows.filter((row: DatamatchingGroupedResultDTO) => {
		return (
			row.MatchedSiteAddress?.toLowerCase().includes(needle) ||
			row.MatchedSiteCity?.toLowerCase().includes(needle) ||
			row.MatchedSiteName?.toLowerCase().includes(needle) ||
			row.MatchedSitePostcode?.toLowerCase().includes(needle) ||
			row.SupplierSiteAddress?.toLowerCase().includes(needle) ||
			row.SupplierSiteCity?.toLowerCase().includes(needle) ||
			row.SupplierSiteName?.toLowerCase().includes(needle) ||
			row.SupplierSitePostcode?.toLowerCase().includes(needle)
		);
	});

	return rowsOut;
}

function OpenSidePanel(
	binResult: DataMatchingBinResultDTO,
	index: number,
	row: DataMatchingBinResultDTOInternal
) {
	_drawerOut.value = true;
	LoadAudit(binResult.ReportImportId.toString());
	_drawerData.value.ReportImportId = binResult.ReportImportId.toString();
	_drawerData.value.BinResult = binResult as DataMatchingBinResultDTOInternal;
	_drawerData.value.BinIndex = index;
	_drawerData.value.InternalSiteRowId = row.InternalSiteRowId || 0;
}

function ClearSelections() {
	_items.value.forEach((item) =>
		item.BinResults?.forEach((bin) => (bin.selected = undefined))
	);
}

//This function looks more intimidating than it is, annotated for clarity.
//It's trying to replace the schedule in one of the site bins, that's all it does.
function HandleSidePanelBinChanged(eventIn: {
	schedule: DataMatchingScheduleDTOShallow;
	internalSiteRowId: number;
}) {
	let binResultIndex = null;
	//We find the site index
	let index = _items.value.findIndex((item) => {
		if (!item.BinResults) {
			return;
		}
		if (item.InternalSiteRowId === eventIn.internalSiteRowId) {
			for (let i = 0; i < item.BinResults.length; i++) {
				if (
					item.BinResults[i].ReportImportId ===
					Number.parseInt(_drawerData.value.ReportImportId)
				) {
					//We find the bin index while we are at it
					binResultIndex = i;
					return true;
				}
			}
		}
	});
	if (binResultIndex !== null && binResultIndex > -1) {
		//And finally we find the index of the schedule we are replacing
		let oldScheduleIndex = _items.value[index].BinResults[
			binResultIndex
		].MatchedSchedules.findIndex(
			(oldSchedule: DataMatchingScheduleDTOShallow) => {
				return oldSchedule.ScheduleId === eventIn.schedule.ScheduleId;
			}
		);
		//Then use all the indicies we found and replace the schedule.
		_items.value[index].BinResults[binResultIndex].MatchedSchedules[
			oldScheduleIndex
		] = eventIn.schedule;
		//Ensure that drawer data is updated too
		_drawerData.value.BinResult = _items.value[index].BinResults[
			binResultIndex
		] as DataMatchingBinResultDTOInternal;
		LoadAudit(_drawerData.value.ReportImportId);
	}
}

function ShowSiteMappingsModal(eventIn: {
	rowData: DatamatchingGroupedResultDTOInternal;
	index: number;
}) {
	if (!eventIn.rowData || !eventIn.rowData.BinResults) {
		return;
	}
	_siteMappingData.value.GroupedResult = eventIn.rowData;
	_siteMappingData.value.InternalSiteRowId = eventIn.rowData.InternalSiteRowId;
	scheduleMappingModal.value?.Close();
	binMappingModal.value?.Close();
	siteMappingModal.value?.Open();
}

function ShowBinMappingsModal(propsIn: {
	rowData: DatamatchingGroupedResultDTOInternal;
	binResult: DataMatchingBinResultDTO;
	index: number;
}) {
	_binMappingData.value.GroupedResult = propsIn.rowData;
	_binMappingData.value.BinResult = propsIn.binResult;
	_binMappingData.value.InternalSiteRowId = propsIn.rowData.InternalSiteRowId;
	siteMappingModal.value?.Close();
	scheduleMappingModal.value?.Close();
	binMappingModal.value?.Open();
}

function LoadAudit(reportImportId: string) {
	_service
		.Get<any>(`/DataMatchingWeights/Audits/${reportImportId}`)
		.then((dataIn: DataMatchingImportHistoryDTO[]) => {
			_drawerData.value.Audit = dataIn;
		});
}

function LoadOnSiteData(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	_isLoading.value = true;
	rowData.BinResults.forEach((Bin: any) => {
		Bin.isLoadingOnsite = true;
	});
	_service
		.Get<OnSiteCountDto[]>(
			`/DataMatchingWeights/OnSiteCount?reportImportId=${rowData.BinResults[0].ReportImportId}`
		)
		.then((response) => {
			if (!rowData.BinResults) {
				return;
			}
			for (let i = 0; i < response.length; i++) {
				rowData.BinResults.forEach((Bin) => {
					if (!Bin.ReportImportId) {
						return;
					}
					Bin.onSite = response.find(
						(responseItem) => responseItem.ReportImportId === Bin.ReportImportId
					).QuantityOnSite;
				});
			}
		})
		.finally(() => {
			_isLoading.value = false;
			if (!rowData.BinResults) {
				return;
			}
			rowData.BinResults.forEach((Bin: any) => {
				Bin.isLoadingOnsite = false;
			});
		});
}

function DownloadServiceReport() {
	_service
		.Get<any>(
			`DataMatchingWeights/ServiceReport?importFileId=${_route.params.id}`
		)
		.then((res: string) => {
			const link = document.createElement('a');
			link.href = 'data:text/csv;charset=utf-8,' + res;
			link.download = `ServiceReport${moment().format(
				'DD-MM-YYYY HH:MM:SS'
			)}.csv`;
			link.click();
		});
}

function GetSpreadSheetDetails() {
	_items.value = [];
	_isLoading.value = true;
	_service
		.Get<DatamatchingGroupedResultDTOInternal[]>(
			`/DataMatchingWeights/Results?importFileId=${_route.params.id}`
		)
		.then((response) => {
			let idIncrement = 0;
			for (let i = 0; i < response.length; i++) {
				response[i].InternalSiteRowId = idIncrement;
				_items.value.push(response[i]);
				idIncrement++;
			}
		})
		.catch((err) => {
			console.log(err);
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostBinSave(
	ReportImportId: string,
	internalSiteRowId: number,
	undo: boolean
) {
	let data: DatamatchDataEntryDto = {
		ReportImportId: Number.parseInt(ReportImportId),
	};
	let urlEnding = undo ? 'UndoCommit' : 'CommitData';
	_isLoading.value = true;
	_service
		.Post<DatamatchDataEntryDto>(`/DataMatchingWeights/${urlEnding}`, data)
		.then((response: DatamatchingGroupedResultDTO[]) => {
			UpdateSiteRow({
				reportImportId: ReportImportId,
				internalSiteRowId: internalSiteRowId,
				site: response,
			});
		})
		.finally(() => {
			_isLoading.value = false;
			LoadAudit(ReportImportId);
		});
}

function PostIgnoreBin(
	isBinIgnored: boolean,
	internalSiteRowId: number,
	ReportImportId: string
) {
	let data = {
		IsIgnored: !isBinIgnored,
		ReportImportId: Number.parseInt(ReportImportId),
	};
	_isLoading.value = true;
	_service
		.Post<DatamatchDataEntryDto>('/DataMatchingWeights/IgnoreRow', data)
		.then((response: DatamatchingGroupedResultDTO[]) => {
			UpdateSiteRow({
				reportImportId: ReportImportId,
				internalSiteRowId: internalSiteRowId,
				site: response,
			});
		})
		.finally(() => {
			_isLoading.value = false;
			LoadAudit(ReportImportId);
		});
}

function SaveSettings() {
	localStorage.setItem('ODMSettings', JSON.stringify(_settings.value));
}

function LoadSettings() {
	let loadedSettings = localStorage.getItem('ODMSettings');
	if (loadedSettings) {
		_settings.value = JSON.parse(loadedSettings);
	}
}

onMounted(() => {
	LoadSettings();
	GetSpreadSheetDetails();
});
</script>

<style lang="scss">
@import '../styles/base-styles.scss';

.slideoutClass {
}
</style>
