import script from "./DetailsBinRow.vue?vue&type=script&setup=true&lang=ts"
export * from "./DetailsBinRow.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerOval from 'quasar/src/components/spinner/QSpinnerOval.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.1.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTd,QTooltip,QBtn,QSpinnerOval});
