import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/supplieraccounts/',
		name: 'home',
		component: () =>
			import(/* webpackChunkName: "homeview" */ '../views/HomeView.vue'),
	},
	{
		path: '/supplieraccounts/datamatching',
		name: 'datamatching',
		component: () =>
			import(
				/* webpackChunkName: "supplieraccounts" */ '../views/SupplierAccounts.vue'
			),
	},
	{
		path: '/supplieraccounts/weightdatamatching/spreadsheets',
		name: 'weight-datamatching-spreadsheets',
		component: () =>
			import(
				/* webpackChunkName: "supplieraccounts" */ '../views/OverweightDataMatching.vue'
			),
	},
	{
		path: '/supplieraccounts/weightdatamatching/spreadsheets/details/:id',
		name: 'weight-datamatching-spreadsheets-details',
		component: () =>
			import(
				/* webpackChunkName: "supplieraccounts" */ '../views/OverweightDataMatchingDetails.vue'
			),
	},
	{
		path: '/supplieraccounts/weightsdatamatching/binsitemappings',
		name: 'weight-datamatching-binsitemappings',
		component: () =>
			import(
				/* webpackChunkName: "supplieraccounts" */ '../views/WeightDataMatching/BinSiteMappings.vue'
			),
	},
	{
		path: '/supplieraccounts/mappings',
		name: 'mappings',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "about" */ '../views/SupplierMappingsView.vue'
			),
	},
	{
		path: '/supplieraccounts/supplierdefaults',
		name: 'supplierdefaults',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "about" */ '../views/SuppliedDataMatchingDefaults.vue'
			),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
