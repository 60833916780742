<template>
	<q-card style="width: 800px">
		<q-toolbar class="bg-accent text-white">
			<q-toolbar-title class="text-h5">Edit Details</q-toolbar-title>
		</q-toolbar>
		<q-form ref="editModalForm">
			<q-card-section>
				<div class="row d-flex justify-around">
					<p>{{ _selectProcessed.SiteAddress }}</p>
					<div class="row">
						<div class="col-6 q-px-sm">
							<q-input
								label="Supplier Name"
								outlined
								eager="true"
								v-model="_selectProcessed.SupplierName"
								readonly
							></q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="Invoice No"
								outlined
								:rules="_nameRules"
								v-model="_selectProcessed.InvoiceNo"
								:readonly="_disabled"
							></q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="Net"
								outlined
								required
								disabled
								:rules="_nameRules"
								v-model="_selectProcessed.Net"
								:readonly="_disabled"
							></q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="VAT"
								outlined
								required
								:rules="_nameRules"
								v-model="_selectProcessed.VAT"
								:readonly="_disabled"
							></q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="Total"
								outlined
								required
								:disabled="_disabled"
								:rules="_nameRules"
								v-model="_selectProcessed.Total"
								:readonly="_disabled"
							></q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="Invoice Date"
								v-model="_updatedDate"
								:readonly="_disabled"
								:rules="_nameRules"
								outlined
							>
								<template v-slot:append>
									<q-icon
										name="mdi-calendar-range"
										class="cursor-pointer"
										v-if="!_disabled"
									>
										<q-popup-proxy
											cover
											transition-show="scale"
											transition-hide="scale"
										>
											<q-date
												v-model="_updatedDate"
												mask="DD/MM/YYYY"
												@update:modelValue="_updateDateRange"
											>
												<div class="row items-center justify-end">
													<q-btn
														v-close-popup
														label="Close"
														color="primary"
														flat
													/>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="From Date"
								v-model="_updatedStartDate"
								:readonly="_disabled"
								:rules="[fromToDateRule]"
								outlined
							>
								<template v-slot:append>
									<q-icon
										name="mdi-calendar-range"
										class="cursor-pointer"
										v-if="!_disabled"
									>
										<q-popup-proxy
											cover
											transition-show="scale"
											transition-hide="scale"
										>
											<q-date
												v-model="_updatedStartDate"
												mask="DD/MM/YYYY"
												title="Date"
											>
												<div class="row items-center justify-end">
													<q-btn
														v-close-popup
														label="Close"
														color="primary"
														flat
													/>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>
						</div>
						<div class="col-6 q-px-sm">
							<q-input
								label="To Date"
								v-model="_updatedEndDate"
								:readonly="_disabled"
								:rules="[fromToDateRule]"
								outlined
							>
								<template v-slot:append>
									<q-icon
										name="mdi-calendar-range"
										class="cursor-pointer"
										v-if="!_disabled"
									>
										<q-popup-proxy
											cover
											transition-show="scale"
											transition-hide="scale"
										>
											<q-date v-model="_updatedEndDate" mask="DD/MM/YYYY">
												<div class="row items-center justify-end">
													<q-btn
														v-close-popup
														label="Close"
														color="primary"
														flat
													/>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>
						</div>

						<div class="col-12 q-pb-sm">
							<q-toggle
								color="primary"
								label="Dispute Invoice"
								v-model="_disputed"
								inset
								disable
							></q-toggle>
						</div>

						<div class="row" style="width: 100%">
							<div class="col-7">
								<GZGenericSelect
									class="q-px-sm"
									:multiple="false"
									:items="_suppliersStore.getSuppliers"
									name="SupplierName"
									label="Supplier"
									v-model="_selectProcessed.Supplier"
									@update:modelValue="_delaySageReferences"
									:rules="_supplierNameRules"
								/>
							</div>
							<div class="col-5">
								<GZGenericSelect
									class="q-px-sm"
									:multiple="false"
									:items="_sageReferences"
									v-model="_selectProcessed.SageReference"
									name="Reference"
									label="Sage Reference"
									:rules="_nameRules"
								/>
							</div>
						</div>

						<div class="col-12 q-px-sm" v-if="_disputed === true">
							<div class="col-12 q-pb-sm">
								<GZGenericSelect
									:multiple="true"
									:items="_disputeReasonArray"
									name="Name"
									label="Dispute Reasons"
									v-model="_selectProcessed.DisputeCode"
								></GZGenericSelect>
							</div>
							<q-input
								v-model="_selectProcessed.DisputeReason"
								filled
								clearable
								type="textarea"
								color="red-12"
								label="Enter Dispute Reason"
							/>
						</div>
						<div class="col-12 q-px-sm" v-else>
							<div class="col-12 q-pb-sm">
								<GZGenericSelect
									:multiple="false"
									:loading="_customersStore.isLoading"
									:disabled="_customersStore.isLoading"
									:items="_customers"
									name="CustomerName"
									label="Customer"
									v-model="_selectProcessed.Customer"
								/>
							</div>
							<q-input
								label="Site Postcode Search"
								outlined
								@update:modelValue="_delayedGetSites"
								v-model="_postcode"
							></q-input>
						</div>
					</div>
				</div>
			</q-card-section>

			<q-card-actions align="right">
				<q-btn
					flat
					label="Cancel"
					color="accent"
					@click="_emit('close', true)"
				/>
				<q-btn
					outline
					color="positive"
					:label="_disputed ? 'Dispute' : 'Submit'"
					@click.prevent.stop="Submit()"
					:loading="_isLoading"
				/>
			</q-card-actions>
		</q-form>
	</q-card>
</template>
<script lang="ts" setup>
import { defineProps, ref, defineEmits, computed, onMounted } from 'vue';
import { debounce, date } from 'quasar';
import { DisputeReasonsEnum } from 'greenzonegateway.classes/lib/classes/enums';
import { BaseService } from '@/services/BaseService';
import { customerStore } from '@/store/customer';
import { supplierStore } from '@/store/suppliers';
import moment = require('moment');
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import { ICustomer, ISite } from 'greenzonegateway.classes/lib/classes';
import { ISageReference } from 'greenzonegateway.classes/lib/classes/Generic';
const _nameRules = [(v: string) => !!v || 'Field is required'];
const _supplierNameRules = [
	(v: string) => v != null || 'This field is required',
];

const _customersStore = customerStore();
const _suppliersStore = supplierStore();
const _service = new BaseService();
const _filteredCustomers = ref<ICustomer[]>([]);
const _site = ref<ISite[]>([]);
const _postcode = ref('');
const _updatedDate = ref('');
const _updatedStartDate = ref('');
const _updatedEndDate = ref('');
const _isLoading = ref(false);
const _disabled = ref(false);
const _disputed = ref(false);
const editModalForm = ref();
const _disputedCode = ref(null);
const _sageReferences = ref<ISageReference[]>([]);
const _emit = defineEmits(['close', 'update:processed']);

const props = defineProps({
	processed: Object,
	processedInvoiceID: Number,
});

const _disputeReasonArray: any = Object.keys(DisputeReasonsEnum)
	.filter((v) => isNaN(Number(v)))
	.map((Name: any) => {
		var val = DisputeReasonsEnum[Name as keyof typeof DisputeReasonsEnum];
		Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

		return {
			Value: val,
			Name,
		};
	});

const _selectProcessed: any = ref(Object.assign({}, props.processed));
const _delayedGetSites = debounce(GetPostCodes, 500);
const _delaySageReferences = debounce(GetSageReferences, 500);
const _updateDateRange = updateDateModels;
const _customers = computed({
	get() {
		return _filteredCustomers.value.length > 0
			? _filteredCustomers.value
			: _customersStore.customers;
	},
	set(val) {
		_customersStore.customers = val;
	},
});

onMounted(async () => {
	_updatedDate.value = FormatDate(
		_selectProcessed.value.InvoiceDate
	).toString();

	_updatedStartDate.value = FormatDate(
		_selectProcessed.value.StartDate
	).toString();

	_updatedEndDate.value = FormatDate(_selectProcessed.value.EndDate).toString();

	if (_selectProcessed.value.Customer?.CustomerId === 0) {
		_selectProcessed.value.Customer = 0;
	}
});

function FormatDate(date: string) {
	if (!date) {
		return 'Not Found';
	} else {
		return moment(date).format('DD/MM/YYYY');
	}
}

const fromToDateRule = (value: string): boolean | string => {
	if (!_updatedStartDate.value || !_updatedEndDate.value) {
		return false || 'Date Required';
	}

	let from = date.extractDate(_updatedStartDate.value, 'DD/MM/YYYY');
	let to = date.extractDate(_updatedEndDate.value, 'DD/MM/YYYY');

	return from <= to || 'From Date must be earlier than or equal to To Date';
};

function updateDateModels() {
	_selectProcessed.value.InvoiceDate =
		moment(_updatedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00';

	_updatedStartDate.value = moment(_selectProcessed.value.InvoiceDate)
		.clone()
		.startOf('month')
		.format('DD/MM/YYYY')
		.toString();
	_updatedEndDate.value = moment(_selectProcessed.value.InvoiceDate)
		.clone()
		.endOf('month')
		.format('DD/MM/YYYY')
		.toString();
}

async function GetPostCodes() {
	_customersStore.isLoading = true;
	_service
		.Get<any>('/Customers/AI/GetPostCodes?postcode=' + _postcode.value)
		.then((postcode: any) => {
			_site.value = postcode;
			let list = [];
			for (let i = 0; i < _site.value.length; i++) {
				list.push(_site.value[i].Customer);
			}

			_filteredCustomers.value = [...new Set(list)] as ICustomer[];
		})
		.finally(() => {
			_customersStore.isLoading = false;
		});
}

async function GetSageReferences() {
	if (_selectProcessed.value.Supplier !== null) {
		_sageReferences.value = [];
		_selectProcessed.value.SageReference = null;
		_service
			.Get<any>(
				'/Suppliers/List/SageReferences?supplierId=' +
					_selectProcessed.value.Supplier.SupplierId
			)
			.then((response: any) => {
				let list = [];
				list = response;
				for (let i = 0; i < list.length; i++) {
					_sageReferences.value.push(list[i].SageReference);
				}
			});
	}
}

async function Submit(): Promise<void> {
	_isLoading.value = true;
	_selectProcessed.value.InvoiceDate =
		moment(_updatedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00';
	_selectProcessed.value.StartDate =
		moment(_updatedStartDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
		'T00:00:00';
	_selectProcessed.value.EndDate =
		moment(_updatedEndDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
		'T00:00:00';
	if (_selectProcessed.value.DisputeCode !== null) {
		for (let i = 0; i < _selectProcessed.value.DisputeCode.length; i++) {
			_disputedCode.value += _selectProcessed.value.DisputeCode[i].Value;
		}
		_selectProcessed.value.Approved = true;
	}

	editModalForm.value.validate().then((success: any) => {
		if (success) {
			_service
				.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
					processedInvoiceIds: [_selectProcessed.value.Id],
					operations: [
						{
							op: 'replace',
							path: '/CustomerId',
							value: _selectProcessed.value.Customer.CustomerId ?? 0,
						},
						{
							op: 'replace',
							path: '/SupplierId',
							value:
								_selectProcessed.value.Supplier != null
									? _selectProcessed.value.Supplier.SupplierId
									: null,
						},
						{
							op: 'replace',
							path: '/Archived',
							value: _selectProcessed.value.Archived,
						},
						{
							op: 'replace',
							path: '/Approved',
							value: _selectProcessed.value.Approved,
						},
						{
							op: 'replace',
							path: '/InvoiceNo',
							value: _selectProcessed.value.InvoiceNo,
						},
						{
							op: 'replace',
							path: '/InvoiceDate',
							value: _selectProcessed.value.InvoiceDate,
						},
						{
							op: 'replace',
							path: '/StartDate',
							value: _selectProcessed.value.StartDate,
						},
						{
							op: 'replace',
							path: '/EndDate',
							value: _selectProcessed.value.EndDate,
						},
						{
							op: 'replace',
							path: '/Net',
							value: _selectProcessed.value.Net,
						},
						{
							op: 'replace',
							path: '/VAT',
							value: _selectProcessed.value.VAT,
						},
						{
							op: 'replace',
							path: '/Total',
							value: _selectProcessed.value.Total,
						},
						{
							op: 'replace',
							path: '/DisputeCode',
							value: _disputedCode.value,
						},
						{
							op: 'replace',
							path: '/DisputeReason',
							value: _selectProcessed.value.DisputeReason,
						},
						{
							op: 'replace',
							path: '/SageReferenceId',
							value:
								_selectProcessed.value.SageReference != null
									? _selectProcessed.value.SageReference.Id
									: null,
						},
					],
				})
				.then(() => {
					_emit('update:processed', _selectProcessed.value);
				})
				.finally(() => {
					_isLoading.value = false;
				});
		} else {
			_isLoading.value = false;
		}
	});
}
</script>
