import { defineStore } from 'pinia';
import { ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { ICustomer } from 'greenzonegateway.classes/lib/classes';

export const customerStore = defineStore('customer', () => {
	//Services
	const service = ref(new BaseService());
	const isLoading = ref(false);

	const customers = ref([] as ICustomer[]);

	async function updateCustomersList(supplierId: any) {
		isLoading.value = true;

		if (supplierId) {
			service.value
				.Get<ICustomer[]>(`Customers/List?supplierId=${supplierId}`)
				.then((usersIn: ICustomer[]) => {
					customers.value = usersIn;
				})
				.finally(() => {
					isLoading.value = false;
				});
		} else {
			service.value
				.Get<ICustomer[]>('Customers/List')
				.then((usersIn: ICustomer[]) => {
					customers.value = usersIn;
				})
				.finally(() => {
					isLoading.value = false;
				});
		}
	}

	return {
		isLoading,
		customers,
		updateCustomersList,
	};
});
