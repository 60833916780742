import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, unref as _unref } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-5"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  key: 1,
  class: "col-5"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { style: {"color":"rgba(0, 0, 0, 0.6)","text-align":"right"} }

import { ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import {
	DatamatchingGroupedResultDTO,
	DataMatchingUpdateBinMapDto,
	ReadServiceDTOShallow,
} from 'greenzonegateway.classes/lib/classes';
import { GZDraggableModal } from '@gz/quasar-components-vue3';

//defines

export default /*@__PURE__*/_defineComponent({
  __name: 'BinMappingModal',
  props: {
	binMappingData: Object,
	isBinSiteMode: { type: Boolean, default: false, require: false },
},
  emits: ['close', 'binUpdated', 'submit'],
  setup(__props, { expose: __expose, emit: __emit }) {

__expose({ Open, Close });
const _emit = __emit;
const _props = __props;
const _service = new BaseService();

//refs
const binMappingModal = ref<typeof GZDraggableModal>();

//flags
const _isLoading = ref(false);
const _isRelevantSearch = ref(true);
const _isSaving = ref(false);

//vars
const _options = ref<ReadServiceDTOShallow[]>([]);
const _servicesList = ref<ReadServiceDTOShallow[]>([]);
const _binSelected = ref<ReadServiceDTOShallow>();

//#region Exposed Functions
function Close() {
	Reset();
	binMappingModal.value?.hide();
}

function Open() {
	binMappingModal.value?.show();
	GetServicesList();
}
//#endregion

function Reset() {
	_isRelevantSearch.value = true;
	_options.value = [];
	_binSelected.value = undefined;
}

function filterFn(val: any, update: any) {
	update(() => {
		const searchVal = val.toLowerCase();
		_options.value = _servicesList.value.filter((v) => {
			if (v && v.ServiceDescription) {
				return v.ServiceDescription.toLowerCase().indexOf(searchVal) > -1;
			}
		});
	});
}

//#region API Calls
function GetServicesList() {
	_isLoading.value = true;
	let url = 'DataMatchingWeights/Services';
	if (
		_isRelevantSearch.value &&
		_props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId
	) {
		url += `?siteId=${_props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId}`;
	}
	_service
		.Get<ReadServiceDTOShallow[]>(url)
		.then((services) => {
			_servicesList.value = services;
			_options.value = services;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostBinMap() {
	if (_props.isBinSiteMode) {
		_emit('submit', _binSelected.value?.ServiceId);
		Close();
		return;
	}
	_isSaving.value = true;
	const _data: DataMatchingUpdateBinMapDto = {
		ServiceId: _binSelected.value?.ServiceId as number,
		BinMapId: _props.binMappingData?.BinResult?.MatchedBinMapping?.Id ?? null,
		ReportImportId: _props.binMappingData?.BinResult.ReportImportId,
	};
	_service
		.Post<DataMatchingUpdateBinMapDto>('DataMatchingWeights/SaveBinMap', _data)
		.then((site: DatamatchingGroupedResultDTO) => {
			_emit('binUpdated', {
				site: site,
				internalSiteRowId: _props.binMappingData?.InternalSiteRowId,
				reportImportId: _props.binMappingData?.ReportImportId,
			});
			Close();
		})
		.finally(() => {
			_isSaving.value = false;
		});
}
//#endregion

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_unref(GZDraggableModal), {
    ref_key: "binMappingModal",
    ref: binMappingModal
  }, {
    header: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-accent text-white" }, {
        default: _withCtx(() => [
          (__props.binMappingData)
            ? (_openBlock(), _createBlock(_component_q_toolbar_title, {
                key: 0,
                class: "text-h5"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.binMappingData.GroupedResult.SupplierName) + " - Bin Mappings", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      (__props.binMappingData)
        ? (_openBlock(), _createBlock(_component_q_card, {
            key: 0,
            style: {"min-width":"1000px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row" }, [
                        _createElementVNode("b", null, "Site")
                      ], -1)),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.binMappingData.GroupedResult.MatchedSiteName || 'N/A'), 1)
                    ]),
                    (!__props.isBinSiteMode)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "row" }, [
                                _createElementVNode("b", null, "Waste Stream Group")
                              ], -1)),
                              _createElementVNode("div", _hoisted_7, _toDisplayString(__props.binMappingData.BinResult.MatchedBinMapping
												?.WasteStreamGroup || 'N/A'), 1)
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row" }, [
                                _createElementVNode("b", null, "Waste Stream")
                              ], -1)),
                              _createElementVNode("div", _hoisted_9, _toDisplayString(__props.binMappingData.BinResult.MatchedBinMapping?.WasteStream ||
											'N/A'), 1)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row" }, [
                                _createElementVNode("b", null, "Bin")
                              ], -1)),
                              _createElementVNode("div", _hoisted_11, _toDisplayString(__props.binMappingData.BinResult.MatchedBinMapping?.Bin || 'N/A'), 1)
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row" }, [
                                _createElementVNode("b", null, "Service Type")
                              ], -1)),
                              _createElementVNode("div", _hoisted_15, _toDisplayString(__props.binMappingData.MappedBinResult?.ServiceTypeName), 1)
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "row" }, [
                                _createElementVNode("b", null, "Service Description")
                              ], -1)),
                              _createElementVNode("div", _hoisted_17, _toDisplayString(__props.binMappingData.MappedBinResult?.ServiceDescription), 1)
                            ])
                          ])
                        ])),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_q_select, {
                        modelValue: _binSelected.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_binSelected).value = $event)),
                        "option-value": `ServiceDescription`,
                        "option-label": `ServiceDescription`,
                        options: _options.value,
                        loading: _isLoading.value,
                        onFilter: filterFn,
                        class: "full-width",
                        label: "Services",
                        "use-input": "",
                        "fill-input": "",
                        "hide-selected": "",
                        dense: "",
                        outlined: ""
                      }, {
                        option: _withCtx((props) => [
                          _createVNode(_component_q_item, _mergeProps(props.itemProps, { dense: "" }), {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("b", null, _toDisplayString(props.opt.ServiceDescription), 1)
                                ]),
                                _createElementVNode("div", _hoisted_21, _toDisplayString(props.opt.ServiceTypeName), 1)
                              ])
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options", "loading"]),
                      _createVNode(_component_q_toggle, {
                        disable: !__props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId,
                        onClick: GetServicesList,
                        label: _isRelevantSearch.value ? 'Most Relevant' : 'All',
                        modelValue: _isRelevantSearch.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_isRelevantSearch).value = $event))
                      }, null, 8, ["disable", "label", "modelValue"]),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(!__props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId
										? 'No site available. Relevant search disabled.'
										: null), 1)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, { onClick: Close }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_q_btn, {
                    color: "primary",
                    disable: !_binSelected.value,
                    loading: _isSaving.value,
                    onClick: PostBinMap
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  }, 8, ["disable", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}
}

})