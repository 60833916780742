import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'GZIndicator',
  props: { color: String },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "indicator",
      style: _normalizeStyle({ backgroundColor: __props.color })
    }, null, 4),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})