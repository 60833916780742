<template>
	<div class="q-ma-none">
		<div id="gz-dropzone" v-show="_isDropzoneVisible">
			<div style="pointer-events: none">Drop the file here...</div>
		</div>
		<q-dialog v-model="_isConfirmDialogVisible">
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title> Upload Spreadsheet - Confirm </q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					Please confirm that you have selected the correct Supplier for the
					file you are uplading
				</q-card-section>
				<q-card-section>
					<div><b>File: </b>{{ _spreadsheetName }}</div>
					<div><b>Supplier: </b>{{ _selectedSupplier?.SupplierName }}</div>
					<div v-if="_additionalFiles">
						<b>Additional Files: </b>
						<div v-for="file in _additionalFiles" :key="file.FileName">
							{{ file.FileName }}
						</div>
					</div>
				</q-card-section>
				<q-card-section>
					<q-checkbox
						v-model="_isUploadConfirmed"
						left-label
						label="I confirm that the supplier selected matches the file being uploaded"
					/>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Cancel"
						color="accent"
						@click="
							{
								_files = [];
								_isConfirmDialogVisible = false;
							}
						"
					/>
					<q-btn
						outline
						color="positive"
						label="Submit"
						:disable="!_isUploadConfirmed"
						:loading="_isLoading"
						@click="PostFile"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="_isNotifyDialogVisible">
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title> Upload Spreadsheet - Notice </q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					Spreadsheet has been sent off to the server for processing, please
					wait 15 minutes and refresh this screen.
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Confirm"
						color="accent"
						@click="_isNotifyDialogVisible = false"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<q-dialog v-model="_isSupplierDialogVisible" persistent>
			<q-card>
				<q-toolbar class="dialog-color">
					<q-toolbar-title>Upload Spreadsheet - Pick Supplier</q-toolbar-title>
				</q-toolbar>
				<q-card-section>
					<b>Uploading:</b> {{ _spreadsheetName }}
					<q-select
						outlined
						:options="_suppliers"
						v-model="_selectedSupplier"
						label="Supplier"
						:rules="_supplierSelectRules"
						:option-value="'SupplierId'"
						:option-label="'SupplierName'"
					></q-select>
					<div
						v-if="_selectedSupplier && _selectedSupplier.MaxFilesPerUpload > 1"
					>
						<b>Additional Uploads: </b>
						<input type="file" multiple @input="HandleExtraFiles" />
						<p>
							Max additional files:
							{{ _selectedSupplier?.MaxFilesPerUpload - 1 }}
						</p>
						<div v-if="_additionalFiles.length">
							<b>Additional Files Added: </b>
							<div v-for="file in _additionalFiles" :key="file.FileName">
								{{ file.FileName }}
							</div>
						</div>
						<p
							align="right"
							style="color: red"
							v-if="_files.length > _selectedSupplier?.MaxFilesPerUpload"
						>
							Too many files uploaded!
						</p>
					</div>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn
						flat
						label="Cancel"
						color="accent"
						@click="
							{
								_files = [];
								_isSupplierDialogVisible = false;
							}
						"
					/>
					<q-btn
						outline
						color="positive"
						label="Submit"
						@click="
							{
								_isSupplierDialogVisible = false;
								_isConfirmDialogVisible = true;
							}
						"
						:loading="_isLoading"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<style lang="scss" scoped>
.dialog-color {
	background-color: var(--q-primary);
	color: white;
}

#gz-dropzone {
	position: absolute;
	top: 64;
	background: rgba(0, 0, 0, 0.5);
	align-content: center;
	text-align: center;
	color: white;
	font-size: xxx-large;
	width: 100%;
	height: 86%;
	z-index: 20;
}
</style>

<script setup lang="ts">
//Minimal implementation for now, going to move it to components later
import { BaseService } from '@/services/BaseService';
import { DataMatchingFile } from 'greenzonegateway.classes/lib/classes';
import { Supplier } from 'greenzonegateway.classes/lib/classes';
import { onMounted, onBeforeUnmount, defineEmits, ref, computed } from 'vue';

const emit = defineEmits(['matchedUpdate']);

const _suppliers = ref<SuppAccsSupplier[]>([]);
const _selectedSupplier = ref<SuppAccsSupplier>();
const _spreadsheetName = ref('');
const _files = ref<{ File: string; FileName: string }[]>([]);

const _isLoading = ref(false);
const _isDropzoneVisible = ref(false);
const _isSupplierDialogVisible = ref(false);
const _isNotifyDialogVisible = ref(false);
const _isConfirmDialogVisible = ref(false);
const _isUploadConfirmed = ref(false);

const _service = new BaseService();

const _supplierSelectRules = [(v: any) => !!v || 'Supplier is required'];

onMounted(() => {
	SubscribeEvents();
});

onBeforeUnmount(() => {
	UnsubscribeEvents();
});

const _additionalFiles = computed(() => {
	return _files.value.slice(1);
});

function EnableDrag(event: DragEvent) {
	if (event.dataTransfer?.items.length) {
		event.dataTransfer.dropEffect = 'copy';
		event.preventDefault();
	}
}

async function HandleExtraFiles(event: InputEvent) {
	_files.value = [_files.value[0]];
	if (!event?.target?.files) {
		return;
	}
	for (let i = 0; i < event.target.files.length; i++) {
		HandleFileUpload(event.target.files[i]);
	}
}

async function HandleDrop(event: DragEvent) {
	_isDropzoneVisible.value = false;
	if (event.dataTransfer?.items.length) {
		[...event.dataTransfer.items].forEach(async (item: DataTransferItem) => {
			if (item.kind === 'file') {
				const fileIn = item.getAsFile() as Blob;
				await HandleFileUpload(fileIn);
				_spreadsheetName.value = _files.value[0].FileName;
				_isSupplierDialogVisible.value = true;
				GetSuppliers();
			}
		});
	}
	event.preventDefault();
}

function blobToBase64(fileIn: Blob) {
	return new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.onload = () => {
			const resultString = reader.result as string;
			const resultStringSplit = resultString.split(',');
			return resolve(
				btoa(resultStringSplit[resultStringSplit.length - 1] as string)
			);
		};
		reader.readAsDataURL(fileIn);
	});
}

async function HandleFileUpload(fileIn: Blob) {
	_files.value.push({
		File: await blobToBase64(fileIn),
		FileName: fileIn.name,
	});
}

function ShowDropzone(event: DragEvent) {
	_isDropzoneVisible.value = true;
	event.preventDefault();
}

function HideDropzone(event?: DragEvent) {
	_isDropzoneVisible.value = false;
	_isSupplierDialogVisible.value = false;
	if (event) {
		event.preventDefault();
	}
}

function SubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.addEventListener('dragenter', ShowDropzone);
	dropzone?.addEventListener('dragenter', EnableDrag);
	dropzone?.addEventListener('dragover', EnableDrag);
	dropzone?.addEventListener('dragleave', HideDropzone);
	dropzone?.addEventListener('drop', HandleDrop);
}

function UnsubscribeEvents() {
	const dropzone = document.getElementById('gz-dropzone');
	window.removeEventListener('dragenter', ShowDropzone);
	dropzone?.removeEventListener('dragenter', EnableDrag);
	dropzone?.removeEventListener('dragover', EnableDrag);
	dropzone?.removeEventListener('dragleave', HideDropzone);
	dropzone?.removeEventListener('drop', HandleDrop);
}

class SuppAccsSupplier extends Supplier {
	constructor() {
		super();
		this.MaxFilesPerUpload = 0;
	}
	MaxFilesPerUpload: number;
}

function GetSuppliers() {
	_isLoading.value = true;
	_service
		.Get<SuppAccsSupplier[]>('DataMatchingWeights/Suppliers')
		.then((suppliers) => {
			_suppliers.value = suppliers;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

type DataOut = {
	Files: { File: string; FileName: string }[];
	SupplierId: number | null;
};

function PostFile() {
	if (!_selectedSupplier.value || !_files.value.length) {
		console.warn('No spreadsheet detected!');
		return;
	}
	let dataOut: DataOut = {
		Files: _files.value,
		SupplierId: _selectedSupplier.value.SupplierId,
	};
	_isLoading.value = true;
	_service
		.Post<DataOut>('/Files/DataMatchingWeights/Upload', dataOut)
		.then(() => {
			_isConfirmDialogVisible.value = false;
			HideDropzone();
			_files.value = [];
			_isNotifyDialogVisible.value = true;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}
</script>
