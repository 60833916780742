<template>
	<q-tr
		:id="`row-${[props.row.InternalSiteRowId]}`"
		style="background-color: rgba(255, 177, 0, 0.05)"
		@click="props.row.expand = !props.row.expand"
	>
		<q-td style="width: 708px">
			<div
				v-if="GetSiteRowColor(props.row.HasProblems, props.row.IsSiteCommitted)"
			></div>
			<GZIndicator
				v-if="GetSiteRowColor(props.row.HasProblems, props.row.IsSiteCommitted)"
				:color="
					GetSiteRowColor(props.row.HasProblems, props.row.IsSiteCommitted)
				"
			>
				<q-tooltip
					v-if="!props.row.IsSiteCommitted || props.row.HasProblems"
					anchor="top middle"
					self="bottom middle"
					:offset="[10, 10]"
				>
					<div v-if="!props.row.IsSiteCommitted">
						One or more bins aren't saved
					</div>
					<div v-if="props.row.HasProblems">One or more bins have problems</div>
				</q-tooltip>
			</GZIndicator>
			<div class="row">
				<div class="col" v-if="!settings.slimGroups">
					<q-tooltip
						anchor="top middle"
						self="bottom middle"
						:offset="[10, 10]"
						v-if="!props.row.IsSiteCommitted || props.row.HasProblems"
					>
						<div v-if="!props.row.IsSiteCommitted">
							One or more bins aren't saved
						</div>
						<div v-if="props.row.HasProblems">
							One or more bins have problems
						</div>
					</q-tooltip>
					<div class="row">
						<b>{{ props.row.SupplierSiteName }}</b>
					</div>
					<div class="row">
						<i>{{ props.row.SupplierSiteAddress }}</i>
					</div>
					<div class="row">
						<i>{{ props.row.SupplierSitePostcode }}</i>
					</div>
				</div>
				<div class="col" v-else>
					<q-tooltip
						anchor="top middle"
						self="bottom middle"
						:offset="[10, 10]"
						v-if="!props.row.IsSiteCommitted || props.row.HasProblems"
					>
						<div v-if="!props.row.IsSiteCommitted">
							One or more bins aren't saved
						</div>
						<div v-if="props.row.HasProblems">
							One or more bins have problems
						</div>
					</q-tooltip>
					<div class="row">
						<b>{{ props.row.SupplierSiteName }}&nbsp;</b>
						<div style="background-color: rgba(0, 0, 0, 0.1)">
							{{ props.row.SupplierSiteAddress }}
						</div>
						<div>&nbsp;{{ props.row.SupplierSitePostcode }}</div>
					</div>
				</div>
			</div>
		</q-td>
		<q-td style="width: 112px">
			<q-btn
				icon="mdi-map-outline"
				:size="settings.slimGroups ? `sm` : `md`"
				round
				flat
				:disable="CheckBinsSaved(props.row)"
				@click.stop="ShowSiteMappingsModal(props.row, props.rowIndex)"
			>
				<q-tooltip class="bg-accent" anchor="top middle">
					<span
						>Site Mapping{{
							CheckBinsSaved(props.row)
								? ` - DISABLED: One of the bins is saved`
								: ``
						}}</span
					>
				</q-tooltip>
			</q-btn>
		</q-td>
		<q-td>
			<div class="col" v-if="!settings.slimGroups">
				<div class="row">
					<b>{{ props.row.MatchedSiteName }}</b>
				</div>
				<div class="row">
					<i>{{ props.row.MatchedSiteAddress }}</i>
				</div>
				<div class="row">
					<i>{{ props.row.MatchedSitePostcode }}</i>
				</div>
			</div>
			<div class="col" v-else>
				<div class="row">
					<b>{{ props.row.MatchedSiteName }}&nbsp;</b>
					<div style="background-color: rgba(0, 0, 0, 0.1)">
						{{ props.row.MatchedSiteAddress }}
					</div>
					<div>&nbsp;{{ props.row.MatchedSitePostcode }}</div>
				</div>
			</div>
		</q-td>
		<q-td style="width: 86px">
			<q-btn
				size="sm"
				color="accent"
				round
				dense
				@click.stop="props.row.expand = !props.row.expand"
				:icon="props.row.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
			/>
		</q-td>
	</q-tr>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from 'vue';
import { DatamatchingGroupedResultDTO } from 'greenzonegateway.classes/lib/classes';
import GZIndicator from '@/components/GZIndicator.vue';

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number | null | undefined;
	expand: boolean;
}

const props = computed({
	get() {
		return _props.propsIn;
	},
	set(value: any) {
		_emit('update:propsIn', value);
	},
});

const _props = defineProps<{
	settings: { slimGroups: boolean; slimItems: boolean };
	propsIn: object;
}>();
const _emit = defineEmits([
	'showModalScheduleMapping',
	'showModalBinMappings',
	'showModalSiteMappings',
	'openSidePanel',
	'clearSelections',
	'update:propsIn',
]);

function GetSiteRowColor(hasProblems: boolean, IsSiteCommitted: boolean) {
	let colorOut = '';
	IsSiteCommitted ? (colorOut = 'var(--q-positive)') : null;
	!IsSiteCommitted && hasProblems ? (colorOut = 'var(--q-negative)') : null;
	return colorOut;
}

function CheckBinsSaved(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	let savedBinsCount = 0;
	rowData.BinResults.forEach((bin) => {
		bin.WeightEntered?.Display ? savedBinsCount++ : null;
	});

	return savedBinsCount ? true : false;
}

function ShowSiteMappingsModal(
	rowData: DatamatchingGroupedResultDTOInternal,
	index: number
) {
	_emit('showModalSiteMappings', { rowData, index });
}
</script>

<style></style>
