<template>
	<div>
		<q-toolbar class="bg-accent text-white q-pr-none">
			<q-toolbar-title
				>Weight Data Matching - Matched Spreadsheets - BETA
				RELEASE</q-toolbar-title
			>
		</q-toolbar>
		<div style="padding-left: 56px">
			<GZDragAndDrop></GZDragAndDrop>
			<q-table
				:columns="columns"
				:rows="_items"
				:loading="_isLoading"
				v-model:pagination="_pagination"
				style="height: 80vh; position: relative; width: 100%"
				hide-pagination
				row-key="Id"
				selection="single"
				binary-state-sort
				return-object
				flat
				:table-header-style="{
					backgroundColor: $q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}"
			>
				<template v-slot:body="props">
					<q-tr :props="props">
						<q-td>
							<GZIndicator :color="GetFileStatusColor(props.row.Status)">
								<q-tooltip>
									<span>{{ GetToolTipStatus(props.row.Status) }}</span>
								</q-tooltip>
							</GZIndicator>
						</q-td>
						<q-td
							v-for="col in props.cols"
							style="padding: 0px; padding-left: 16px"
							:key="col.name"
							:props="props"
							@click="OpenDataMatchingDetails(props.row)"
						>
							<div class="row full-height padding: 8px;">
								<div class="col-10" style="align-content: end">
									<q-tooltip>
										<span>{{ GetToolTipStatus(props.row.Status) }}</span>
									</q-tooltip>
									<p>{{ col.value }}</p>
								</div>
							</div>
							<q-menu context-menu touch-position>
								<q-list dense style="width: 200px">
									<q-item clickable v-close-popup style="padding: 16px">
										<q-item-section
											@click="ChangeSheetStatus(props.row, props.rowIndex)"
										>
											{{ GetMarkSheetOptionName(props.row.Status) }}
										</q-item-section>
									</q-item>
									<q-item clickable style="padding: 16px">
										<q-item-section> Asign to... </q-item-section>
										<q-item-section side
											><q-icon name="mdi-chevron-right"></q-icon
										></q-item-section>
										<q-menu anchor="top end" self="top start">
											<q-list dense style="width: 200px">
												<q-item
													v-for="user in _users"
													:key="user.UserId"
													@click="
														PostAssignUser(
															user.AzureId.toString(),
															props.row.Id
														)
													"
													:disable="_isLoading"
													clickable
													v-close-popup
													style="padding: 16px"
												>
													<q-item-section>{{ user.FullName }}</q-item-section>
												</q-item>
											</q-list>
										</q-menu>
									</q-item>
								</q-list>
							</q-menu>
						</q-td>
					</q-tr>
				</template>

				<template v-slot:no-data>
					<div class="full-width row flex-center q-gutter-sm">
						<q-icon size="2em" name="mdi-alert" />
						<span>No data available</span>
					</div>
				</template>
			</q-table>
			<GZPagination
				:items-length="_items.length"
				v-model:pagination="_pagination"
			></GZPagination>
		</div>
		<q-dialog v-model="_isDialogVisible" style="width: 500px">
			<q-card height="150px">
				<q-card-section> </q-card-section>
				<q-card-actions align="right">
					<q-space />
					<q-btn color="primary" v-close-popup> Close </q-btn>
					<q-space />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { GZPagination } from '@gz/quasar-components-vue3';
import { DataMatchingFile, User } from 'greenzonegateway.classes/lib/classes';

import router from '@/router';
import GZIndicator from '@/components/GZIndicator.vue';
import GZDragAndDrop from '@/components/GZDragAndDrop.vue';

const _service = new BaseService();

const _isLoading = ref(false);
const _isDialogVisible = ref(false);

const _items = ref<DataMatchingFile[]>([]);
const _users = ref<User[]>();
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});
const columns = [
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.SupplierName,
	},
	{
		name: 'FileName',
		label: 'File Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.FileName,
	},
	{
		name: 'AssignedTo',
		label: 'Assigned To',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.AssignedToFullName || 'Unassigned',
	},
	{
		name: 'ImportDate',
		label: 'Import Date',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.ImportDate.Display,
	},
];

enum DataMatchingFileStatusEnum {
	Processing = 0,

	Processed = 1,

	Completed = 2,
}

interface UpdateSheetStatusDto {
	FileId: number;
	Status: DataMatchingFileStatusEnum;
}

onMounted(() => {
	GetSpreadSheetFiles();
	GetUsers();
});

function GetFileStatusColor(number: number) {
	if (number === 2) {
		return 'var(--q-positive)';
	} else if (number === 1) {
		return 'var(--q-warning)';
	} else if (number === 0) {
		return 'var(--q-negative)';
	}
}

function GetToolTipStatus(enumNum: number) {
	return DataMatchingFileStatusEnum[enumNum];
}

function GetMarkSheetOptionName(option: DataMatchingFileStatusEnum) {
	switch (option) {
		case DataMatchingFileStatusEnum.Processed:
			return 'Complete';
		case DataMatchingFileStatusEnum.Completed:
			return 'Revert Complete';
		default:
			return 'Still Processing...';
	}
}

function OpenDataMatchingDetails(row: { Id: number }) {
	router.push({
		path: `/supplieraccounts/weightdatamatching/spreadsheets/details/${row.Id}`,
	});
}

function ChangeSheetStatus(row: DataMatchingFile, index: number) {
	switch (row.Status) {
		case DataMatchingFileStatusEnum.Processed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Completed, index);
			break;
		case DataMatchingFileStatusEnum.Completed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Processed, index);
			break;
		default:
			return;
	}
}

function PostAssignUser(UserId: string, FileId: number) {
	let data: { UserId: string; FileId: number } = {
		UserId,
		FileId,
	};
	_service
		.Post('DataMatchingWeights/AssignUser', data)
		.then((response: DataMatchingFile[]) => {
			if (!_items.value) {
				return;
			}
			let fileIndex = _items.value?.findIndex((file) => file.Id === FileId);
			_items.value[fileIndex] = response[0];
		})
		.catch((err) => {
			console.log(err);
		});
}

function GetUsers() {
	if (_users.value && _users.value.length) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<User[]>('DataMatchingWeights/Users')
		.then((response) => {
			_users.value = response;
		})
		.catch((err) => {
			console.log(err);
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function GetSpreadSheetFiles() {
	_items.value = [];
	_service
		.Get<DataMatchingFile[]>('DataMatchingWeights/Processed')
		.then((response) => {
			_items.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

function PostSheetStatus(
	id: number,
	status: DataMatchingFileStatusEnum,
	index: number
) {
	let data: UpdateSheetStatusDto = {
		FileId: id,
		Status: status,
	};
	_service
		.Post<UpdateSheetStatusDto>('/DataMatchingWeights/UpdateSheetStatus', data)
		.then((dataOut: DataMatchingFile[]) => {
			if (_items.value) {
				_items.value[index] = dataOut[0];
			}
		});
}
</script>

<style lang="scss">
@import '../styles/base-styles.scss';
</style>
