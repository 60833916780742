import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home internal-container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = { class: "pa-4 layout-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6 q-pa-md" }
const _hoisted_5 = { class: "row q-pa-md" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { style: {"padding-left":"4px"} }
const _hoisted_8 = { class: "col-6 q-pa-md" }
const _hoisted_9 = { class: "full-width row flex-center q-gutter-sm" }

import { ref, onMounted } from 'vue';
import { BaseService } from '@/services/BaseService';
import { supplierStore } from '@/store/suppliers';
import QGenericSelect from '@/components/QGenericSelect.vue';
import { ITaxCode } from 'greenzonegateway.classes/lib/classes/Generic';
import {
	ISupplierDefaults,
	PaymentTypeEnum,
} from 'greenzonegateway.classes/lib/classes/dataMatching';

export default /*@__PURE__*/_defineComponent({
  __name: 'SuppliedDataMatchingDefaults',
  setup(__props) {

const rules = ref([(val: any) => val != null || 'This field is required']);

const _paymentTypeArray: any = Object.keys(PaymentTypeEnum)
	.filter((v) => isNaN(Number(v)))
	.map((Name: any) => {
		let val = PaymentTypeEnum[Name as keyof typeof PaymentTypeEnum];
		Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

		return {
			Value: val,
			Name,
		};
	});

const _suppliersStore = supplierStore();
const _service = new BaseService();
const _supplierInvoiceDefaults = ref([]);
const _isLoading = ref<boolean>(false);
const _isTableLoading = ref(false);
const _selectedPaymentType: any = ref(null);
const _taxCodeId: any = ref(null);
const _supplierId: any = ref(null);
const _headers = [
	{
		label: 'Tax Code',
		align: 'left',
		sortable: false,
		field: 'TaxCode.Name',
	},
	{ label: 'Supplier', align: 'left', field: 'Supplier.SupplierName' },
	{ label: 'Payment Type', align: 'left', field: 'PaymentType' },
];

const _taxCodes = ref([] as ITaxCode[]);
const _supplierDefaults = ref<ISupplierDefaults>({
	TaxCodeId: _taxCodeId,
	SupplierId: _supplierId,
	PaymentType: _selectedPaymentType,
});
const _modelValue = ref<ISupplierDefaults>({
	TaxCodeId: _taxCodeId,
	SupplierId: _supplierId,
	PaymentType: _selectedPaymentType,
});
function GetSupplierDefaults() {
	_isTableLoading.value = true;
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/SupplierDefaults')
		.then((mapping: any) => {
			_supplierInvoiceDefaults.value = mapping;
			_isTableLoading.value = false;
		});
}
function getTaxCodes() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/TaxCodes')
		.then((taxcodes: any) => {
			_taxCodes.value = taxcodes;
		});
}

async function PostSupplierDefaults(): Promise<void> {
	_isLoading.value = true;
	_supplierDefaults.value.PaymentType = _modelValue.value.PaymentType.Value;
	_supplierDefaults.value.SupplierId = _modelValue.value.SupplierId.SupplierId;
	_supplierDefaults.value.TaxCodeId = _modelValue.value.TaxCodeId.Id;
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/SupplierDefaults/Create',
			_supplierDefaults.value
		)
		.then(() => {
			_selectedPaymentType.value = null;
			_taxCodeId.value = null;
			_supplierId.value = null;

			GetSupplierDefaults();
		})
		.finally(() => {
			_isLoading.value = false;
		});
}
function OnReset() {
	_modelValue.value.TaxCodeId = null;
	_modelValue.value.PaymentType = null;
	_modelValue.value.SupplierId = null;
}
onMounted(() => {
	GetSupplierDefaults();
	getTaxCodes();
});

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_layout, {
      class: "header-bar",
      view: "hHh Lpr fff"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pt-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar_title, { style: {"padding":"12px !important"} }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Supplier Defaults")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_page_container, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_card, {
                    class: "p-0",
                    style: {"height":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, { class: "bg-accent text-white" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("div", { class: "text-h6" }, "New Supplier Defaults", -1)
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_q_form, {
                            onSubmit: _cache[3] || (_cache[3] = ($event: any) => (PostSupplierDefaults())),
                            onReset: _cache[4] || (_cache[4] = ($event: any) => (OnReset())),
                            class: "q-gutter-md"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(QGenericSelect, {
                                  items: _unref(_suppliersStore).getSuppliers,
                                  name: "SupplierName",
                                  labeling: "Supplier",
                                  modelValue: _modelValue.value.SupplierId,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_modelValue.value.SupplierId) = $event)),
                                  "second-label": "AccountNumber",
                                  rules: rules.value
                                }, null, 8, ["items", "modelValue", "rules"]),
                                _createVNode(QGenericSelect, {
                                  items: _taxCodes.value,
                                  name: "Name",
                                  labeling: "Tax Codes",
                                  modelValue: _modelValue.value.TaxCodeId,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_modelValue.value.TaxCodeId) = $event)),
                                  rules: rules.value
                                }, null, 8, ["items", "modelValue", "rules"]),
                                _createVNode(QGenericSelect, {
                                  items: _unref(_paymentTypeArray),
                                  name: "Name",
                                  labeling: "Payment Type",
                                  modelValue: _modelValue.value.PaymentType,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_modelValue.value.PaymentType) = $event)),
                                  rules: rules.value
                                }, null, 8, ["items", "modelValue", "rules"])
                              ]),
                              _createVNode(_component_q_separator),
                              _createElementVNode("div", null, [
                                _createVNode(_component_q_btn, {
                                  label: "Submit",
                                  type: "submit",
                                  color: "primary"
                                }),
                                _createVNode(_component_q_btn, {
                                  label: "Reset",
                                  type: "reset",
                                  color: "primary",
                                  flat: "",
                                  loading: _isLoading.value,
                                  class: "q-ml-sm"
                                }, null, 8, ["loading"])
                              ])
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_card, { style: {"height":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_table, {
                        columns: _headers,
                        rows: _supplierInvoiceDefaults.value,
                        "rows-per-page-options": [0],
                        "virtual-scroll-sticky-size-start": 20,
                        "item-value": "name",
                        class: "elevation-1",
                        loading: _isTableLoading.value,
                        style: {"height":"inherit"}
                      }, {
                        body: _withCtx((props) => [
                          _createVNode(_component_q_tr, { props: props }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_td, { key: "SupplierName" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(props.row.TaxCode.Name), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_q_td, { key: "Name" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(props.row.Supplier.SupplierName), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_q_td, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(PaymentTypeEnum)[props.row.PaymentType]), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["props"])
                        ]),
                        "no-data": _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_q_icon, {
                              size: "2em",
                              name: "mdi-alert"
                            }),
                            _cache[7] || (_cache[7] = _createElementVNode("span", null, "No data available", -1))
                          ])
                        ]),
                        pagination: _withCtx(() => _cache[8] || (_cache[8] = [])),
                        _: 1
                      }, 8, ["rows", "loading"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})