import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { ISupplier } from 'greenzonegateway.classes/lib/classes/supplier/interfaces/ISupplier';

export const supplierStore = defineStore('supplier', () => {
	//Services
	const service = new BaseService();
	const suppliersPromise = ref();

	const suppliers = ref([] as ISupplier[]);
	const suppliersBackup = ref([] as ISupplier[]);

	let isLoading = false;

	function updateSuppliersList() {
		isLoading = true;
		suppliersPromise.value = service.Get<ISupplier[]>('Suppliers/List');

		suppliersPromise.value.then((usersInternal: ISupplier[]) => {
			suppliers.value = usersInternal;
			suppliersBackup.value = usersInternal;
			isLoading = false;
		});
	}

	const getSuppliers = computed(() => {
		if (suppliers.value.length === 0 && !isLoading) {
			isLoading = true;
			updateSuppliersList();
		}
		return suppliers.value;
	});
	const FilterSuppliers = (search: string) => {
		if (!search.length) {
			suppliers.value = suppliersBackup.value;
		}

		suppliers.value = suppliersBackup.value.filter(
			(supplier) =>
				supplier.SupplierName?.toLowerCase().includes(search.toLowerCase()) ||
				supplier.Status.Name?.toLowerCase().includes(search.toLowerCase()) ||
				supplier.BagRequestEmail?.toLowerCase().includes(
					search.toLowerCase()
				) ||
				supplier.Address?.AddressLine1?.toLowerCase().includes(
					search.toLowerCase()
				) ||
				supplier.Address?.AddressLine2?.toLowerCase().includes(
					search.toLowerCase()
				) ||
				supplier.Address?.AddressLine3?.toLowerCase().includes(
					search.toLowerCase()
				) ||
				supplier.Address?.Town?.toLowerCase().includes(search.toLowerCase()) ||
				supplier.Address?.Postcode?.toLowerCase().includes(
					search.toLowerCase()
				) ||
				supplier.SupplierId?.toString().includes(search)
		);
	};

	return {
		getSuppliers,
		updateSuppliersList,
		suppliersPromise,
		FilterSuppliers,
	};
});
