import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding-left":"56px"}
}
const _hoisted_2 = { class: "full-width row flex-center q-gutter-sm" }
const _hoisted_3 = {
  key: 1,
  style: {"padding-left":"56px"}
}
const _hoisted_4 = { class: "full-width row flex-center q-gutter-sm" }

import { BaseService } from '@/services/BaseService';
import { onMounted, ref, nextTick, computed } from 'vue';
import { GZPagination } from '@gz/quasar-components-vue3';
import {
	SiteMappingModal,
	BinMappingModal,
} from '../../components/WeightDataMatching';
import { mdiTrashCan, mdiHome } from '@quasar/extras/mdi-v7';


export default /*@__PURE__*/_defineComponent({
  __name: 'BinSiteMappings',
  setup(__props) {

const _service = new BaseService();

const _columnsBins = ref([
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		style: 'width: 320px; max-width: 320px',
		field: (row: any) => row.Supplier?.SupplierName,
	},
	{
		name: 'Bin',
		label: 'Bin',
		align: 'left',
		sortable: true,
		style: 'width: 300px; max-width: 300px',
		field: (row: any) => row.Bin,
	},
	{
		name: 'WasteStream',
		label: 'Waste Stream',
		align: 'left',
		sortable: true,
		style: 'width: 250px; max-width: 250px',
		field: (row: any) => row.WasteStream,
	},
	{
		name: 'WasteStreamGroup',
		label: 'Waste Stream Group',
		align: 'left',
		sortable: true,
		style: 'width: 180px; max-width: 180px',
		field: (row: any) => row.WasteStreamGroup,
	},
	{
		name: 'SiteAddress',
		label: 'Site Address',
		align: 'left',
		sortable: true,
		style: 'width: 180px; max-width: 180px',
		field: (row: any) => row.MappedSite?.SiteReference || 'N/A',
	},
	{
		name: 'CreatedTime',
		label: 'Created Time',
		align: 'left',
		sortable: true,
		style: 'width: 160px; max-width: 160px',
		field: (row: any) => row.CreatedDate?.Display,
	},
	{
		name: 'CreatedBy',
		label: 'Created By',
		align: 'left',
		sortable: true,
		style: 'width: 200px; max-width: 200px',
		field: (row: any) => row.CreatedBy,
	},
	{
		name: 'LastUsed',
		label: 'Last Used',
		align: 'left',
		sortable: true,
		style: 'width: 160px; max-width: 160px',
		field: (row: any) => row.LastUsedDate?.Display,
	},
]);

const _columnsSites = ref([
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		style: 'width: 320px; max-width: 320px',
		field: (row: any) => row.Supplier?.SupplierName,
	},
	{
		name: 'SiteDescription',
		label: 'Site Description',
		align: 'left',
		style: 'width: 570px; max-width: 570px',

		sortable: true,
		field: (row: any) => row.SiteDescription,
	},
	{
		name: 'SiteRef',
		label: 'Site Ref',
		align: 'left',
		sortable: true,
		style: 'width: 380px; max-width: 380px',
		field: (row: any) => row.SiteRef,
	},
	{
		name: 'SitePostcode',
		label: 'Site Postcode',
		align: 'left',
		sortable: true,
		field: (row: any) => row.SitePostcode,
	},
	{
		name: 'CreatedTime',
		label: 'Created Time',
		align: 'left',
		sortable: true,
		field: (row: any) => row.CreatedDate?.Display,
	},
	{
		name: 'CreatedBy',
		label: 'Created By',
		align: 'left',
		style: 'width: 200px; max-width: 200px',
		sortable: true,
		field: (row: any) => row.CreatedBy,
	},
	{
		name: 'LastUsed',
		label: 'Last Used',
		align: 'left',
		sortable: true,
		field: (row: any) => row.LastUsedDate?.Display,
	},
]);

const _itemsBins = ref([]);
const _itemsSites = ref([]);

const siteMappingModal = ref<typeof SiteMappingModal>();
const binMappingModal = ref<typeof BinMappingModal>();

const _siteMappingData = ref();
const _binMappingData = ref();

const _binSelected = ref();
const _siteSelected = ref();

const _wildcardSearch = ref('');

const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _pagination2 = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});

const _isBinsMode = ref(false);
const _isLoading = ref(false);

const _computedBins = computed(() => {
	if (_wildcardSearch.value && _isBinsMode.value) {
		return _itemsBins.value.filter((bin: any) => {
			return (
				bin.Supplier?.SupplierName?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.Bin?.toLowerCase().includes(_wildcardSearch.value.toLowerCase()) ||
				bin.WasteStream?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.WasteStreamGroup?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.SiteReference?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.AddressLine1?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.Postcode?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.MappedSite?.Address?.Town?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.CreatedBy?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				bin.CreatedDate?.Display.includes(_wildcardSearch.value.toLowerCase())
			);
		});
	}
	return _itemsBins.value;
});

const _computedSites = computed(() => {
	if (_wildcardSearch.value && !_isBinsMode.value) {
		return _itemsSites.value.filter((site: any) => {
			return (
				site.Supplier?.SupplierName?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SiteDescription?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SiteRef?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.SitePostcode?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.CreatedDate?.Display?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.CreatedBy?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				) ||
				site.LastUsedDate?.Display?.toLowerCase().includes(
					_wildcardSearch.value.toLowerCase()
				)
			);
		});
	}
	return _itemsSites.value;
});

onMounted(() => {
	GetDataBins();
	GetDataSites();
});

function OpenBinDialog(row: any) {
	_binSelected.value = row;
	const payload = {
		GroupedResult: {
			SupplierName: row?.Supplier?.SupplierName,
			MatchedSiteName: row.MappedSite?.SiteName,
			MatchedSiteMap: { SiteId: row.MappedSite?.SiteId },
		},
		BinResult: {
			MatchedBinMapping: {
				WasteStreamGroup: row.WasteStreamGroup,
				WasteStream: row.WasteStream,
				Bin: row.Bin,
				Id: row.Id,
			},
		},
		MappedBinResult: {
			ServiceTypeName: row.MappedService?.ServiceTypeName,
			ServiceDescription: row.MappedService?.ServiceDescription,
		},
	};
	_binMappingData.value = payload;
	nextTick(() => {
		binMappingModal.value?.Open();
	});
}

function OpenSiteDialog(row: any) {
	_siteSelected.value = row;
	const payload = {
		GroupedResult: {
			SupplierName: row?.Supplier?.SupplierName,
			MatchedSiteName: row.MappedSite?.SiteName,
			MatchedSitePostcode: row.MappedSite?.Address?.Postcode,
			MatchedSiteAddress: row.MappedSite?.Address?.AddressLine1,
			SupplierId: row.Supplier?.SupplierId,
		},
	};
	_siteMappingData.value = payload;
	nextTick(() => {
		siteMappingModal.value?.Open();
	});
}

function BinDialogComplete(ServiceId: number) {
	const operations = [
		{
			op: 'replace',
			path: '/ServiceId',
			value: ServiceId,
		},
	];
	nextTick(() => {
		UpdateBinRow(_binSelected.value, operations);
	});
}

function SiteDialogComplete(SiteId: number) {
	const operations = [
		{
			op: 'replace',
			path: '/SiteId',
			value: SiteId,
		},
	];
	nextTick(() => {
		UpdateSiteRow(_siteSelected.value, operations);
	});
}

function RemoveRow(row: any, siteBin: number) {
	const operations = [
		{
			op: 'replace',
			path: '/Status',
			value: 2, //enum
		},
	];
	if (siteBin === 0) {
		UpdateBinRow(row, operations, true);
	}
	if (siteBin === 1) {
		UpdateSiteRow(row, operations, true);
	}
}

function UpdateBinRow(row: any, operations: any[], remove = false) {
	const payload = {
		binMapId: row.Id,
		note: remove
			? 'Mapping Management - Reject'
			: 'Mapping Management - Update',
		operations: operations,
	};
	_service
		.Patch<any>('DataMatchingWeights/Mapping/BinMap', payload)
		.then((response) => {
			if (remove) {
				UpdateTableRowData(row.Id, 0, null, true);
			} else {
				UpdateTableRowData(row.Id, 0, response[0]);
			}
		})
		.catch((err) => {
			console.log(err);
		});
}

function UpdateSiteRow(row: any, operations: any[], remove = false) {
	const payload = {
		siteMapId: row.Id,
		note: remove
			? 'Mapping Management - Reject'
			: 'Mapping Management - Update',
		operations: operations,
	};
	_service
		.Patch<any>('DataMatchingWeights/Mapping/SiteMap', payload)
		.then((response) => {
			if (remove) {
				UpdateTableRowData(row.Id, 1, null, true);
			} else {
				UpdateTableRowData(row.Id, 1, response[0]);
			}
		})
		.catch((err) => {
			console.log(err);
		});
}

function UpdateTableRowData(
	id: string | number,
	siteBin: number,
	data?: any,
	remove = false
) {
	if (siteBin === 0) {
		let index = _itemsBins.value.findIndex((bin: any) => {
			return bin.Id === id;
		});
		if (remove) {
			_itemsBins.value.splice(index, 1);
		} else if (!remove && data) {
			_itemsBins.value[index] = data;
		}
	}
	if (siteBin === 1) {
		let index = _itemsSites.value.findIndex((site: any) => {
			return site.Id === id;
		});
		if (remove) {
			_itemsSites.value.splice(index, 1);
		} else if (!remove && data) {
			_itemsSites.value[index] = data;
		}
	}
}

function GetDataBins() {
	_service
		.Get<any>('DataMatchingWeights/Mapping/BinMaps')
		.then((response) => {
			_itemsBins.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

function GetDataSites() {
	_service
		.Get<any>('/DataMatchingWeights/Mapping/SiteMaps')
		.then((response) => {
			_itemsSites.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pr-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, null, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Weight Data Matching - Matched Spreadsheets")
          ])),
          _: 1
        }),
        _createVNode(_component_q_input, {
          type: "text",
          modelValue: _wildcardSearch.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_wildcardSearch).value = $event)),
          label: "Search",
          outlined: "",
          dense: "",
          dark: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          icon: _unref(mdiTrashCan),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_isBinsMode.value = true)),
          class: "q-ml-sm"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Bins")
          ])),
          _: 1
        }, 8, ["icon"]),
        _createVNode(_component_q_btn, {
          icon: _unref(mdiHome),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_isBinsMode.value = false)),
          class: "q-mx-sm"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Sites")
          ])),
          _: 1
        }, 8, ["icon"])
      ]),
      _: 1
    }),
    (_isBinsMode.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_table, {
            columns: _columnsBins.value,
            rows: _computedBins.value,
            loading: _isLoading.value,
            title: "Bins",
            pagination: _pagination.value,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_pagination).value = $event)),
            style: {"height":"80vh","position":"relative","width":"100%"},
            "hide-pagination": "",
            "row-key": "name",
            "binary-state-sort": "",
            "return-object": "",
            flat: "",
            "table-header-style": {
					backgroundColor: _ctx.$q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}
          }, {
            "body-cell": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: ($event: any) => (OpenBinDialog(props.row)),
                class: "bin-site-overflow"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.value), 1)
                ]),
                _: 2
              }, 1032, ["props", "onClick"]),
              _createVNode(_component_q_menu, {
                "context-menu": "",
                "touch-position": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, {
                    dense: "",
                    style: {"width":"200px"}
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        onClick: ($event: any) => (RemoveRow(props.row, 0)),
                        style: {"padding":"16px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Delete ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [_directive_close_popup]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            "body-cell-SiteAddress": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.MappedSite?.Address?.AddressLine1) + " ", 1),
                  _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(props.row.MappedSite?.Address?.Postcode) + " ", 1),
                  _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(props.row.MappedSite?.Address?.Town), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "no-data": _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_icon, {
                  size: "2em",
                  name: "mdi-alert"
                }),
                _cache[13] || (_cache[13] = _createElementVNode("span", null, "No data available", -1))
              ])
            ]),
            _: 1
          }, 8, ["columns", "rows", "loading", "pagination", "table-header-style"]),
          _createVNode(_unref(GZPagination), {
            "items-length": _computedBins.value.length,
            pagination: _pagination.value,
            "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_pagination).value = $event))
          }, null, 8, ["items-length", "pagination"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_q_table, {
            columns: _columnsSites.value,
            rows: _computedSites.value,
            loading: _isLoading.value,
            title: "Sites",
            pagination: _pagination2.value,
            "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_pagination2).value = $event)),
            style: {"height":"80vh","position":"relative","width":"100%"},
            "hide-pagination": "",
            "row-key": "Id",
            "binary-state-sort": "",
            "return-object": "",
            flat: "",
            "table-header-style": {
					backgroundColor: _ctx.$q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}
          }, {
            "body-cell": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: ($event: any) => (OpenSiteDialog(props.row)),
                class: "bin-site-overflow"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.value), 1)
                ]),
                _: 2
              }, 1032, ["props", "onClick"]),
              _createVNode(_component_q_menu, {
                "context-menu": "",
                "touch-position": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_list, {
                    dense: "",
                    style: {"width":"200px"}
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        onClick: ($event: any) => (RemoveRow(props.row, 1)),
                        style: {"padding":"16px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_section, null, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Delete ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [_directive_close_popup]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            "no-data": _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_q_icon, {
                  size: "2em",
                  name: "mdi-alert"
                }),
                _cache[15] || (_cache[15] = _createElementVNode("span", null, "No data available", -1))
              ])
            ]),
            _: 1
          }, 8, ["columns", "rows", "loading", "pagination", "table-header-style"]),
          _createVNode(_unref(GZPagination), {
            "items-length": _computedSites.value.length,
            pagination: _pagination2.value,
            "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => ((_pagination2).value = $event))
          }, null, 8, ["items-length", "pagination"])
        ])),
    _createVNode(_unref(SiteMappingModal), {
      ref_key: "siteMappingModal",
      ref: siteMappingModal,
      onSubmit: SiteDialogComplete,
      isBinSiteMode: true,
      siteMappingData: _siteMappingData.value
    }, null, 8, ["siteMappingData"]),
    _createVNode(_unref(BinMappingModal), {
      ref_key: "binMappingModal",
      ref: binMappingModal,
      onSubmit: BinDialogComplete,
      isBinSiteMode: true,
      binMappingData: _binMappingData.value
    }, null, 8, ["binMappingData"])
  ]))
}
}

})