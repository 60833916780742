<template>
	<div style="display: contents">
		<q-td>
			<GZIndicator
				v-if="
					binResult.WeightEntered &&
					GetBinRowColor(
						binResult.Problems.length,
						binResult.WeightEntered.Display
					)
				"
				:color="
					GetBinRowColor(
						binResult.Problems.length,
						binResult.WeightEntered.Display
					)
				"
			>
				<q-tooltip
					v-if="
						!binResult.WeightEntered.Display || binResult.Problems.length > 0
					"
					anchor="top middle"
					self="bottom middle"
					:offset="[10, 10]"
				>
					<div v-if="!binResult.WeightEntered.Display">
						Bin not yet commited
					</div>
					<div v-for="problem in binResult.Problems" :key="problem">
						{{ TranslateProblemEnum(problem) }}
					</div>
				</q-tooltip>
			</GZIndicator>
			<div
				class="row"
				:style="{ height: settings.slimItems ? '24px' : '48px' }"
			>
				<div
					class="col-8 q-pl-md"
					style="align-content: center"
					v-if="!settings.slimItems"
				>
					<q-tooltip
						anchor="top middle"
						self="bottom middle"
						:offset="[10, 10]"
						v-if="
							!binResult.WeightEntered?.Display || binResult.Problems.length > 0
						"
					>
						<div v-if="!binResult.WeightEntered?.Display">
							Bin not yet commited
						</div>
						<div v-for="problem in binResult.Problems" :key="problem">
							{{ TranslateProblemEnum(problem) }}
						</div>
					</q-tooltip>
					<div class="row">
						<b>{{ binResult.SupplierServiceDescriptionDisplayLine2 }}</b>
					</div>
					<div class="row">
						{{ binResult.SupplierServiceDescriptionDisplayLine1 }}
					</div>
				</div>
				<div class="col-8 q-pl-md" style="align-content: center" v-else>
					<q-tooltip
						anchor="top middle"
						self="bottom middle"
						:offset="[10, 10]"
						v-if="
							!binResult.WeightEntered?.Display || binResult.Problems.length > 0
						"
					>
						<div v-if="!binResult.WeightEntered?.Display">
							Bin not yet commited
						</div>
						<div v-for="problem in binResult.Problems" :key="problem">
							{{ TranslateProblemEnum(problem) }}
						</div>
					</q-tooltip>
					<div class="row">
						<b>{{ binResult.SupplierServiceDescriptionDisplayLine2 }}&nbsp;</b>
						<div style="background-color: rgba(0, 0, 0, 0.1)">
							{{ binResult.SupplierServiceDescriptionDisplayLine1 }}
						</div>
					</div>
				</div>
				<div class="col q-pl-md" style="align-content: center">
					{{ binResult.SupplierServiceDate?.Display }}
				</div>
				<div class="col q-pl-md" style="align-content: center">
					{{ binResult.SupplierActualWeightKgDisplay }}
				</div>
			</div>
		</q-td>
		<q-td>
			<q-btn
				icon="mdi-cup-outline"
				:size="settings.slimItems ? `sm` : `md`"
				round
				flat
				:disable="!!binResult.WeightEntered?.Display"
				@click="ShowBinMappingsModal(row, binResult, rowIndex)"
			>
				<q-tooltip class="bg-accent" anchor="top middle">
					<span
						>Bin Mapping{{
							!!binResult.WeightEntered?.Display ? ` - DISABLED: Bin saved` : ``
						}}</span
					>
				</q-tooltip>
			</q-btn>
			<q-btn
				icon="mdi-file-document-outline"
				:size="settings.slimItems ? `sm` : `md`"
				:disable="!!binResult.WeightEntered?.Display || !binResult.onSite"
				round
				flat
				class="q-ml-md"
				@click="ShowModalScheduleMapping(row, binResult)"
			>
				<q-tooltip class="bg-accent" anchor="top middle">
					<span>Schedule Select{{ ScheduleSelectTooltipText(binResult) }}</span>
				</q-tooltip>
			</q-btn>
		</q-td>
		<q-td>
			<div class="row" style="align-items: center">
				<div class="col-6" style="align-content: center">
					{{ binResult.MatchedServiceDescription }}
				</div>
				<div class="col" style="align-content: center">
					Quantity: {{ binResult.MatchedQuantity }}
				</div>
				<div class="col" style="align-content: center" v-if="!_isLoadingOnSite">
					On Site: {{ binResult.onSite || 'N/A' }}
				</div>
				<div class="col" style="align-content: center" v-else>
					On Site: <q-spinner-oval style="margin-bottom: 3px" />
				</div>
			</div>
		</q-td>
		<q-td />
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, watch, ref } from 'vue';
import {
	DataMatchingBinResultDTO,
	DatamatchingGroupedResultDTO,
	DataMatchingProblemEnum,
	OnSiteCountDto,
} from 'greenzonegateway.classes/lib/classes';
import GZIndicator from '@/components/GZIndicator.vue';
import { BaseService } from '@/services/BaseService';

const _service = new BaseService();

const _isLoadingOnSite = ref(false);

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number | null | undefined;
	expand: boolean;
}

const _props = defineProps<{
	settings: { slimGroups: boolean; slimItems: boolean };
	rowIndex: number;
	row: DatamatchingGroupedResultDTOInternal;
	binResult: DataMatchingBinResultDTO;
}>();
const _emit = defineEmits([
	'showModalScheduleMapping',
	'showModalBinMappings',
	'clearSelections',
]);

function GetBinRowColor(problemsLength: number, weightEnteredDate: string) {
	let colorOut = '';
	weightEnteredDate ? (colorOut = 'var(--q-positive)') : null;
	problemsLength > 0 && !weightEnteredDate
		? (colorOut = 'var(--q-negative)')
		: null;
	return colorOut;
}

function ScheduleSelectTooltipText(binResult: DataMatchingBinResultDTO) {
	let outputString = '';

	!binResult.onSite ? (outputString = ' - DISABLED: No schedules found') : '';
	binResult.WeightEntered?.Display
		? (outputString = ' - DISABLED: Bin saved')
		: '';

	return outputString;
}

function TranslateProblemEnum(enumNum: number) {
	if (!enumNum) {
		return;
	}
	let strOut = DataMatchingProblemEnum[enumNum].split(/(?=[A-Z])/).join(' ');
	return strOut.charAt(0) + strOut.substring(1).toLowerCase();
}

function ShowBinMappingsModal(
	rowData: DatamatchingGroupedResultDTOInternal,
	binResult: DataMatchingBinResultDTO,
	index: number
) {
	_emit('showModalBinMappings', { rowData, binResult, index });
}

function ShowModalScheduleMapping(
	rowData: DatamatchingGroupedResultDTO,
	bin: DataMatchingBinResultDTO
) {
	_emit('showModalScheduleMapping', { rowData, bin });
}

function LoadOnSiteData(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	_isLoadingOnSite.value = true;
	_service
		.Get<OnSiteCountDto[]>(
			`/DataMatchingWeights/OnSiteCount?reportImportId=${rowData.BinResults[0].ReportImportId}`
		)
		.then((response) => {
			if (!rowData.BinResults) {
				return;
			}
			for (let i = 0; i < response.length; i++) {
				rowData.BinResults.forEach((Bin) => {
					if (!Bin.ReportImportId) {
						return;
					}
					Bin.onSite = response.find(
						(responseItem) => responseItem.ReportImportId === Bin.ReportImportId
					).QuantityOnSite;
				});
			}
		})
		.finally(() => {
			_isLoadingOnSite.value = false;
		});
}

watch(
	() => _props.row.expand,
	() => {
		if (_props.row.expand) {
			LoadOnSiteData(_props.row);
		}
	},
	{ deep: true }
);
</script>

<style></style>
