<template>
	<GZDraggableModal ref="binMappingModal">
		<template #header>
			<q-toolbar class="bg-accent text-white">
				<q-toolbar-title class="text-h5" v-if="binMappingData"
					>{{ binMappingData.GroupedResult.SupplierName }} - Bin
					Mappings</q-toolbar-title
				>
			</q-toolbar>
		</template>
		<template #body>
			<q-card style="min-width: 1000px" v-if="binMappingData">
				<q-card-section>
					<div class="row">
						<div class="col-3">
							<div class="row"><b>Site</b></div>
							<div class="row">
								{{ binMappingData.GroupedResult.MatchedSiteName || 'N/A' }}
							</div>
						</div>
						<div class="col-5" v-if="!isBinSiteMode">
							<div class="row">
								<div class="col">
									<div class="row"><b>Waste Stream Group</b></div>
									<div class="row">
										{{
											binMappingData.BinResult.MatchedBinMapping
												?.WasteStreamGroup || 'N/A'
										}}
									</div>
								</div>
								<div class="col">
									<div class="row"><b>Waste Stream</b></div>
									<div class="row">
										{{
											binMappingData.BinResult.MatchedBinMapping?.WasteStream ||
											'N/A'
										}}
									</div>
								</div>
								<div class="col">
									<div class="row"><b>Bin</b></div>
									<div class="row">
										{{
											binMappingData.BinResult.MatchedBinMapping?.Bin || 'N/A'
										}}
									</div>
								</div>
							</div>
						</div>
						<div class="col-5" v-else>
							<div class="row">
								<div class="col">
									<div class="row"><b>Service Type</b></div>
									<div class="row">
										{{ binMappingData.MappedBinResult?.ServiceTypeName }}
									</div>
								</div>
								<div class="col">
									<div class="row"><b>Service Description</b></div>
									<div class="row">
										{{ binMappingData.MappedBinResult?.ServiceDescription }}
									</div>
								</div>
							</div>
						</div>
						<div class="col">
							<q-select
								v-model="_binSelected"
								:option-value="`ServiceDescription`"
								:option-label="`ServiceDescription`"
								:options="_options"
								:loading="_isLoading"
								@filter="filterFn"
								class="full-width"
								label="Services"
								use-input
								fill-input
								hide-selected
								dense
								outlined
							>
								<template #option="props">
									<q-item v-bind="props.itemProps" dense>
										<div class="col">
											<div class="row">
												<b>{{ props.opt.ServiceDescription }}</b>
											</div>
											<div class="row">
												{{ props.opt.ServiceTypeName }}
											</div>
										</div>
									</q-item>
								</template>
							</q-select>
							<q-toggle
								:disable="!binMappingData?.GroupedResult.MatchedSiteMap?.SiteId"
								@click="GetServicesList"
								:label="_isRelevantSearch ? 'Most Relevant' : 'All'"
								v-model="_isRelevantSearch"
							>
							</q-toggle>
							<div style="color: rgba(0, 0, 0, 0.6); text-align: right">
								{{
									!binMappingData?.GroupedResult.MatchedSiteMap?.SiteId
										? 'No site available. Relevant search disabled.'
										: null
								}}
							</div>
						</div>
					</div>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn @click="Close">Cancel</q-btn>
					<q-btn color="primary" :disable="!_binSelected" @click="PostBinMap"
						>Save</q-btn
					>
				</q-card-actions>
			</q-card>
		</template>
	</GZDraggableModal>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue';
import { BaseService } from '@/services/BaseService';
import {
	DatamatchingGroupedResultDTO,
	DataMatchingUpdateBinMapDto,
	ReadServiceDTOShallow,
} from 'greenzonegateway.classes/lib/classes';
import { GZDraggableModal } from '@gz/quasar-components-vue3';

//defines
defineExpose({ Open, Close });
const _emit = defineEmits(['close', 'binUpdated', 'submit']);
const _props = defineProps({
	binMappingData: Object,
	isBinSiteMode: { type: Boolean, default: false, require: false },
});
const _service = new BaseService();

//refs
const binMappingModal = ref<typeof GZDraggableModal>();

//flags
const _isLoading = ref(false);
const _isRelevantSearch = ref(true);

//vars
const _options = ref<ReadServiceDTOShallow[]>([]);
const _servicesList = ref<ReadServiceDTOShallow[]>([]);
const _binSelected = ref<ReadServiceDTOShallow>();

//#region Exposed Functions
function Close() {
	Reset();
	binMappingModal.value?.hide();
}

function Open() {
	binMappingModal.value?.show();
	GetServicesList();
}
//#endregion

function Reset() {
	_isRelevantSearch.value = true;
	_options.value = [];
	_binSelected.value = undefined;
}

function filterFn(val: any, update: any) {
	update(() => {
		const searchVal = val.toLowerCase();
		_options.value = _servicesList.value.filter((v) => {
			if (v && v.ServiceDescription) {
				return v.ServiceDescription.toLowerCase().indexOf(searchVal) > -1;
			}
		});
	});
}

//#region API Calls
function GetServicesList() {
	_isLoading.value = true;
	let url = 'DataMatchingWeights/Services';
	if (
		_isRelevantSearch.value &&
		_props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId
	) {
		url += `?siteId=${_props.binMappingData?.GroupedResult.MatchedSiteMap?.SiteId}`;
	}
	_service
		.Get<ReadServiceDTOShallow[]>(url)
		.then((services) => {
			_servicesList.value = services;
			_options.value = services;
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostBinMap() {
	if (_props.isBinSiteMode) {
		_emit('submit', _binSelected.value?.ServiceId);
		Close();
		return;
	}
	const _data: DataMatchingUpdateBinMapDto = {
		ServiceId: _binSelected.value?.ServiceId as number,
		BinMapId: _props.binMappingData?.BinResult?.MatchedBinMapping?.Id ?? null,
		ReportImportId: _props.binMappingData?.BinResult.ReportImportId,
	};
	_service
		.Post<DataMatchingUpdateBinMapDto>('DataMatchingWeights/SaveBinMap', _data)
		.then((site: DatamatchingGroupedResultDTO) => {
			_emit('binUpdated', {
				site: site,
				internalSiteRowId: _props.binMappingData?.InternalSiteRowId,
				reportImportId: _props.binMappingData?.ReportImportId,
			});
			Close();
		});
}
//#endregion
</script>

<style></style>
