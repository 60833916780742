import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home internal-container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = { class: "pa-4 layout-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6 q-pa-md" }
const _hoisted_5 = { class: "row q-pa-md" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "col-6 q-pa-md" }

import { ref, onMounted } from 'vue';
import { BaseService } from '@/services/BaseService';
import { supplierStore } from '@/store/suppliers';
import MappedTable from '@/components/MappedTable.vue';
import QGenericSelect from '@/components/QGenericSelect.vue';

// const { validate } = useFormChild();
interface SupplierNameMapping {
	Name: string;
	SupplierId: number;
	AccountNumber: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SupplierMappingsView',
  setup(__props) {

const _rules = ref([(val: any) => val != null || 'This field is required']);
const _SupplierStore = supplierStore();
const _service = new BaseService();
const _mappingName = ref([]);
const _isLoading = ref<boolean>(false);
const _selectedSupplierId: any = ref(undefined);
const _supplierNameMapping = ref<SupplierNameMapping>({
	Name: '',
	SupplierId: _selectedSupplierId ?? 0,
	AccountNumber: '',
});
const _selectedSupplier = ref(null);

function getMappings() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/Mapping')
		.then((mapping: any) => {
			_mappingName.value = mapping;
		});
}

async function validate(): Promise<void> {
	_supplierNameMapping.value.SupplierId = _selectedSupplier.value?.SupplierId;
	_isLoading.value = true;
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/Mapping/Create',
			_supplierNameMapping.value
		)
		.then((mapping: any) => {
			_mappingName.value = mapping;
			getMappings();
			_isLoading.value = false;
		});
}

function OnReset() {
	_supplierNameMapping.value.Name = '';
	_selectedSupplier.value = null;
}
onMounted(() => {
	getMappings();
});

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_layout, {
      class: "header-bar",
      view: "hHh Lpr fff"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar, { class: "bg-primary text-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_toolbar_title, { style: {"padding":"12px !important"} }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Supplier Name Mappings")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_page_container, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_card, { class: "p-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, { class: "bg-accent text-white" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("div", { class: "text-h6" }, "New Mapped Name", -1)
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_q_form, {
                            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (validate())),
                            onReset: _cache[3] || (_cache[3] = ($event: any) => (OnReset()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_input, {
                                outlined: "",
                                style: {"width":"100%"},
                                modelValue: _supplierNameMapping.value.Name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_supplierNameMapping.value.Name) = $event)),
                                label: "Mapping Name",
                                "lazy-rules": "",
                                rules: [
													(val) =>
														(val && val.length > 0) || 'Please type something',
												]
                              }, null, 8, ["modelValue", "rules"]),
                              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                              _createVNode(QGenericSelect, {
                                items: _unref(_SupplierStore).getSuppliers,
                                name: "SupplierName",
                                labeling: "Suppliers",
                                modelValue: _selectedSupplier.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_selectedSupplier).value = $event)),
                                rules: _rules.value
                              }, null, 8, ["items", "modelValue", "rules"]),
                              _createVNode(_component_q_space),
                              _createVNode(_component_q_separator),
                              _createVNode(_component_q_card_actions, { class: "q-pa-md" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_btn, {
                                    label: "Submit",
                                    type: "submit",
                                    color: "primary",
                                    loading: _isLoading.value
                                  }, null, 8, ["loading"]),
                                  _createVNode(_component_q_btn, {
                                    label: "Reset",
                                    type: "reset",
                                    color: "primary",
                                    flat: "",
                                    class: "q-ml-sm"
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(MappedTable, { items: _mappingName.value }, null, 8, ["items"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})