import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home internal-container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = {
  class: "pa-4 layout-container",
  style: {"height":"90%"},
  fluid: ""
}
const _hoisted_3 = { style: {"height":"100%"} }

import router from '@/router';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const _fab = ref();

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		router.push({ path: name, query: query });
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_fab_action = _resolveComponent("q-fab-action")!
  const _component_q_fab = _resolveComponent("q-fab")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pl-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, { style: {"padding-left":"16px"} }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_page_sticky, {
          position: "bottom-right",
          style: {"margin":"8px !important"},
          offset: [24, 24]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_fab, {
              color: "primary",
              icon: "mdi-dots-vertical",
              modelValue: _fab.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_fab).value = $event)),
              "vertical-actions-align": "left",
              direction: "up",
              "hide-icon": ""
            }, {
              label: _withCtx(({ opened }) => [
                opened
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      style: {"padding":"none !important"},
                      name: "mdi-close"
                    }))
                  : (_openBlock(), _createBlock(_component_q_icon, {
                      key: 1,
                      class: "q-pa-none",
                      name: "mdi-dots-vertical"
                    }))
              ]),
              default: _withCtx(() => [
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "primary",
                  icon: "mdi-cloud",
                  label: "Data Matching",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (Navigate(`datamatching`, null, null)))
                }),
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "primary",
                  icon: "mdi-cloud",
                  label: "Weight Data Matching",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (Navigate(`weight-datamatching-spreadsheets`, null, null)))
                }),
                _createVNode(_component_q_fab_action, {
                  "external-label": "",
                  "label-position": "left",
                  color: "primary",
                  icon: "mdi-map-outline",
                  label: "Bin/Site Mappings",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
								Navigate(`weight-datamatching-binsitemappings`, null, null)
							))
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})