<template>
	<div style="position: absolute; height: calc(100% - 64px); width: 100%">
		<q-toolbar class="bg-accent text-white q-pr-none">
			<q-toolbar-title
				>Weight Data Matching - Matched Spreadsheets</q-toolbar-title
			>
			<q-input
				type="text"
				outlined
				dense
				label="Filter"
				v-model="_search"
				dark
			></q-input>
			<q-btn
				round
				:flat="!FiltersEnabled()"
				:outline="FiltersEnabled()"
				size="large"
				class="q-ml-sm"
			>
				<q-icon :name="mdiFilterVariant" size="md"></q-icon>
				<q-tooltip location="top">
					<span>Filters</span>
				</q-tooltip>
				<q-menu>
					<div style="width: 230px">
						<q-toggle
							v-model="_isShowProcessing"
							label="Show Processing"
						></q-toggle>
						<q-toggle
							v-model="_isShowProcessed"
							label="Show Processed"
						></q-toggle>
						<q-toggle
							v-model="_isShowComplete"
							label="Show Completed"
						></q-toggle>
					</div>
				</q-menu>
			</q-btn>
			<q-btn @click="GetStats" round flat size="large">
				<q-icon :name="mdiInformationOutline" size="md" />
				<!--Small bug here with not opening due to the data not being loaded-->
				<q-menu>
					<div class="col q-pa-md" style="width: 366px">
						<div class="row"><h5>Overall Datamatching Statistics</h5></div>
						<div class="row"><h6>(for the past 3 months)</h6></div>
						<div class="row">
							Imported Lines:
							{{ _statsData?.TotalImportedLines || '...Loading' }}
						</div>
						<div class="row">
							Auto Saved Lines:
							{{ _statsData?.TotalLinesAutoSaved || '...Loading' }}
						</div>
						<div class="row">
							Overall Percentage:
							{{ _statsData?.Percentage || '...Loading' }}
						</div>
					</div>
				</q-menu>
			</q-btn>
		</q-toolbar>
		<div
			style="
				position: absolute;
				overflow-y: auto;
				height: calc(100% - 64px);
				width: calc(100% - 56px);
				left: 56px;
			"
		>
			<GZDragAndDrop></GZDragAndDrop>
			<q-table
				:columns="columns"
				:rows="_itemsComputed"
				:loading="_isLoading"
				style="height: calc(100% - 56px)"
				v-model:pagination="_pagination"
				hide-pagination
				row-key="Id"
				selection="single"
				binary-state-sort
				return-object
				flat
				:table-header-style="{
					backgroundColor: $q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
				}"
			>
				<template v-slot:body="props">
					<q-tr :props="props">
						<q-td>
							<GZIndicator :color="GetFileStatusColor(props.row.Status)">
								<q-tooltip>
									<span>{{ GetToolTipStatus(props.row.Status) }}</span>
								</q-tooltip>
							</GZIndicator>
						</q-td>
						<q-td
							v-for="col in props.cols"
							style="padding: 0px; padding-left: 16px"
							:key="col.name"
							:props="props"
							@click="OpenDataMatchingDetails(props.row)"
						>
							<div class="row full-height padding: 8px;">
								<div class="col-10" style="align-content: end">
									<q-tooltip>
										<span>{{ GetToolTipStatus(props.row.Status) }}</span>
									</q-tooltip>
									<p>{{ col.value }}</p>
								</div>
							</div>
							<q-menu context-menu touch-position>
								<q-list dense style="width: 200px">
									<q-item clickable v-close-popup style="padding: 16px">
										<q-item-section
											@click="ChangeSheetStatus(props.row, props.rowIndex)"
										>
											{{ GetMarkSheetOptionName(props.row.Status) }}
										</q-item-section>
									</q-item>
									<q-item clickable style="padding: 16px">
										<q-item-section> Asign to... </q-item-section>
										<q-item-section side
											><q-icon name="mdi-chevron-right"></q-icon
										></q-item-section>
										<q-menu anchor="top end" self="top start">
											<q-list dense style="width: 200px">
												<q-item
													v-for="user in _users"
													:key="user.UserId"
													@click="
														PostAssignUser(
															user.AzureId.toString(),
															props.row.Id
														)
													"
													:disable="_isLoading"
													clickable
													v-close-popup
													style="padding: 16px"
												>
													<q-item-section>{{ user.FullName }}</q-item-section>
												</q-item>
											</q-list>
										</q-menu>
									</q-item>
								</q-list>
							</q-menu>
						</q-td>
					</q-tr>
				</template>

				<template v-slot:no-data>
					<div class="full-width row flex-center q-gutter-sm">
						<q-icon size="2em" name="mdi-alert" />
						<span>No data available</span>
					</div>
				</template>
			</q-table>
			<GZPagination
				class="q-pt-sm q-px-sm"
				:items-length="_itemsComputed.length"
				v-model:pagination="_pagination"
			></GZPagination>
		</div>
		<q-dialog v-model="_isDialogVisible" style="width: 500px">
			<q-card height="150px">
				<q-card-section> </q-card-section>
				<q-card-actions align="right">
					<q-space />
					<q-btn color="primary" v-close-popup> Close </q-btn>
					<q-space />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { GZPagination } from '@gz/quasar-components-vue3';
import { DataMatchingFile, User } from 'greenzonegateway.classes/lib/classes';
import { mdiInformationOutline, mdiFilterVariant } from '@quasar/extras/mdi-v7';

import router from '@/router';
import GZIndicator from '@/components/GZIndicator.vue';
import GZDragAndDrop from '@/components/GZDragAndDrop.vue';

const _service = new BaseService();

const _isLoading = ref(false);
const _isDialogVisible = ref(false);
const _isShowProcessing = ref(true);
const _isShowProcessed = ref(true);
const _isShowComplete = ref(false);

const _search = ref('');
const _statsData = ref();
const _items = ref<DataMatchingFile[]>([]);
const _users = ref<User[]>();
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 20,
});
const columns = [
	{
		name: 'SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.SupplierName,
	},
	{
		name: 'FileName',
		label: 'File Name',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.FileName,
	},
	{
		name: 'AssignedTo',
		label: 'Assigned To',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.AssignedToFullName || 'Unassigned',
	},
	{
		name: 'ImportDate',
		label: 'Import Date',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.ImportDate.Display,
	},
	{
		name: 'UploadedBy',
		label: 'Uploaded By',
		align: 'left',
		sortable: true,
		field: (row: DataMatchingFile) => row.UploadedByFullName,
	},
];

const _itemsComputed = computed(() => {
	if (!_items.value.length) {
		return [];
	}

	let itemsToReturn: DataMatchingFile[] = [];

	if (_isShowProcessing.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 0)
		);
	}
	if (_isShowProcessed.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 1)
		);
	}
	if (_isShowComplete.value) {
		itemsToReturn = itemsToReturn.concat(
			_items.value.filter((item: DataMatchingFile) => item.Status === 2)
		);
	}

	if (_search.value) {
		const needle = _search.value.toLowerCase();
		itemsToReturn = itemsToReturn.filter((row: DataMatchingFile) => {
			return (
				row.SupplierName?.toLowerCase().includes(needle) ||
				row.FileName?.toLowerCase().includes(needle) ||
				row.AssignedToFullName?.toLowerCase().includes(needle) ||
				row.ImportDate?.Display.toLowerCase().includes(needle) ||
				row.UploadedByFullName?.toLowerCase().includes(needle)
			);
		});
	}

	return itemsToReturn;
});

enum DataMatchingFileStatusEnum {
	Processing = 0,

	Processed = 1,

	Completed = 2,
}

interface UpdateSheetStatusDto {
	FileId: number;
	Status: DataMatchingFileStatusEnum;
}

onMounted(() => {
	GetSpreadSheetFiles();
	GetUsers();
});

function FiltersEnabled() {
	if (
		_isShowProcessing.value ||
		_isShowProcessed.value ||
		_isShowComplete.value
	) {
		return true;
	}
	return false;
}

function GetFileStatusColor(number: number) {
	if (number === 2) {
		return 'var(--q-positive)';
	} else if (number === 1) {
		return 'var(--q-warning)';
	} else if (number === 0) {
		return 'var(--q-negative)';
	}
}

function GetToolTipStatus(enumNum: number) {
	return DataMatchingFileStatusEnum[enumNum];
}

function GetMarkSheetOptionName(option: DataMatchingFileStatusEnum) {
	switch (option) {
		case DataMatchingFileStatusEnum.Processed:
			return 'Complete';
		case DataMatchingFileStatusEnum.Completed:
			return 'Revert Complete';
		default:
			return 'Still Processing...';
	}
}

function OpenDataMatchingDetails(row: { Id: number }) {
	router.push({
		path: `/supplieraccounts/weightdatamatching/spreadsheets/details/${row.Id}`,
	});
}

function ChangeSheetStatus(row: DataMatchingFile, index: number) {
	switch (row.Status) {
		case DataMatchingFileStatusEnum.Processed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Completed);
			break;
		case DataMatchingFileStatusEnum.Completed as number:
			PostSheetStatus(row.Id, DataMatchingFileStatusEnum.Processed);
			break;
		default:
			return;
	}
}

function GetStats() {
	_service.Get<any>('/DataMatchingWeights/Statistics').then((dataIn: any) => {
		_statsData.value = dataIn;
		_statsData.value.Percentage += '%';
	});
}

function GetUsers() {
	if (_users.value && _users.value.length) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<User[]>('DataMatchingWeights/Users')
		.then((response) => {
			_users.value = response;
		})
		.catch((err) => {
			console.log(err);
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function GetSpreadSheetFiles() {
	_items.value = [];
	_service
		.Get<DataMatchingFile[]>('DataMatchingWeights/Processed')
		.then((response) => {
			_items.value = response;
		})
		.catch((err) => {
			console.log(err);
		});
}

function PostAssignUser(UserId: string, FileId: number) {
	let data: { UserId: string; FileId: number } = {
		UserId,
		FileId,
	};
	_service
		.Post('DataMatchingWeights/AssignUser', data)
		.then((response: DataMatchingFile[]) => {
			if (!_items.value) {
				return;
			}
			let fileIndex = _items.value?.findIndex((file) => file.Id === FileId);
			_items.value[fileIndex] = response[0];
			_items.value.push(); //Just for vue reactivity
		})
		.catch((err) => {
			console.log(err);
		});
}

function PostSheetStatus(id: number, status: DataMatchingFileStatusEnum) {
	let data: UpdateSheetStatusDto = {
		FileId: id,
		Status: status,
	};
	_service
		.Post<UpdateSheetStatusDto>('/DataMatchingWeights/UpdateSheetStatus', data)
		.then((response: DataMatchingFile[]) => {
			if (_items.value) {
				const index = _items.value.findIndex(
					(item: DataMatchingFile) => item.Id === response[0].Id
				);
				_items.value[index] = response[0];
				_items.value.push(); //Just for vue reactivity
			}
		});
}
</script>

<style lang="scss">
@import '../styles/base-styles.scss';
</style>
