<template>
	<div class="internal-container" style="width: 100%; height: 100%">
		<q-dialog v-model="_postInvoiceDialog" style="width: 500px">
			<q-card height="150px">
				<q-card-section>
					{{ _message }}
				</q-card-section>

				<q-card-actions align="right">
					<q-space />

					<q-btn color="primary" v-close-popup> Close </q-btn>
					<q-space />
				</q-card-actions>
			</q-card>
		</q-dialog>

		<q-toolbar class="bg-accent text-white q-pr-none">
			<q-toolbar-title>Data Matching</q-toolbar-title>

			<q-btn
				color="primary"
				@click="ApproveSelected(_selectedItems)"
				dense
				padding="sm"
				class="q-mx-md"
				:disable="!_selectedItems.length"
				:loading="_isLoading"
				>Approve Selected</q-btn
			>

			<q-separator dark vertical inset />

			<q-input
				class="q-px-md"
				outlined
				dark
				v-model="_search"
				debounce="300"
				placeholder="Search"
				dense
			>
				<template v-slot:append>
					<q-icon name="mdi-file-search-outline" />
				</template>
			</q-input>

			<q-btn flat round size="large" class="q-mr-sm">
				<q-icon name="mdi-filter-variant" />
				<q-menu :persistent="true">
					<div>
						<q-card style="width: 300px">
							<q-card-section class="bg-primary text-white text-h6">
								Filters
							</q-card-section>

							<q-separator />
							<div class="row">
								<div class="col-12 q-pa-sm">
									<GZGenericSelect
										:multiple="true"
										:items="_users"
										name="FullName"
										label="User"
										v-model="_selectedUsers"
										:clearable="true"
										subName="Position"
									></GZGenericSelect>
								</div>
							</div>
							<q-card-actions align="right">
								<q-btn flat label="Cancel" color="accent" v-close-popup />
								<q-btn outline color="positive" @click="FilterUsers()"
									>Filter</q-btn
								>
							</q-card-actions>
						</q-card>
					</div>
				</q-menu>
			</q-btn>

			<q-separator dark vertical inset />
			<q-btn
				flat
				round
				size="large"
				class="q-pr-sm"
				@click="TriggerApproveJob()"
			>
				<q-tooltip location="top">
					<span>Send Over Approved Matches</span>
				</q-tooltip>
				<q-icon name="mdi-database" />
			</q-btn>

			<q-btn
				flat
				round
				size="large"
				class="q-pr-sm"
				@click="_delaySendFiles"
				:disable="_disableButton"
			>
				<q-tooltip location="top">
					<span>Rerun AI</span>
				</q-tooltip>
				<q-icon name="mdi-file" />
			</q-btn>

			<q-btn flat round size="large" class="q-mr-sm">
				<q-badge color="green" rounded floating>{{ _fileCount }}</q-badge>
				<q-icon name="mdi-upload" />
				<q-tooltip location="top">
					<span>Upload Purchase Invoices</span>
				</q-tooltip>
				<q-menu v-model="_showMenu" @hide="ClearFiles()" :persistent="true">
					<div class="">
						<div class="col-12">
							<q-uploader
								multiple
								batch
								style="width: 500px"
								@added="addedFiles"
								@rejected="onRejected"
								accept="application/pdf"
								max-files="100"
							>
								<template v-slot:header="scope">
									<div class="row q-px-sm">
										<q-space />
										<q-btn dense flat icon="mdi-close" v-close-popup />
									</div>

									<div class="row no-wrap items-center q-px-sm q-gutter-xs">
										<q-btn
											v-if="scope.queuedFiles.length > 0"
											icon="mdi-notification-clear-all"
											@click="scope.removeQueuedFiles"
											round
											dense
											flat
										>
											<q-tooltip>
												<span>Clear All</span>
											</q-tooltip>
										</q-btn>
										<q-btn
											v-if="scope.uploadedFiles.length > 0"
											icon="done_all"
											@click="scope.removeUploadedFiles"
											round
											dense
											flat
										>
											<q-tooltip>
												<span>Remove Uploaded Files</span>
											</q-tooltip>
										</q-btn>
										<q-spinner
											v-if="scope.isUploading"
											class="q-uploader__spinner"
										/>
										<div class="col">
											<div class="q-uploader__title">Upload your files</div>
											<div class="q-uploader__subtitle">
												{{ scope.uploadSizeLabel }}
											</div>
											<div class="q-uploader__subtitle">
												{{ scope.files.length }} Files
											</div>
										</div>
										<q-btn
											v-if="scope.canAddFiles"
											type="a"
											icon="mdi-plus-circle"
											@click="scope.pickFiles"
											round
											dense
											flat
										>
											<q-uploader-add-trigger />
											<q-tooltip>
												<span>Pick Files</span>
											</q-tooltip>
										</q-btn>
										<q-btn
											v-if="scope.canUpload && _expanded !== true"
											icon="mdi-upload"
											@click="uploadAttachment()"
											round
											dense
											flat
										>
											<q-tooltip>
												<span>Upload Files</span>
											</q-tooltip>
										</q-btn>

										<q-btn
											v-if="scope.isUploading"
											icon="mdi-close-circle"
											@click="scope.abort"
											round
											dense
											flat
										>
											<q-tooltip>
												<span>Abort Upload</span>
											</q-tooltip>
										</q-btn>
									</div>
								</template>
								<template v-slot:list="scope">
									<q-list separator>
										<q-item v-for="file in scope.files" :key="file.__key">
											<q-item-section>
												<q-item-label class="full-width ellipsis">
													{{ file.name }}
												</q-item-label>

												<q-item-label caption>
													Status: {{ file.__status }}
												</q-item-label>

												<q-item-label caption>
													{{ file.__sizeLabel }} / {{ file.__progressLabel }}
												</q-item-label>
											</q-item-section>

											<q-item-section v-if="file.__img" thumbnail class="gt-xs">
												<img :src="file.__img.src" />
											</q-item-section>

											<q-item-section top side>
												<q-btn
													class="gt-xs"
													size="12px"
													flat
													dense
													round
													icon="mdi-delete"
													@click="scope.removeFile(file)"
												/>
											</q-item-section>
										</q-item>
									</q-list>
								</template>
							</q-uploader>
						</div>

						<q-expansion-item
							label="Additional Files"
							v-model="_expanded"
							expanded-icon="mdi-chevron-down"
							expand-icon="mdi-chevron-up"
						>
							<div class="col-12">
								<q-uploader
									multiple
									batch
									style="width: 500px"
									@added="additionalAddedFiles"
									@rejected="onRejected"
									accept="application/pdf"
									max-files="10"
								>
									<template v-slot:header="scope">
										<div class="row no-wrap items-center q-pa-sm q-gutter-xs">
											<q-btn
												v-if="scope.queuedFiles.length > 0"
												icon="mdi-notification-clear-all"
												@click="scope.removeQueuedFiles"
												round
												dense
												flat
											>
												<q-tooltip>
													<span>Clear All</span>
												</q-tooltip>
											</q-btn>
											<q-btn
												v-if="scope.uploadedFiles.length > 0"
												icon="done_all"
												@click="scope.removeUploadedFiles"
												round
												dense
												flat
											>
												<q-tooltip>
													<span>Remove Uploaded Files</span>
												</q-tooltip>
											</q-btn>
											<q-spinner
												v-if="scope.isUploading"
												class="q-uploader__spinner"
											/>
											<div class="col">
												<div class="q-uploader__title">Upload your files</div>
												<div class="q-uploader__subtitle">
													{{ scope.uploadSizeLabel }}
												</div>
												<div class="q-uploader__subtitle">
													{{ scope.files.length }} Files
												</div>
											</div>
											<q-btn
												v-if="scope.canAddFiles"
												type="a"
												icon="mdi-plus-circle"
												@click="scope.pickFiles"
												round
												dense
												flat
											>
												<q-uploader-add-trigger />
												<q-tooltip>
													<span>Pick Files</span>
												</q-tooltip>
											</q-btn>
											<q-btn
												v-if="scope.canUpload"
												icon="mdi-upload"
												@click="uploadAttachment()"
												round
												dense
												flat
											>
												<q-tooltip>
													<span>Upload Files</span>
												</q-tooltip>
											</q-btn>

											<q-btn
												v-if="scope.isUploading"
												icon="mdi-close-circle"
												@click="scope.abort"
												round
												dense
												flat
											>
												<q-tooltip>
													<span>Abort Upload</span>
												</q-tooltip>
											</q-btn>
										</div>
									</template>
									<template v-slot:list="scope">
										<q-list separator>
											<q-item v-for="file in scope.files" :key="file.__key">
												<q-item-section>
													<q-item-label class="full-width ellipsis">
														{{ file.name }}
													</q-item-label>

													<q-item-label caption>
														Status: {{ file.__status }}
													</q-item-label>

													<q-item-label caption>
														{{ file.__sizeLabel }} / {{ file.__progressLabel }}
													</q-item-label>
												</q-item-section>

												<q-item-section
													v-if="file.__img"
													thumbnail
													class="gt-xs"
												>
													<img :src="file.__img.src" />
												</q-item-section>

												<q-item-section top side>
													<q-btn
														class="gt-xs"
														size="12px"
														flat
														dense
														round
														icon="mdi-delete"
														@click="scope.removeFile(file)"
													/>
												</q-item-section>
											</q-item>
										</q-list>
									</template>
								</q-uploader>
							</div>
						</q-expansion-item>

						<q-inner-loading
							label="Please wait..."
							label-style="font-size: 1.1em"
						/>
					</div>
				</q-menu>
			</q-btn>
		</q-toolbar>

		<div class="pa-4 layout-container q-pa-md">
			<SupplierDataTable
				:isLoading="_isInvoicesLoading"
				:headers="headers"
				:items="_items"
				:search="_search"
				:selectedItems="_selectedItems"
				@updateTable="RefreshTable"
				@updateBulk="BulkRefreshTable"
				@update:selectedItems="_selectedItems = $event"
				@update:approve="ApproveSelected(_selectedItems)"
				@update:archive="ArchiveSelected(_selectedItems)"
			>
			</SupplierDataTable>
			<q-btn
				color="primary"
				style="position: absolute; right: 10px; bottom: 70px"
				:icon="_nav ? 'mdi-close' : 'mdi-account-circle'"
				round
				size="large"
			>
				<q-menu
					transition-show="scale"
					transition-hide="scale"
					fit
					:offset="[-5, 0]"
					auto-close
					style="
						box-shadow: none;
						background-color: transparent;
						overflow-y: hidden;
						overflow-x: hidden;
					"
				>
					<q-list>
						<q-btn
							round
							color="secondary"
							icon="mdi-clipboard-file"
							to="/supplieraccounts/mappings"
						>
							<q-tooltip anchor="center right" self="center left">
								Supplier Mapping
							</q-tooltip>
						</q-btn>
					</q-list>
					<br />
					<q-list>
						<q-btn
							round
							color="secondary"
							icon="mdi-layers"
							to="/supplieraccounts/supplierdefaults"
						>
							<q-tooltip anchor="center right" self="center left">
								Supplier defaults
							</q-tooltip>
						</q-btn>
					</q-list>
					<br />
				</q-menu>
			</q-btn>
		</div>
		<q-dialog v-model="_dialog" style="width: 500px">
			<q-card height="150px">
				<q-card-section>
					{{ _message }}
				</q-card-section>

				<q-card-actions align="right">
					<q-space />

					<q-btn color="primary" v-close-popup> Close </q-btn>
					<q-space />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script lang="ts" setup>
import SupplierDataTable from '../components/SupplierDataTable.vue';
import { BaseService } from '@/services/BaseService';
import { debounce } from 'quasar';
import { onMounted, ref, computed, onUnmounted } from 'vue';
import { useQuasar } from 'quasar';
import moment from 'moment';
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import { User } from 'greenzonegateway.classes/lib/classes';
import { userStore } from '@/store/users';

interface SupplierPurchaseInvoice {
	Files: [];
	AdditionalFiles: [];
	AzureId: string;
}
const $q = useQuasar();
const _service = new BaseService();
const _items = ref<any[]>([]);
const _supplierFiles = ref<SupplierPurchaseInvoice>({
	Files: [],
	AdditionalFiles: [],
	AzureId: '',
});
const _nav = ref(false);
const _isLoading = ref(false);
const _isAttachmentLoading = ref(false);
const _postInvoiceDialog = ref(false);
const _supplierInvoiceFiles = ref<File[]>([]);
const _additionalFiles = ref<File[]>([]);
const _selectedItems = ref<any[]>([]);
const _isInvoicesLoading = ref(false);
const _showMenu = ref(false);
const _expanded = ref(false);
const _message = ref('');
const _search = ref('');
const _dialog = ref(false);
const _users = ref<User[]>([]);
const _selectedUsers = ref<User[]>([]);
const _window = ref();
const _fileCount = computed(() => {
	return _supplierInvoiceFiles.value.length;
});
const _disableButton = ref(false);
//get Headers
const headers = [
	{
		name: 'ProcessedSupplierInvoice.SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice?.SupplierName,
	},
	{
		name: 'ProcessedSupplierInvoice.SageReferences.SageReference',
		label: 'Sage Reference',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.SageReference?.Reference,
	},
	{
		name: 'ProcessedSupplierInvoice.InvoiceNo',
		label: 'Invoice No',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.InvoiceNo,
	},
	{
		name: 'ProcessedSupplierInvoice.InvoiceDate',
		label: 'Date',
		align: 'left',
		sortable: true,
		field: (row: any) => FormatDate(row.ProcessedSupplierInvoice.InvoiceDate),
	},
	{
		name: 'ProcessedSupplierInvoice.Customer.CustomerName',
		label: 'Customer Name',
		sortable: true,
		align: 'left',
		field: (row: any) => row.ProcessedSupplierInvoice.Customer.CustomerName,
	},
	{
		name: 'ProcessedSupplierInvoice.Net',
		label: 'Net',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.Net,
	},
	{
		name: 'ProcessedSupplierInvoice.VAT',
		label: 'VAT',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.VAT,
	},
	{
		name: 'ProcessedSupplierInvoice.Total',
		label: 'Total',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.Total,
	},
	{
		name: 'ProcessedSupplierInvoice.Approved',
		label: 'Approved',
		align: 'center',
		field: (row: any) => row.ProcessedSupplierInvoice.Approved,
	},
	{
		name: 'view',
		label: 'Additional Files',
		align: 'center',
		field: (row: any) =>
			row.ProcessedSupplierInvoice.SupplierInvoiceFile
				.SupplierSupplementaryInvoiceFiles.length,
	},
	{
		name: 'view',
		label: 'Uploaded By',
		align: 'center',
		field: (row: any) =>
			row.ProcessedSupplierInvoice.SupplierInvoiceFile.User?.FullName,
	},
	{
		name: 'view',
		label: 'Actions',
		align: 'center',
		field: (row: any) => row.view,
	},
];
onMounted(() => {
	GetPurchaseInvoices();
	PreventRefresh();
	GetUsers();
	window.addEventListener('beforeunload', handleBeforeUnload);
});

onUnmounted(() => {
	window.removeEventListener('beforeunload', handleBeforeUnload);
});

function ClearFiles() {
	_additionalFiles.value = [];
	_supplierInvoiceFiles.value = [];
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
	if (_isLoading.value) {
		event.preventDefault();
		event.returnValue = '';
	}
};
function onRejected(rejectedEntries: any) {
	$q.notify({
		type: 'negative',
		icon: 'mdi-alert',
		message: `${rejectedEntries.length} Invalid file(s)`,
	});
}
function ShowLoading() {
	$q.loading.show({
		message: 'Uploading Files, Please wait',
		boxClass: 'bg-grey-2 text-grey-9',
		spinnerColor: 'primary',
	});
}

function HideLoading() {
	$q.loading.hide();
}
function PreventRefresh() {
	_window.value = window;
	let beforeUnloadHandler = (event: any) => {
		if (_isAttachmentLoading.value) {
			event.returnValue = true;
		}
	};
	_window.value.addEventListener('beforeunload', beforeUnloadHandler, true);
}

function BulkRefreshTable(updatedItems: any[]) {
	if (!updatedItems.length) {
		return;
	}
	let overrideItems = _items.value;
	updatedItems.forEach((updatedItem: any) => {
		let index = overrideItems.findIndex(
			(xx) => xx.ProcessedSupplierInvoice.Id === updatedItem.Id
		);
		overrideItems[index].ProcessedSupplierInvoice = updatedItem;

		overrideItems = overrideItems.filter(
			(xx) => xx.ProcessedSupplierInvoice.Approved !== true
		);
		_items.value = overrideItems;
	});
}

function RefreshTable(updatedItem: any) {
	let index = _items.value.findIndex(
		(xx) => xx.ProcessedSupplierInvoice.Id === updatedItem.Id
	);
	_items.value[index].ProcessedSupplierInvoice = updatedItem;

	_items.value = _items.value.filter(
		(xx) => xx.ProcessedSupplierInvoice.Approved !== true
	);
}

function GetPurchaseInvoices() {
	_isInvoicesLoading.value = true;
	_items.value = [];
	let users = [];
	users.push(userStore().GetUser.localAccountId);
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Processed',
			users
		)
		.then((response: any) => {
			for (let i = 0; i < response.length; i++) {
				_items.value.push(response[i]);
			}
			_isInvoicesLoading.value = false;
		})
		.catch((err) => {
			console.log(err);
		});
}

function GetUsers() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/List/Users')
		.then((response: any) => {
			_users.value = response;
			_selectedUsers.value = _users.value.filter(
				(x) =>
					x.AzureId.toLowerCase() ===
					userStore().GetUser.localAccountId.toLowerCase()
			);
		});
}

function addedFiles(files: any) {
	for (let i = 0; i < files.length; i++) {
		_supplierInvoiceFiles.value.push(files[i]);
	}
	return files;
}
function additionalAddedFiles(files: any) {
	for (let i = 0; i < files.length; i++) {
		_additionalFiles.value.push(files[i]);
	}
	return files;
}

function CheckForMissingDetails(itemsIn: any): boolean {
	let hasMissingDetails = itemsIn.some(
		(xx: any) =>
			!xx.ProcessedSupplierInvoice.Customer?.CustomerId ||
			!xx.ProcessedSupplierInvoice.Supplier?.SupplierId ||
			!xx.ProcessedSupplierInvoice?.InvoiceNo ||
			!xx.ProcessedSupplierInvoice?.Net ||
			!xx.ProcessedSupplierInvoice?.VAT ||
			!xx.ProcessedSupplierInvoice?.Total ||
			!xx.ProcessedSupplierInvoice?.InvoiceDate
	);

	if (hasMissingDetails) {
		_message.value =
			'You have one or more Invoices that are missing required fields';
		_dialog.value = true;
		_isLoading.value = false;
		return true;
	}
	return false;
}

function DelaySendFiles() {
	_disableButton.value = true;
	setTimeout(() => {
		SendFiles();
		_disableButton.value = false;
	}, 5000);
}

const _delaySendFiles = debounce(DelaySendFiles, 500);

async function ArchiveSelected(items: any): Promise<void> {
	_isLoading.value = true;
	let hasMissingDetails = false;

	if (!hasMissingDetails) {
		let ids = items.map((match: any) => {
			return match.ProcessedSupplierInvoiceId;
		});
		_service
			.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
				processedInvoiceIds: ids,
				operations: [
					{
						op: 'replace',
						path: '/Archived',
						value: true,
					},
				],
			})
			.then(() => {
				_message.value = 'Selected Purchase Invoices have been Archived';
				_dialog.value = true;
				TriggerApproveJob();

				_items.value = _items.value.filter(
					(xx) => !ids.includes(xx.ProcessedSupplierInvoiceId)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				_isLoading.value = false;
				_selectedItems.value = [];
				HideLoading();
			});
	}
}

async function ApproveSelected(items: any): Promise<void> {
	_isLoading.value = true;

	let hasMissingDetails = CheckForMissingDetails(items);

	if (!hasMissingDetails) {
		let ids = items.map((match: any) => {
			return match.ProcessedSupplierInvoiceId;
		});
		_service
			.Patch<any>('Suppliers/ProcessedPurchaseInvoices/ToApprove', {
				processedSupplierIds: ids,
				operations: [
					{
						op: 'replace',
						path: '/Approved',
						value: true,
					},
				],
			})
			.then(() => {
				_message.value = 'Selected Purchase Invoices have been Approved';
				_dialog.value = true;
				TriggerApproveJob();

				_items.value = _items.value.filter(
					(xx) => !ids.includes(xx.ProcessedSupplierInvoiceId)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				_isLoading.value = false;
				_selectedItems.value = [];
			});
	}
}
function FileToByteArray(file: any) {
	return new Promise((resolve, reject) => {
		try {
			const reader = new FileReader();
			const fileByteArray: any[] = [];

			reader.readAsArrayBuffer(file);

			reader.onloadend = (evt) => {
				if (evt.target?.readyState === FileReader.DONE) {
					const arrayBuffer = evt.target.result as any,
						array = new Uint8Array(arrayBuffer);

					let byte;

					for (byte of array) {
						fileByteArray.push(byte);
					}
				}

				resolve(fileByteArray);
			};
		} catch (e) {
			reject(e);
		}
	});
}

function SendFiles() {
	_service.Get<any>('Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Send');
}

function TriggerApproveJob() {
	_service.Put<any>(
		'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Approve',
		{}
	);
}

async function uploadAttachment(): Promise<void> {
	let attachments: any = [];
	let addAttachments: any = [];
	if (_supplierInvoiceFiles.value.length) {
		_isLoading.value = true;
		_isAttachmentLoading.value = true;
		_showMenu.value = false;
		ShowLoading();

		for (const files of _supplierInvoiceFiles.value) {
			let file: any = await FileToByteArray(files);

			attachments.push({
				FileName: files.name,
				File: file,
			});

			_supplierFiles.value.Files = attachments;
			_supplierFiles.value.AzureId = userStore().GetUser.localAccountId;

			if (_expanded.value) {
				for (const files of _additionalFiles.value) {
					let file: any = await FileToByteArray(files);

					addAttachments.push({
						FileName: files.name,
						File: file,
					});

					_supplierFiles.value.AdditionalFiles = addAttachments;
				}
			}
		}

		_service
			.Post<any>('/Files/DataMatching/Upload', _supplierFiles.value)
			.then(() => {
				attachments = [];

				SendFiles();
			})
			.catch(() => {
				_postInvoiceDialog.value = true;
				_message.value = 'Failed to upload Blobs';
			})
			.finally(() => {
				_supplierInvoiceFiles.value = [];
				_message.value =
					'Invoices have been sent for processing, you should recieve an email when completed';
				_postInvoiceDialog.value = true;
				_isLoading.value = false;
				_isAttachmentLoading.value = false;
				HideLoading();
			});
	}
}

async function FilterUsers() {
	let users = [];
	if (_selectedUsers.value) {
		for (let i = 0; i < _selectedUsers.value.length; i++) {
			users.push(_selectedUsers.value[i].AzureId);
		}
	}
	_items.value = [];
	await _service

		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Processed',
			users
		)
		.then((response: any) => {
			for (let i = 0; i < response.length; i++) {
				_items.value.push(response[i]);
			}
			_isInvoicesLoading.value = false;
		})
		.catch((err) => {
			console.log(err);
		});
}

function FormatDate(date: string) {
	return moment(date).format('DD/MM/YYYY');
}
</script>

<style lang="scss">
@import '../styles/base-styles.scss';
</style>
