import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "internal-container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 q-pa-sm" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "row q-px-sm" }
const _hoisted_7 = { class: "row no-wrap items-center q-px-sm q-gutter-xs" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "q-uploader__subtitle" }
const _hoisted_10 = { class: "q-uploader__subtitle" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "row no-wrap items-center q-pa-sm q-gutter-xs" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "q-uploader__subtitle" }
const _hoisted_16 = { class: "q-uploader__subtitle" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "pa-4 layout-container q-pa-md" }

import SupplierDataTable from '../components/SupplierDataTable.vue';
import { BaseService } from '@/services/BaseService';
import debounce from 'quasar/src/utils/debounce/debounce.js';;
import { onMounted, ref, computed, onUnmounted } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import moment from 'moment';
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import { User } from 'greenzonegateway.classes/lib/classes';
import { userStore } from '@/store/users';

interface SupplierPurchaseInvoice {
	Files: [];
	AdditionalFiles: [];
	AzureId: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SupplierAccounts',
  setup(__props) {

const $q = useQuasar();
const _service = new BaseService();
const _items = ref<any[]>([]);
const _supplierFiles = ref<SupplierPurchaseInvoice>({
	Files: [],
	AdditionalFiles: [],
	AzureId: '',
});
const _nav = ref(false);
const _isLoading = ref(false);
const _isAttachmentLoading = ref(false);
const _postInvoiceDialog = ref(false);
const _supplierInvoiceFiles = ref<File[]>([]);
const _additionalFiles = ref<File[]>([]);
const _selectedItems = ref<any[]>([]);
const _isInvoicesLoading = ref(false);
const _showMenu = ref(false);
const _expanded = ref(false);
const _message = ref('');
const _search = ref('');
const _dialog = ref(false);
const _users = ref<User[]>([]);
const _selectedUsers = ref<User[]>([]);
const _window = ref();
const _fileCount = computed(() => {
	return _supplierInvoiceFiles.value.length;
});
const _disableButton = ref(false);
//get Headers
const headers = [
	{
		name: 'ProcessedSupplierInvoice.SupplierName',
		label: 'Supplier Name',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice?.SupplierName,
	},
	{
		name: 'ProcessedSupplierInvoice.SageReferences.SageReference',
		label: 'Sage Reference',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.SageReference?.Reference,
	},
	{
		name: 'ProcessedSupplierInvoice.InvoiceNo',
		label: 'Invoice No',
		align: 'left',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.InvoiceNo,
	},
	{
		name: 'ProcessedSupplierInvoice.InvoiceDate',
		label: 'Date',
		align: 'left',
		sortable: true,
		field: (row: any) => FormatDate(row.ProcessedSupplierInvoice.InvoiceDate),
	},
	{
		name: 'ProcessedSupplierInvoice.Customer.CustomerName',
		label: 'Customer Name',
		sortable: true,
		align: 'left',
		field: (row: any) => row.ProcessedSupplierInvoice.Customer.CustomerName,
	},
	{
		name: 'ProcessedSupplierInvoice.Net',
		label: 'Net',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.Net,
	},
	{
		name: 'ProcessedSupplierInvoice.VAT',
		label: 'VAT',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.VAT,
	},
	{
		name: 'ProcessedSupplierInvoice.Total',
		label: 'Total',
		align: 'center',
		sortable: true,
		field: (row: any) => row.ProcessedSupplierInvoice.Total,
	},
	{
		name: 'ProcessedSupplierInvoice.Approved',
		label: 'Approved',
		align: 'center',
		field: (row: any) => row.ProcessedSupplierInvoice.Approved,
	},
	{
		name: 'view',
		label: 'Additional Files',
		align: 'center',
		field: (row: any) =>
			row.ProcessedSupplierInvoice.SupplierInvoiceFile
				.SupplierSupplementaryInvoiceFiles.length,
	},
	{
		name: 'view',
		label: 'Uploaded By',
		align: 'center',
		field: (row: any) =>
			row.ProcessedSupplierInvoice.SupplierInvoiceFile.User?.FullName,
	},
	{
		name: 'view',
		label: 'Actions',
		align: 'center',
		field: (row: any) => row.view,
	},
];
onMounted(() => {
	GetPurchaseInvoices();
	PreventRefresh();
	GetUsers();
	window.addEventListener('beforeunload', handleBeforeUnload);
});

onUnmounted(() => {
	window.removeEventListener('beforeunload', handleBeforeUnload);
});

function ClearFiles() {
	_additionalFiles.value = [];
	_supplierInvoiceFiles.value = [];
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
	if (_isLoading.value) {
		event.preventDefault();
		event.returnValue = '';
	}
};
function onRejected(rejectedEntries: any) {
	$q.notify({
		type: 'negative',
		icon: 'mdi-alert',
		message: `${rejectedEntries.length} Invalid file(s)`,
	});
}
function ShowLoading() {
	$q.loading.show({
		message: 'Uploading Files, Please wait',
		boxClass: 'bg-grey-2 text-grey-9',
		spinnerColor: 'primary',
	});
}

function HideLoading() {
	$q.loading.hide();
}
function PreventRefresh() {
	_window.value = window;
	let beforeUnloadHandler = (event: any) => {
		if (_isAttachmentLoading.value) {
			event.returnValue = true;
		}
	};
	_window.value.addEventListener('beforeunload', beforeUnloadHandler, true);
}

function BulkRefreshTable(updatedItems: any[]) {
	if (!updatedItems.length) {
		return;
	}
	let overrideItems = _items.value;
	updatedItems.forEach((updatedItem: any) => {
		let index = overrideItems.findIndex(
			(xx) => xx.ProcessedSupplierInvoice.Id === updatedItem.Id
		);
		overrideItems[index].ProcessedSupplierInvoice = updatedItem;

		overrideItems = overrideItems.filter(
			(xx) => xx.ProcessedSupplierInvoice.Approved !== true
		);
		_items.value = overrideItems;
	});
}

function RefreshTable(updatedItem: any) {
	let index = _items.value.findIndex(
		(xx) => xx.ProcessedSupplierInvoice.Id === updatedItem.Id
	);
	_items.value[index].ProcessedSupplierInvoice = updatedItem;

	_items.value = _items.value.filter(
		(xx) => xx.ProcessedSupplierInvoice.Approved !== true
	);
}

function GetPurchaseInvoices() {
	_isInvoicesLoading.value = true;
	_items.value = [];
	let users = [];
	users.push(userStore().GetUser.localAccountId);
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Processed',
			users
		)
		.then((response: any) => {
			for (let i = 0; i < response.length; i++) {
				_items.value.push(response[i]);
			}
			_isInvoicesLoading.value = false;
		})
		.catch((err) => {
			console.log(err);
		});
}

function GetUsers() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/List/Users')
		.then((response: any) => {
			_users.value = response;
			_selectedUsers.value = _users.value.filter(
				(x) =>
					x.AzureId.toLowerCase() ===
					userStore().GetUser.localAccountId.toLowerCase()
			);
		});
}

function addedFiles(files: any) {
	for (let i = 0; i < files.length; i++) {
		_supplierInvoiceFiles.value.push(files[i]);
	}
	return files;
}
function additionalAddedFiles(files: any) {
	for (let i = 0; i < files.length; i++) {
		_additionalFiles.value.push(files[i]);
	}
	return files;
}

function CheckForMissingDetails(itemsIn: any): boolean {
	let hasMissingDetails = itemsIn.some(
		(xx: any) =>
			!xx.ProcessedSupplierInvoice.Customer?.CustomerId ||
			!xx.ProcessedSupplierInvoice.Supplier?.SupplierId ||
			!xx.ProcessedSupplierInvoice?.InvoiceNo ||
			!xx.ProcessedSupplierInvoice?.Net ||
			!xx.ProcessedSupplierInvoice?.VAT ||
			!xx.ProcessedSupplierInvoice?.Total ||
			!xx.ProcessedSupplierInvoice?.InvoiceDate
	);

	if (hasMissingDetails) {
		_message.value =
			'You have one or more Invoices that are missing required fields';
		_dialog.value = true;
		_isLoading.value = false;
		return true;
	}
	return false;
}

function DelaySendFiles() {
	_disableButton.value = true;
	setTimeout(() => {
		SendFiles();
		_disableButton.value = false;
	}, 5000);
}

const _delaySendFiles = debounce(DelaySendFiles, 500);

async function ArchiveSelected(items: any): Promise<void> {
	_isLoading.value = true;
	let hasMissingDetails = false;

	if (!hasMissingDetails) {
		let ids = items.map((match: any) => {
			return match.ProcessedSupplierInvoiceId;
		});
		_service
			.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
				processedInvoiceIds: ids,
				operations: [
					{
						op: 'replace',
						path: '/Archived',
						value: true,
					},
				],
			})
			.then(() => {
				_message.value = 'Selected Purchase Invoices have been Archived';
				_dialog.value = true;
				TriggerApproveJob();

				_items.value = _items.value.filter(
					(xx) => !ids.includes(xx.ProcessedSupplierInvoiceId)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				_isLoading.value = false;
				_selectedItems.value = [];
				HideLoading();
			});
	}
}

async function ApproveSelected(items: any): Promise<void> {
	_isLoading.value = true;

	let hasMissingDetails = CheckForMissingDetails(items);

	if (!hasMissingDetails) {
		let ids = items.map((match: any) => {
			return match.ProcessedSupplierInvoiceId;
		});
		_service
			.Patch<any>('Suppliers/ProcessedPurchaseInvoices/ToApprove', {
				processedSupplierIds: ids,
				operations: [
					{
						op: 'replace',
						path: '/Approved',
						value: true,
					},
				],
			})
			.then(() => {
				_message.value = 'Selected Purchase Invoices have been Approved';
				_dialog.value = true;
				TriggerApproveJob();

				_items.value = _items.value.filter(
					(xx) => !ids.includes(xx.ProcessedSupplierInvoiceId)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				_isLoading.value = false;
				_selectedItems.value = [];
			});
	}
}
function FileToByteArray(file: any) {
	return new Promise((resolve, reject) => {
		try {
			const reader = new FileReader();
			const fileByteArray: any[] = [];

			reader.readAsArrayBuffer(file);

			reader.onloadend = (evt) => {
				if (evt.target?.readyState === FileReader.DONE) {
					const arrayBuffer = evt.target.result as any,
						array = new Uint8Array(arrayBuffer);

					let byte;

					for (byte of array) {
						fileByteArray.push(byte);
					}
				}

				resolve(fileByteArray);
			};
		} catch (e) {
			reject(e);
		}
	});
}

function SendFiles() {
	_service.Get<any>('Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Send');
}

function TriggerApproveJob() {
	_service.Put<any>(
		'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Approve',
		{}
	);
}

async function uploadAttachment(): Promise<void> {
	let attachments: any = [];
	let addAttachments: any = [];
	if (_supplierInvoiceFiles.value.length) {
		_isLoading.value = true;
		_isAttachmentLoading.value = true;
		_showMenu.value = false;
		ShowLoading();

		for (const files of _supplierInvoiceFiles.value) {
			let file: any = await FileToByteArray(files);

			attachments.push({
				FileName: files.name,
				File: file,
			});

			_supplierFiles.value.Files = attachments;
			_supplierFiles.value.AzureId = userStore().GetUser.localAccountId;

			if (_expanded.value) {
				for (const files of _additionalFiles.value) {
					let file: any = await FileToByteArray(files);

					addAttachments.push({
						FileName: files.name,
						File: file,
					});

					_supplierFiles.value.AdditionalFiles = addAttachments;
				}
			}
		}

		_service
			.Post<any>('/Files/DataMatching/Upload', _supplierFiles.value)
			.then(() => {
				attachments = [];

				SendFiles();
			})
			.catch(() => {
				_postInvoiceDialog.value = true;
				_message.value = 'Failed to upload Blobs';
			})
			.finally(() => {
				_supplierInvoiceFiles.value = [];
				_message.value =
					'Invoices have been sent for processing, you should recieve an email when completed';
				_postInvoiceDialog.value = true;
				_isLoading.value = false;
				_isAttachmentLoading.value = false;
				HideLoading();
			});
	}
}

async function FilterUsers() {
	let users = [];
	if (_selectedUsers.value) {
		for (let i = 0; i < _selectedUsers.value.length; i++) {
			users.push(_selectedUsers.value[i].AzureId);
		}
	}
	_items.value = [];
	await _service

		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/AI/DataMatching/Processed',
			users
		)
		.then((response: any) => {
			for (let i = 0; i < response.length; i++) {
				_items.value.push(response[i]);
			}
			_isInvoicesLoading.value = false;
		})
		.catch((err) => {
			console.log(err);
		});
}

function FormatDate(date: string) {
	return moment(date).format('DD/MM/YYYY');
}

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_uploader_add_trigger = _resolveComponent("q-uploader-add-trigger")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_uploader = _resolveComponent("q-uploader")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_dialog, {
      modelValue: _postInvoiceDialog.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_postInvoiceDialog).value = $event)),
      style: {"width":"500px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { height: "150px" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_message.value), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_space),
                _withDirectives((_openBlock(), _createBlock(_component_q_btn, { color: "primary" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })), [
                  [_directive_close_popup]
                ]),
                _createVNode(_component_q_space)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pr-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, null, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Data Matching")
          ])),
          _: 1
        }),
        _createVNode(_component_q_btn, {
          color: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (ApproveSelected(_selectedItems.value))),
          dense: "",
          padding: "sm",
          class: "q-mx-md",
          disable: !_selectedItems.value.length,
          loading: _isLoading.value
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createTextVNode("Approve Selected")
          ])),
          _: 1
        }, 8, ["disable", "loading"]),
        _createVNode(_component_q_separator, {
          dark: "",
          vertical: "",
          inset: ""
        }),
        _createVNode(_component_q_input, {
          class: "q-px-md",
          outlined: "",
          dark: "",
          modelValue: _search.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_search).value = $event)),
          debounce: "300",
          placeholder: "Search",
          dense: ""
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "mdi-file-search-outline" })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          size: "large",
          class: "q-mr-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "mdi-filter-variant" }),
            _createVNode(_component_q_menu, { persistent: true }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_card, { style: {"width":"300px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, { class: "bg-primary text-white text-h6" }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode(" Filters ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_separator),
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_unref(GZGenericSelect), {
                            multiple: true,
                            items: _users.value,
                            name: "FullName",
                            label: "User",
                            modelValue: _selectedUsers.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_selectedUsers).value = $event)),
                            clearable: true,
                            subName: "Position"
                          }, null, 8, ["items", "modelValue"])
                        ])
                      ]),
                      _createVNode(_component_q_card_actions, { align: "right" }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_q_btn, {
                            flat: "",
                            label: "Cancel",
                            color: "accent"
                          }, null, 512), [
                            [_directive_close_popup]
                          ]),
                          _createVNode(_component_q_btn, {
                            outline: "",
                            color: "positive",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (FilterUsers()))
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode("Filter")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator, {
          dark: "",
          vertical: "",
          inset: ""
        }),
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          size: "large",
          class: "q-pr-sm",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (TriggerApproveJob()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createElementVNode("span", null, "Send Over Approved Matches", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_icon, { name: "mdi-database" })
          ]),
          _: 1
        }),
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          size: "large",
          class: "q-pr-sm",
          onClick: _unref(_delaySendFiles),
          disable: _disableButton.value
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createElementVNode("span", null, "Rerun AI", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_icon, { name: "mdi-file" })
          ]),
          _: 1
        }, 8, ["onClick", "disable"]),
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          size: "large",
          class: "q-mr-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_badge, {
              color: "green",
              rounded: "",
              floating: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_fileCount.value), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_icon, { name: "mdi-upload" }),
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createElementVNode("span", null, "Upload Purchase Invoices", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_menu, {
              modelValue: _showMenu.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_showMenu).value = $event)),
              onHide: _cache[10] || (_cache[10] = ($event: any) => (ClearFiles())),
              persistent: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_uploader, {
                      multiple: "",
                      batch: "",
                      style: {"width":"500px"},
                      onAdded: addedFiles,
                      onRejected: onRejected,
                      accept: "application/pdf",
                      "max-files": "100"
                    }, {
                      header: _withCtx((scope) => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_q_space),
                          _withDirectives(_createVNode(_component_q_btn, {
                            dense: "",
                            flat: "",
                            icon: "mdi-close"
                          }, null, 512), [
                            [_directive_close_popup]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          (scope.queuedFiles.length > 0)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 0,
                                icon: "mdi-notification-clear-all",
                                onClick: scope.removeQueuedFiles,
                                round: "",
                                dense: "",
                                flat: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                                      _createElementVNode("span", null, "Clear All", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (scope.uploadedFiles.length > 0)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 1,
                                icon: "done_all",
                                onClick: scope.removeUploadedFiles,
                                round: "",
                                dense: "",
                                flat: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                                      _createElementVNode("span", null, "Remove Uploaded Files", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (scope.isUploading)
                            ? (_openBlock(), _createBlock(_component_q_spinner, {
                                key: 2,
                                class: "q-uploader__spinner"
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_8, [
                            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "q-uploader__title" }, "Upload your files", -1)),
                            _createElementVNode("div", _hoisted_9, _toDisplayString(scope.uploadSizeLabel), 1),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(scope.files.length) + " Files ", 1)
                          ]),
                          (scope.canAddFiles)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 3,
                                type: "a",
                                icon: "mdi-plus-circle",
                                onClick: scope.pickFiles,
                                round: "",
                                dense: "",
                                flat: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_uploader_add_trigger),
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                                      _createElementVNode("span", null, "Pick Files", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (scope.canUpload && _expanded.value !== true)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 4,
                                icon: "mdi-upload",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (uploadAttachment())),
                                round: "",
                                dense: "",
                                flat: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                                      _createElementVNode("span", null, "Upload Files", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (scope.isUploading)
                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                key: 5,
                                icon: "mdi-close-circle",
                                onClick: scope.abort,
                                round: "",
                                dense: "",
                                flat: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_tooltip, null, {
                                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                                      _createElementVNode("span", null, "Abort Upload", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      list: _withCtx((scope) => [
                        _createVNode(_component_q_list, { separator: "" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.files, (file) => {
                              return (_openBlock(), _createBlock(_component_q_item, {
                                key: file.__key
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_label, { class: "full-width ellipsis" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(file.name), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_q_item_label, { caption: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Status: " + _toDisplayString(file.__status), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_q_item_label, { caption: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(file.__sizeLabel) + " / " + _toDisplayString(file.__progressLabel), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (file.__img)
                                    ? (_openBlock(), _createBlock(_component_q_item_section, {
                                        key: 0,
                                        thumbnail: "",
                                        class: "gt-xs"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("img", {
                                            src: file.__img.src
                                          }, null, 8, _hoisted_11)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_q_item_section, {
                                    top: "",
                                    side: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_btn, {
                                        class: "gt-xs",
                                        size: "12px",
                                        flat: "",
                                        dense: "",
                                        round: "",
                                        icon: "mdi-delete",
                                        onClick: ($event: any) => (scope.removeFile(file))
                                      }, null, 8, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_q_expansion_item, {
                    label: "Additional Files",
                    modelValue: _expanded.value,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_expanded).value = $event)),
                    "expanded-icon": "mdi-chevron-down",
                    "expand-icon": "mdi-chevron-up"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_q_uploader, {
                          multiple: "",
                          batch: "",
                          style: {"width":"500px"},
                          onAdded: additionalAddedFiles,
                          onRejected: onRejected,
                          accept: "application/pdf",
                          "max-files": "10"
                        }, {
                          header: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_13, [
                              (scope.queuedFiles.length > 0)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 0,
                                    icon: "mdi-notification-clear-all",
                                    onClick: scope.removeQueuedFiles,
                                    round: "",
                                    dense: "",
                                    flat: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                                          _createElementVNode("span", null, "Clear All", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (scope.uploadedFiles.length > 0)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 1,
                                    icon: "done_all",
                                    onClick: scope.removeUploadedFiles,
                                    round: "",
                                    dense: "",
                                    flat: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                                          _createElementVNode("span", null, "Remove Uploaded Files", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (scope.isUploading)
                                ? (_openBlock(), _createBlock(_component_q_spinner, {
                                    key: 2,
                                    class: "q-uploader__spinner"
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_14, [
                                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "q-uploader__title" }, "Upload your files", -1)),
                                _createElementVNode("div", _hoisted_15, _toDisplayString(scope.uploadSizeLabel), 1),
                                _createElementVNode("div", _hoisted_16, _toDisplayString(scope.files.length) + " Files ", 1)
                              ]),
                              (scope.canAddFiles)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 3,
                                    type: "a",
                                    icon: "mdi-plus-circle",
                                    onClick: scope.pickFiles,
                                    round: "",
                                    dense: "",
                                    flat: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_uploader_add_trigger),
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                                          _createElementVNode("span", null, "Pick Files", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (scope.canUpload)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 4,
                                    icon: "mdi-upload",
                                    onClick: _cache[7] || (_cache[7] = ($event: any) => (uploadAttachment())),
                                    round: "",
                                    dense: "",
                                    flat: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[33] || (_cache[33] = [
                                          _createElementVNode("span", null, "Upload Files", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (scope.isUploading)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 5,
                                    icon: "mdi-close-circle",
                                    onClick: scope.abort,
                                    round: "",
                                    dense: "",
                                    flat: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                                          _createElementVNode("span", null, "Abort Upload", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          list: _withCtx((scope) => [
                            _createVNode(_component_q_list, { separator: "" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.files, (file) => {
                                  return (_openBlock(), _createBlock(_component_q_item, {
                                    key: file.__key
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, { class: "full-width ellipsis" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(file.name), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_q_item_label, { caption: "" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" Status: " + _toDisplayString(file.__status), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_q_item_label, { caption: "" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(file.__sizeLabel) + " / " + _toDisplayString(file.__progressLabel), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      (file.__img)
                                        ? (_openBlock(), _createBlock(_component_q_item_section, {
                                            key: 0,
                                            thumbnail: "",
                                            class: "gt-xs"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("img", {
                                                src: file.__img.src
                                              }, null, 8, _hoisted_17)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_q_item_section, {
                                        top: "",
                                        side: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_btn, {
                                            class: "gt-xs",
                                            size: "12px",
                                            flat: "",
                                            dense: "",
                                            round: "",
                                            icon: "mdi-delete",
                                            onClick: ($event: any) => (scope.removeFile(file))
                                          }, null, 8, ["onClick"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_q_inner_loading, {
                    label: "Please wait...",
                    "label-style": "font-size: 1.1em"
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(SupplierDataTable, {
        isLoading: _isInvoicesLoading.value,
        headers: headers,
        items: _items.value,
        search: _search.value,
        selectedItems: _selectedItems.value,
        onUpdateTable: RefreshTable,
        onUpdateBulk: BulkRefreshTable,
        "onUpdate:selectedItems": _cache[11] || (_cache[11] = ($event: any) => (_selectedItems.value = $event)),
        "onUpdate:approve": _cache[12] || (_cache[12] = ($event: any) => (ApproveSelected(_selectedItems.value))),
        "onUpdate:archive": _cache[13] || (_cache[13] = ($event: any) => (ArchiveSelected(_selectedItems.value)))
      }, null, 8, ["isLoading", "items", "search", "selectedItems"]),
      _createVNode(_component_q_btn, {
        color: "primary",
        style: {"position":"absolute","right":"10px","bottom":"70px"},
        icon: _nav.value ? 'mdi-close' : 'mdi-account-circle',
        round: "",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_menu, {
            "transition-show": "scale",
            "transition-hide": "scale",
            fit: "",
            offset: [-5, 0],
            "auto-close": "",
            style: {"box-shadow":"none","background-color":"transparent","overflow-y":"hidden","overflow-x":"hidden"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    round: "",
                    color: "secondary",
                    icon: "mdi-clipboard-file",
                    to: "/supplieraccounts/mappings"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, {
                        anchor: "center right",
                        self: "center left"
                      }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createTextVNode(" Supplier Mapping ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    round: "",
                    color: "secondary",
                    icon: "mdi-layers",
                    to: "/supplieraccounts/supplierdefaults"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, {
                        anchor: "center right",
                        self: "center left"
                      }, {
                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                          _createTextVNode(" Supplier defaults ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[38] || (_cache[38] = _createElementVNode("br", null, null, -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["icon"])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _dialog.value,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_dialog).value = $event)),
      style: {"width":"500px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { height: "150px" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_message.value), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_space),
                _withDirectives((_openBlock(), _createBlock(_component_q_btn, { color: "primary" }, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode(" Close ")
                  ])),
                  _: 1
                })), [
                  [_directive_close_popup]
                ]),
                _createVNode(_component_q_space)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})