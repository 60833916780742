import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row-6 q-pb-md"
}
const _hoisted_2 = {
  key: 1,
  class: "row-6 q-pb-md"
}
const _hoisted_3 = {
  key: 2,
  class: "row-6 q-pb-md"
}
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { class: "row items-center justify-end" }

import { ref, computed } from 'vue';
import date from 'quasar/src/utils/date/date.js';import debounce from 'quasar/src/utils/debounce/debounce.js';;
import { BaseService } from '@/services/BaseService';
import moment from 'moment';
import { customerStore } from '@/store/customer';
import { supplierStore } from '@/store/suppliers';
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import {
	ICustomer,
	ISite,
	Supplier,
} from 'greenzonegateway.classes/lib/classes';
import { ISageReference } from 'greenzonegateway.classes/lib/classes/Generic';


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkUpdateModal',
  props: {
	selectedItems: Array<any>,
	type: Number,
},
  emits: ['close', 'update:bulk'],
  setup(__props, { emit: __emit }) {

const _nameRules = [(v: string) => !!v || 'Field is required'];
const _supplierNameRules = [
	(v: string) => v != null || 'This field is required',
];

const _customersStore = customerStore();
const _suppliersStore = supplierStore();
const _filteredCustomers = ref<ICustomer[]>([]);
const _service = new BaseService();
const _emit = __emit;
const _customer = ref<ICustomer | null>(null);
const _supplier = ref<Supplier | null>(null);
const _isLoading = ref(false);
const _updatedItems = ref();
const _updatedStartDate = ref();
const _updatedEndDate = ref();
const startDate = ref();
const endDate = ref();
const _postcode = ref('');
const _site = ref<ISite[]>([]);
const props = __props;
const bulkModalForm = ref();

const fromToDateRule = (value: string): boolean | string => {
	const from = date.extractDate(_updatedStartDate.value, 'DD/MM/YYYY');
	const to = date.extractDate(_updatedEndDate.value, 'DD/MM/YYYY');

	return from <= to || 'From Date must be earlier than or equal to To Date';
};

const requiredDateRule = (value: string): boolean | string => {
	return value ? true : 'Date Required';
};

const _sageReferences = ref<ISageReference[]>();
const _sageReference = ref<ISageReference | null>();
const _delaySageReferences = debounce(GetSageReferences, 500);
const _delayedGetSites = debounce(GetPostCodes, 500);

async function GetSageReferences() {
	if (_supplier.value?.SupplierId) {
		_sageReferences.value = [];
		_sageReference.value = null;
		_service
			.Get<any>(
				'/Suppliers/List/SageReferences?supplierId=' +
					_supplier.value.SupplierId
			)
			.then((response: any) => {
				let list = [];
				list = response;
				for (let i = 0; i < list.length; i++) {
					_sageReferences.value?.push(list[i].SageReference);
				}
			});
	}
}
const _customers = computed({
	get() {
		return _filteredCustomers.value.length > 0
			? _filteredCustomers.value
			: _customersStore.customers;
	},
	set(val) {
		_customersStore.customers = val;
	},
});

async function GetPostCodes() {
	_customersStore.isLoading = true;
	_service
		.Get<any>('/Customers/AI/GetPostCodes?postcode=' + _postcode.value)
		.then((postcode: any) => {
			_site.value = postcode;
			let list = [];
			for (let i = 0; i < _site.value.length; i++) {
				list.push(_site.value[i].Customer);
			}

			_filteredCustomers.value = [...new Set(list)] as ICustomer[];
		})
		.finally(() => {
			_customersStore.isLoading = false;
		});
}
async function Submit(): Promise<void> {
	_isLoading.value = true;
	_updatedItems.value = props.selectedItems;
	let ids: any[] = [];
	for (let i = 0; i < _updatedItems.value.length; i++) {
		ids.push(_updatedItems.value[i].ProcessedSupplierInvoice.Id);
	}
	if (props.type === 1) {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/CustomerId',
								value: _customer.value?.CustomerId ?? 0,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.Customer =
								_customer.value;
							_emit('update:bulk', _updatedItems);
						}
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	} else if (props.type === 2) {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/SupplierId',
								value: _supplier.value?.SupplierId ?? 0,
							},
							{
								op: 'replace',
								path: '/SageReferenceId',
								value: _sageReference.value?.Id ?? null,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.Supplier =
								_supplier.value;
							_updatedItems.value[i].ProcessedSupplierInvoice.SageReference =
								_sageReference.value;
							_emit('update:bulk', _updatedItems);
						}
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	} else {
		bulkModalForm.value.validate().then((success: any) => {
			if (success) {
				startDate.value =
					moment(_updatedStartDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
					'T00:00:00';
				endDate.value =
					moment(_updatedEndDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
					'T00:00:00';
				_service
					.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
						processedInvoiceIds: ids,
						operations: [
							{
								op: 'replace',
								path: '/StartDate',
								value: startDate.value,
							},
							{
								op: 'replace',
								path: '/EndDate',
								value: endDate.value,
							},
						],
					})
					.then(() => {
						for (let i = 0; i < _updatedItems.value.length; i++) {
							_updatedItems.value[i].ProcessedSupplierInvoice.StartDate =
								startDate;
							_updatedItems.value[i].ProcessedSupplierInvoice.EndDate = endDate;
						}
						_emit('update:bulk', _updatedItems);
					})
					.finally(() => {
						_isLoading.value = false;
					});
			} else {
				_isLoading.value = false;
			}
		});
	}
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"width":"800px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-accent text-white" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { class: "text-h5" }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Bulk Update Invoice")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_form, {
        ref_key: "bulkModalForm",
        ref: bulkModalForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              (__props.type === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_unref(GZGenericSelect), {
                      multiple: false,
                      items: _unref(_suppliersStore).getSuppliers,
                      name: "SupplierName",
                      label: "Supplier",
                      modelValue: _supplier.value,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_supplier).value = $event)),
                        _unref(_delaySageReferences)
                      ],
                      rules: _nameRules
                    }, null, 8, ["items", "modelValue", "onUpdate:modelValue"]),
                    _createVNode(_unref(GZGenericSelect), {
                      multiple: false,
                      items: _sageReferences.value,
                      modelValue: _sageReference.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_sageReference).value = $event)),
                      name: "Reference",
                      label: "Sage Reference",
                      rules: _nameRules
                    }, null, 8, ["items", "modelValue"])
                  ]))
                : (__props.type === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_unref(GZGenericSelect), {
                        multiple: false,
                        loading: _unref(_customersStore).isLoading,
                        disabled: _unref(_customersStore).isLoading,
                        items: _customers.value,
                        name: "CustomerName",
                        label: "Customer",
                        modelValue: _customer.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_customer).value = $event)),
                        rules: _nameRules
                      }, null, 8, ["loading", "disabled", "items", "modelValue"]),
                      _createVNode(_component_q_input, {
                        label: "Site Postcode Search",
                        outlined: "",
                        "onUpdate:modelValue": [
                          _unref(_delayedGetSites),
                          _cache[3] || (_cache[3] = ($event: any) => ((_postcode).value = $event))
                        ],
                        modelValue: _postcode.value
                      }, null, 8, ["onUpdate:modelValue", "modelValue"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_q_input, {
                        label: "From Date",
                        modelValue: _updatedStartDate.value,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_updatedStartDate).value = $event)),
                        outlined: "",
                        rules: [fromToDateRule, requiredDateRule],
                        "reactive-rules": ""
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "mdi-calendar-range",
                            class: "cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_popup_proxy, {
                                cover: "",
                                "transition-show": "scale",
                                "transition-hide": "scale"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_date, {
                                    modelValue: _updatedStartDate.value,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_updatedStartDate).value = $event)),
                                    mask: "DD/MM/YYYY",
                                    title: "Date"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_4, [
                                        _withDirectives(_createVNode(_component_q_btn, {
                                          label: "Close",
                                          color: "primary",
                                          flat: ""
                                        }, null, 512), [
                                          [_directive_close_popup]
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"]),
                      _createVNode(_component_q_input, {
                        label: "To Date",
                        modelValue: _updatedEndDate.value,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_updatedEndDate).value = $event)),
                        rules: [fromToDateRule, requiredDateRule],
                        "reactive-rules": "",
                        outlined: ""
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "mdi-calendar-range",
                            class: "cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_popup_proxy, {
                                cover: "",
                                "transition-show": "scale",
                                "transition-hide": "scale"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_date, {
                                    modelValue: _updatedEndDate.value,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_updatedEndDate).value = $event)),
                                    mask: "DD/MM/YYYY"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_5, [
                                        _withDirectives(_createVNode(_component_q_btn, {
                                          label: "Close",
                                          color: "primary",
                                          flat: ""
                                        }, null, 512), [
                                          [_directive_close_popup]
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"])
                    ]))
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: "Cancel",
                color: "accent",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_emit('close', true)))
              }),
              _createVNode(_component_q_btn, {
                outline: "",
                color: "positive",
                label: "Submit",
                onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (Submit()), ["prevent","stop"])),
                loading: _isLoading.value
              }, null, 8, ["loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}
}

})