import script from "./SupplierMappingsView.vue?vue&type=script&setup=true&lang=ts"
export * from "./SupplierMappingsView.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/.pnpm/vue-cli-plugin-quasar@5.1.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QToolbar,QToolbarTitle,QPageContainer,QCard,QCardSection,QForm,QInput,QSpace,QSeparator,QCardActions,QBtn});
