<template>
	<div class="home internal-container" style="width: 100%; height: 100%">
		<!-- <v-app-bar color="primary" elevation="0"> </v-app-bar>
		<v-app-bar theme="dark" color="var(--v-primary-darken2)"
			><v-app-bar-title>Home</v-app-bar-title></v-app-bar
		> -->
		<q-toolbar class="bg-accent text-white q-pl-none">
			<q-toolbar-title style="padding-left: 16px">Home</q-toolbar-title>
		</q-toolbar>
		<div class="pa-4 layout-container" style="height: 90%" fluid>
			<div style="height: 100%">
				<q-page-sticky
					position="bottom-right"
					style="margin: 8px !important"
					:offset="[24, 24]"
				>
					<q-fab
						color="primary"
						icon="mdi-dots-vertical"
						v-model="_fab"
						vertical-actions-align="left"
						direction="up"
						hide-icon
					>
						<template v-slot:label="{ opened }">
							<q-icon
								style="padding: none !important"
								v-if="opened"
								name="mdi-close"
							></q-icon>
							<q-icon
								class="q-pa-none"
								v-else
								name="mdi-dots-vertical"
							></q-icon>
						</template>
						<q-fab-action
							external-label
							label-position="left"
							color="primary"
							icon="mdi-cloud"
							label="Data Matching"
							@click="Navigate(`datamatching`, null, null)"
						/>
						<q-fab-action
							external-label
							label-position="left"
							color="primary"
							icon="mdi-cloud"
							label="Weight Data Matching"
							@click="Navigate(`weight-datamatching-spreadsheets`, null, null)"
						/>
						<q-fab-action
							external-label
							label-position="left"
							color="primary"
							icon="mdi-map-outline"
							label="Bin/Site Mappings"
							@click="
								Navigate(`weight-datamatching-binsitemappings`, null, null)
							"
						/>
					</q-fab>
				</q-page-sticky>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import router from '@/router';
import { ref } from 'vue';

const _fab = ref();

function Navigate(
	name: string,
	query: any = null,
	params: any = null,
	isExternal = false
): void {
	if (!isExternal) {
		router.push({
			name: name,
			query: query,
			params: params,
		});
	} else {
		router.push({ path: name, query: query });
	}
}
</script>

<style lang="scss">
.q-fab__label--internal-right {
	padding-right: 0em;
	padding-left: 0em;
}
@import '../styles/base-styles.scss';
.q-btn__content
	.text-center
	.col
	.items-center
	.q-anchor--skip
	.justify-center
	.row {
	.row {
		margin: 0px !important;
	}
}
</style>
