import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-weight-bold" }
const _hoisted_2 = { class: "text-weight-bold" }
const _hoisted_3 = { class: "text-weight-bold" }
const _hoisted_4 = { class: "text-weight-bold" }
const _hoisted_5 = { class: "text-weight-bold" }
const _hoisted_6 = { class: "text-weight-bold" }
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { style: {"flex":"1"} }
const _hoisted_9 = { style: {"flex":"3"} }
const _hoisted_10 = { class: "text-weight-bold" }
const _hoisted_11 = { class: "text-weight-bold" }
const _hoisted_12 = { class: "text-weight-bold" }
const _hoisted_13 = { class: "text-weight-bold" }
const _hoisted_14 = { class: "text-weight-bold" }
const _hoisted_15 = { class: "full-width row flex-center q-gutter-sm" }

import { computed, ref } from 'vue';
import EditDetailsModalContent from './Modals/EditDetailsModalContent.vue';
import BulkUpdateModal from './Modals/BulkUpdateModal.vue';
import { BaseService } from '@/services/BaseService';

import moment from 'moment';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { customerStore } from '@/store/customer';
const enum BulkTypeEnum {
	Customer = 'Customer',
	Supplier = 'Supplier',
	DateRange = 'DateRange',
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SupplierDataTable',
  props: {
	items: Array,
	headers: Array,
	itemKey: String,
	itemsPerPage: Number,
	isLoading: { type: Boolean, default: false },
	search: String,
	selectedItems: Array,
},
  emits: [
	'updateTable',
	'updateBulk',
	'update:search',
	'update:selectedItems',
	'update:approve',
	'update:archive',
],
  setup(__props, { emit: __emit }) {

const _modalVisible = ref(false);
const _modalVisibleBulkUpdate = ref(false);
const _processedSupplierInvoices = ref();
const _customersStore = customerStore();
const _service = new BaseService();
const $q = useQuasar();
const bulkUpdateType = ref(0);
function SelectedBulkType(value: BulkTypeEnum) {
	if (value === BulkTypeEnum.Customer) {
		bulkUpdateType.value = 1;
		_modalVisibleBulkUpdate.value = true;
	} else if (value === BulkTypeEnum.Supplier) {
		bulkUpdateType.value = 2;
		_modalVisibleBulkUpdate.value = true;
	} else {
		bulkUpdateType.value = 3;
		_modalVisibleBulkUpdate.value = true;
	}
}

const props = __props;

const _selected = computed({
	get() {
		return props.selectedItems || [];
	},
	set(val) {
		emit('update:selectedItems', val);
	},
});

const emit = __emit;

function EmitApprove() {
	emit('update:approve');
}
function EmitArchive() {
	emit('update:archive');
}

function Close() {
	_modalVisible.value = false;
	_selected.value = [];
}

function BulkClose() {
	_modalVisibleBulkUpdate.value = false;
	_selected.value = [];
}
function UpdateTable(event: any) {
	if (!event) {
		return;
	}
	emit('updateTable', event);
	Close();
}

function UpdateBulk(event: any) {
	if (!event) {
		return;
	}
	emit('updateBulk', event);
	BulkClose();
}

function EditMatch(item: any) {
	_processedSupplierInvoices.value = item.row.ProcessedSupplierInvoice;

	// Deselecting rows and adding current one to selected
	_selected.value = [];
	_selected.value.push(item.row.ProcessedSupplierInvoice);

	if (!_customersStore.customers.length) {
		_customersStore.updateCustomersList();
	}

	_modalVisible.value = true;
}

function getSelectedString() {
	return _selected.value.length === 0
		? ''
		: `${_selected.value.length} record${
				_selected.value.length > 1 ? 's' : ''
		  };`;
}

function DownloadInvoice(id: number) {
	_service
		.Get<any>(
			'Suppliers/ProcessedPurchaseInvoices/Download?processedSupplierInvoiceId=' +
				`${id}`
		)
		.then((res: string) => {
			window.open(res, '_blank');
		});
}

function getColor(approved: boolean) {
	if (approved === true) return 'green';
	else return 'red';
}

function FormatDate(date: string) {
	if (!date) {
		return 'Not Found';
	} else {
		return moment(date).format('DD/MM/YYYY');
	}
}

function FormatPercentage(number: number) {
	if (number >= 90) {
		return 'green';
	} else if (number < 90 && number >= 80) {
		return 'warning';
	} else if (number < 80) {
		return 'red';
	}
}
const formatter = new Intl.NumberFormat('en-GB', {
	style: 'currency',
	currency: 'GBP',
	minimumFractionDigits: 2,
});
function formatToCurrency(number: number) {
	return formatter.format(number);
}

return (_ctx: any,_cache: any) => {
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _modalVisible.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_modalVisible).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(EditDetailsModalContent, {
          processed: _processedSupplierInvoices.value,
          "onUpdate:processed": _cache[0] || (_cache[0] = ($event: any) => (UpdateTable($event))),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (Close()))
        }, null, 8, ["processed"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_dialog, {
      modelValue: _modalVisibleBulkUpdate.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_modalVisibleBulkUpdate).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(BulkUpdateModal, {
          "selected-items": _selected.value,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (BulkClose())),
          type: bulkUpdateType.value,
          "onUpdate:bulk": _cache[4] || (_cache[4] = ($event: any) => (UpdateBulk($event)))
        }, null, 8, ["selected-items", "type"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_table, {
      selected: _selected.value,
      "rows-per-page-options": [0],
      filter: __props.search,
      columns: __props.headers,
      rows: __props.items,
      "fixed-header": true,
      loading: __props.isLoading,
      "selected-rows-label": getSelectedString,
      "onUpdate:selected": _cache[9] || (_cache[9] = ($event: any) => (_selected.value = $event)),
      style: {"height":"80vh","position":"relative","width":"100%"},
      class: "overflow-y-auto",
      "virtual-scroll": "",
      "row-key": "Id",
      selection: "multiple",
      "binary-state-sort": "",
      "return-object": "",
      "table-header-style": {
				backgroundColor: _unref($q).dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
			}
    }, {
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, { "body-selection": "props" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_checkbox, {
                  modelValue: props.selected,
                  "onUpdate:modelValue": ($event: any) => ((props.selected) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, {
              key: "ProcessedSupplierInvoice.SupplierName",
              props: props
            }, {
              default: _withCtx(() => [
                (props.row.ProcessedSupplierInvoice.Supplier !== null)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      location: "top"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_1, _toDisplayString(props.row.ProcessedSupplierInvoice.Supplier.SupplierName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_2, _toDisplayString(props.row.ProcessedSupplierInvoice.SupplierName === 'Unknown' &&
								props.row.ProcessedSupplierInvoice.Supplier !== null
									? props.row.ProcessedSupplierInvoice.Supplier.SupplierName
									: props.row.ProcessedSupplierInvoice.SupplierName), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.SupplierNameConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.SupplierNameConfidence) + "%", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_3, _toDisplayString(props.row.ProcessedSupplierInvoice.SageReference != null
									? props.row.ProcessedSupplierInvoice.SageReference.Reference
									: ''), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, {
              key: "ProcessedSupplierInvoice.InvoiceNo",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_4, _toDisplayString(props.row.ProcessedSupplierInvoice.InvoiceNo), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.InvoiceNoConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.InvoiceNoConfidence) + "% ", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "ProcessedSupplierInvoice.InvoiceDate",
              props: props
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_5, _toDisplayString(FormatDate(props.row.ProcessedSupplierInvoice.InvoiceDate)), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.InvoiceDateConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.InvoiceDateConfidence) + "% ", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                (props.row.ProcessedSupplierInvoice.Customer !== null)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      location: "top"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.ProcessedSupplierInvoice.SiteAddress) + " ", 1),
                        _createVNode(_component_q_td, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(props.row.ProcessedSupplierInvoice.Customer?.CustomerName), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_6, _toDisplayString(props.row.ProcessedSupplierInvoice.Customer?.CustomerName), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(props.row.ProcessedSupplierInvoice.Customer?.AccountNumber), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_q_chip, {
                      class: "text-weight-bold",
                      color: FormatPercentage(props.row.CustomerConfidence)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.CustomerConfidence) + "% ", 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ])
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_10, _toDisplayString(formatToCurrency(props.row.ProcessedSupplierInvoice.Net)), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.NetConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.NetConfidence) + "% ", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_11, _toDisplayString(formatToCurrency(props.row.ProcessedSupplierInvoice.VAT)), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.VATConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.VATConfidence) + "% ", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_12, _toDisplayString(formatToCurrency(props.row.ProcessedSupplierInvoice.Total)), 1),
                _createVNode(_component_q_chip, {
                  class: "text-weight-bold",
                  color: FormatPercentage(props.row.TotalConfidence)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.TotalConfidence) + "% ", 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  color: getColor(props.row.ProcessedSupplierInvoice.Approved),
                  size: "md",
                  name: 
								props.row.ProcessedSupplierInvoice.Approved
									? 'mdi-check'
									: 'mdi-close'
							
                }, null, 8, ["color", "name"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_13, _toDisplayString(props.row.ProcessedSupplierInvoice.SupplierInvoiceFile
									.SupplierSupplementaryInvoiceFiles.length), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { class: "text-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_14, _toDisplayString(props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.User !=
								null
									? props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.User
											.FullName
									: ''), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, {
              key: "view",
              props: props,
              class: "text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  dense: "",
                  round: "",
                  size: "md",
                  class: "q-mx-sm",
                  "q-bind": "props",
                  onClick: ($event: any) => (EditMatch(props))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      color: "secondary",
                      name: "mdi-pencil",
                      size: "sm",
                      title: "Edit"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_q_btn, {
                  flat: "",
                  dense: "",
                  round: "",
                  size: "md",
                  class: "q-mx-sm",
                  "q-bind": "props",
                  "v-if": 
								props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.FilePath
							,
                  onClick: ($event: any) => (DownloadInvoice(props.row.ProcessedSupplierInvoice.Id)),
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      color: "secondary",
                      name: "mdi-download",
                      size: "sm",
                      title: "Download"
                    })
                  ]),
                  _: 2
                }, 1032, ["v-if", "onClick"])
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_menu, {
              "touch-position": "",
              "context-menu": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_list, {
                  dense: "",
                  style: {"min-width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      style: {"padding":"16px"},
                      clickable: "",
                      disable: __props.selectedItems.length < 1,
                      onClick: EmitApprove
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Approve " + _toDisplayString('(' + __props.selectedItems.length + ')'), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable"])), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      style: {"padding":"16px"},
                      clickable: "",
                      disable: __props.selectedItems.length < 1,
                      onClick: EmitArchive
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Archive " + _toDisplayString('(' + __props.selectedItems.length + ')'), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable"])), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      style: {"padding":"16px"},
                      clickable: "",
                      disable: __props.selectedItems.length < 1,
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (SelectedBulkType(BulkTypeEnum.Supplier)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Update Supplier " + _toDisplayString('(' + __props.selectedItems.length + ')'), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable"])), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      style: {"padding":"16px"},
                      clickable: "",
                      disable: __props.selectedItems.length < 1,
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (SelectedBulkType(BulkTypeEnum.Customer)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Update Customer " + _toDisplayString('(' + __props.selectedItems.length + ')'), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable"])), [
                      [_directive_close_popup]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      style: {"padding":"16px"},
                      clickable: "",
                      disable: __props.selectedItems.length < 1,
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (SelectedBulkType(BulkTypeEnum.DateRange)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Update Date Range " + _toDisplayString('(' + __props.selectedItems.length + ')'), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable"])), [
                      [_directive_close_popup]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "header-selection": _withCtx((scope) => [
        _createVNode(_component_q_checkbox, {
          modelValue: scope.selected,
          "onUpdate:modelValue": ($event: any) => ((scope.selected) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      "no-data": _withCtx(() => [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_q_icon, {
            size: "2em",
            name: "mdi-alert"
          }),
          _cache[10] || (_cache[10] = _createElementVNode("span", null, "No data available", -1))
        ])
      ]),
      pagination: _withCtx(() => _cache[11] || (_cache[11] = [])),
      _: 1
    }, 8, ["selected", "filter", "columns", "rows", "loading", "table-header-style"])
  ]))
}
}

})