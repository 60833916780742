import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-3 q-mr-md" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-1 q-mr-md" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }

import { ref, nextTick } from 'vue';
import debounce from 'quasar/src/utils/debounce/debounce.js';import QSelect from 'quasar/src/components/select/QSelect.js';;
import { BaseService } from '@/services/BaseService';
import { GZDraggableModal } from '@gz/quasar-components-vue3';
import {
	Customer,
	DataMatchingUpdateSiteMapDto,
	Site,
} from 'greenzonegateway.classes/lib/classes';

//defines

export default /*@__PURE__*/_defineComponent({
  __name: 'SiteMappingModal',
  props: {
	siteMappingData: Object,
	mappingsMode: Boolean,
	isBinSiteMode: { type: Boolean, default: false, require: false },
},
  emits: ['siteUpdated', 'submit'],
  setup(__props, { expose: __expose, emit: __emit }) {

__expose({ Close, Open });
const _props = __props;
const _emit = __emit;
const _service = new BaseService();

//refs
const _siteMappingModal = ref<typeof GZDraggableModal>();
const _customerDropdownRef = ref<QSelect>();

//flags
const _isLoadingCustomers = ref(false);
const _isLoadingSites = ref(false);
const _isRelevantSearch = ref(true);

//vars
const _customerList = ref<Customer[]>([]);
const _siteList = ref<Site[]>([]);
const _siteListFiltered = ref<Site[]>([]);

const _customerSelected = ref<Customer>();
const _siteSelected = ref<Site>();

const debouncedGetCustomerList = debounce(
	(query: string) => GetCustomerList(query),
	500
);

function Close() {
	Reset();
	_siteMappingModal.value?.hide();
}

function Open() {
	_siteMappingModal.value?.show();
}

function Reset() {
	_isRelevantSearch.value = true;
	_customerSelected.value = undefined;
	_siteSelected.value = undefined;
	_customerList.value = [];
	_siteListFiltered.value = [];
	_siteList.value = [];
}

function filterFn(val: string, update: any) {
	if (val === '') {
		update(() => {
			_siteListFiltered.value = _siteList.value;
		});
		return;
	}
	update(() => {
		const needle = val.toLowerCase();
		_siteListFiltered.value = _siteList.value.filter(
			(v) =>
				v.SiteName.toLowerCase().indexOf(needle) > -1 ||
				v.SiteReference.toLowerCase().indexOf(needle) > -1
		);
	});
}

//#region API Calls
function GetCustomerList(searchQuery: string) {
	if (!searchQuery || searchQuery.length < 2) {
		return;
	}
	_isLoadingCustomers.value = true;
	_service
		.Get<Customer[]>(`Customers/List?search=${searchQuery}`)
		.then((customers) => {
			_customerList.value = customers;
			nextTick(() => {
				_customerDropdownRef.value?.showPopup();
			});
		})
		.finally(() => {
			_isLoadingCustomers.value = false;
		});
}

function GetSitesList() {
	if (!_customerSelected.value) {
		return;
	}
	let url = `Sites/Customer/${_customerSelected.value.CustomerId}`;
	if (_isRelevantSearch.value) {
		url += `?supplierId=${_props.siteMappingData?.GroupedResult.SupplierId}`;
	}
	_isLoadingSites.value = true;
	_service
		.Get<Site[]>(url)
		.then((sites) => {
			_siteList.value = sites;
			_siteListFiltered.value = sites;
		})
		.finally(() => {
			_isLoadingSites.value = false;
		});
}

function PostSaveSitemap() {
	if (_props.isBinSiteMode) {
		_emit('submit', _siteSelected.value?.SiteId);
		Close();
		return;
	}
	const _data: DataMatchingUpdateSiteMapDto = {
		SiteMapId:
			_props.siteMappingData?.GroupedResult?.MatchedSiteMap?.Id ?? null,
		SiteId: Number(_siteSelected.value?.SiteId),
		ReportImportId:
			_props.siteMappingData?.GroupedResult.BinResults[0].ReportImportId,
	};
	_service
		.Post<DataMatchingUpdateSiteMapDto>(
			'DataMatchingWeights/SaveSitemap',
			_data
		)
		.then((site) => {
			_emit('siteUpdated', {
				site: site,
				internalSiteRowId: _props.siteMappingData?.InternalSiteRowId,
				reportImportId:
					_props.siteMappingData?.GroupedResult.BinResults[0].ReportImportId,
			});
			Close();
		});
}
//#endregion

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_unref(GZDraggableModal), {
    ref_key: "_siteMappingModal",
    ref: _siteMappingModal
  }, {
    header: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-accent text-white" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { class: "text-h5" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.siteMappingData.GroupedResult.SupplierName) + " - Site Mappings", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      (__props.siteMappingData)
        ? (_openBlock(), _createBlock(_component_q_card, {
            key: 0,
            style: {"min-width":"1000px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "row" }, [
                        _createElementVNode("b", null, "Site")
                      ], -1)),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.siteMappingData.GroupedResult.MatchedSiteName || 'N/A'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row" }, [
                        _createElementVNode("b", null, "Postcode")
                      ], -1)),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(__props.siteMappingData.GroupedResult.MatchedSitePostcode || 'N/A'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row" }, [
                        _createElementVNode("b", null, "Reference")
                      ], -1)),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(__props.siteMappingData.GroupedResult.MatchedSiteAddress || 'N/A'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_unref(QSelect), {
                        modelValue: _customerSelected.value,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((_customerSelected).value = $event)),
                          _cache[1] || (_cache[1] = ($event: any) => (GetSitesList()))
                        ],
                        options: _customerList.value,
                        loading: _isLoadingCustomers.value,
                        onInputValue: _unref(debouncedGetCustomerList),
                        ref_key: "_customerDropdownRef",
                        ref: _customerDropdownRef,
                        class: "full-width",
                        label: "Customer",
                        "option-value": "CustomerId",
                        "option-label": "CustomerName",
                        "use-input": "",
                        dense: "",
                        outlined: "",
                        "fill-input": "",
                        "hide-selected": ""
                      }, {
                        option: _withCtx((scope) => [
                          _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(scope.opt.CustomerName), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_q_item_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(scope.opt.CustomerInvoiceAddress.Postcode), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          (_customerSelected.value)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                                key: 0,
                                anchor: "top middle",
                                self: "bottom middle"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(_customerSelected.value.CustomerName), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options", "loading", "onInputValue"]),
                      _createVNode(_unref(QSelect), {
                        modelValue: _siteSelected.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_siteSelected).value = $event)),
                        options: _siteListFiltered.value,
                        disable: !_customerSelected.value,
                        loading: _isLoadingSites.value,
                        onFilter: filterFn,
                        class: "full-width q-pt-sm",
                        label: "Site",
                        "option-value": "SiteId",
                        "option-label": "SiteName",
                        "use-input": "",
                        "fill-input": "",
                        "hide-selected": "",
                        dense: "",
                        outlined: ""
                      }, {
                        option: _withCtx((scope) => [
                          _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(scope.opt.SiteName), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_q_item_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(scope.opt.ServiceAddress.Postcode) + " - " + _toDisplayString(scope.opt.SiteReference), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          (_siteSelected.value)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                                key: 0,
                                anchor: "top middle",
                                self: "bottom middle"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(_siteSelected.value.SiteName), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options", "disable", "loading"]),
                      _createVNode(_component_q_toggle, {
                        onClick: GetSitesList,
                        label: _isRelevantSearch.value ? 'Most Relevant' : 'All',
                        modelValue: _isRelevantSearch.value,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_isRelevantSearch).value = $event))
                      }, null, 8, ["label", "modelValue"])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, { onClick: Close }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_q_btn, {
                    color: "primary",
                    disable: !_siteSelected.value,
                    onClick: PostSaveSitemap
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  }, 8, ["disable"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}
}

})