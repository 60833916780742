<template>
	<div id="menu-container" class="q-pa-md col">
		<div class="row">
			<div class="col">
				<div class="col">
					<div class="row">
						<div class="heading-a">Container Type</div>
					</div>
					<div class="row">
						<div class="heading-b">
							{{ drawerData?.BinResult?.Bin || 'N/A' }}
						</div>
					</div>
				</div>
				<div class="col">
					<div class="row">
						<div class="heading-a">Quantity</div>
					</div>
					<div class="row">
						<div class="heading-b">
							{{ drawerData?.BinResult?.MatchedQuantity || 'N/A' }}
						</div>
					</div>
				</div>
				<div class="col">
					<div class="row">
						<div class="heading-a">On-Site</div>
					</div>
					<div class="row">
						<div class="heading-b">
							{{ drawerData?.BinResult?.onSite || 'N/A' }}
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="col">
					<div class="row">
						<div class="heading-a">Weight</div>
					</div>
					<div class="row">
						<div class="heading-b">
							{{
								drawerData?.BinResult?.SupplierActualWeightKgDisplay || 'N/A'
							}}
						</div>
					</div>
				</div>
				<div class="col">
					<div class="row">
						<div class="heading-a">Date Collected</div>
					</div>
					<div class="row">
						<div class="heading-b">
							{{ drawerData?.BinResult?.LiftDate?.Display || 'N/A' }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<q-separator class="q-mt-md"></q-separator>
		<div
			class="row"
			style="margin-top: 16px !important; margin-bottom: 16px !important"
			v-if="drawerData?.BinResult?.MatchedSchedules?.length"
		>
			<q-markup-table style="width: 480px; max-width: 480px" flat dense>
				<thead>
					<tr>
						<th>Date</th>
						<th>Weight</th>
						<th>Lift Status</th>
						<th>New Weight</th>
						<th>New Lift Status</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="matchedSchedule in drawerData?.BinResult?.MatchedSchedules"
						:key="matchedSchedule.ScheduleId"
					>
						<th>{{ matchedSchedule.ScheduleDate?.Display }}</th>
						<th>{{ matchedSchedule.ActualWeightKg?.Name }}</th>
						<th>{{ matchedSchedule.ActualLiftStatus?.Name }}</th>
						<th
							@click="
								{
									_newScheduleWeight = matchedSchedule.NewActualWeightKg?.Value;
									matchedSchedule.dialogOpen = true;
								}
							"
						>
							{{ matchedSchedule.NewActualWeightKg?.Name }}
							<q-icon
								name="mdi-pencil"
								v-if="!drawerData.BinResult?.WeightEntered?.Display"
							/>
							<GZSimpleEntryPopup
								v-if="!drawerData.BinResult?.WeightEntered?.Display"
								v-model="matchedSchedule.dialogOpen"
								@clickOutside="
									{
										matchedSchedule.dialogOpen = false;
									}
								"
							>
								<template #body>
									<q-input
										v-model="_newScheduleWeight"
										:loading="_isWeightInputLoading"
										:error="!!_weightInputRules"
										type="number"
										label="New Weight (kg)"
										dense
										hide-bottom-space
										@keydown.enter="
											!_weightInputRules ? PostNewWeight(matchedSchedule) : null
										"
										@keyup.enter="
											!_weightInputRules
												? (matchedSchedule.dialogOpen = false)
												: null
										"
									></q-input>
									<div style="color: red" v-if="_weightInputRules">
										{{ _weightInputRules }}
									</div>
								</template>
							</GZSimpleEntryPopup>
						</th>
						<th>{{ matchedSchedule.NewActualLiftStatus?.Name }}</th>
						<q-menu
							context-menu
							touch-position
							v-if="!drawerData.BinResult?.WeightEntered?.Display"
						>
							<q-list dense style="width: 200px">
								<q-item clickable>
									<q-item-section>Enter Actual Lift Status</q-item-section>
									<q-item-section side
										><q-icon name="mdi-chevron-right"></q-icon
									></q-item-section>
									<q-menu anchor="top end" self="top start">
										<q-list dense style="width: 200px">
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.Empty
													)
												"
												>Empty</q-item
											>
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.WastedJourney
													)
												"
												>Wasted Journey</q-item
											>
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.Contaminated
													)
												"
												>Contaminated</q-item
											>
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.Collected
													)
												"
												>Collected</q-item
											>
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.MissedLift
													)
												"
												>Missed Lift</q-item
											>
											<q-item
												clickable
												v-close-popup
												@click="
													PostStatus(
														matchedSchedule,
														ActualLiftStatusEnum.DifferentDay
													)
												"
												>Different Day</q-item
											>
											<q-item clickable v-close-popup>
												<q-item-section
													@click="PostStatus(matchedSchedule, null)"
													>No Status</q-item-section
												>
											</q-item>
										</q-list>
									</q-menu>
								</q-item>
							</q-list>
						</q-menu>
					</tr>
				</tbody>
			</q-markup-table>
		</div>
		<q-separator class="q-mb-md"></q-separator>
		<div class="row">
			<div class="col" style="text-align: center">
				<div>
					Customer Limit:
					<div
						style="display: inline"
						v-if="!_isSupplierCustomerWeightsLoading"
					>
						{{ _weightLimits?.CustomerLimitKg ?? 'N/A' }}
					</div>
					<q-spinner-oval style="margin-bottom: 3px" v-else />
				</div>
			</div>
			<div class="col" style="text-align: center">
				<div>
					Supplier Limit:
					<div
						style="display: inline"
						v-if="!_isSupplierCustomerWeightsLoading"
					>
						{{ _weightLimits?.SupplierLimitKg ?? 'N/A' }}
					</div>
					<q-spinner-oval style="margin-bottom: 3px" v-else />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col" style="text-align: center">
				<div>
					Customer Charge:
					<div
						style="display: inline"
						v-if="!_isSupplierCustomerWeightsLoading"
					>
						{{ _weightLimits?.CustomerOverweightPricePerKg ?? 'N/A' }}
					</div>
					<q-spinner-oval style="margin-bottom: 3px" v-else />
				</div>
			</div>
			<div class="col" style="text-align: center">
				<div>
					Supplier Charge:
					<div
						style="display: inline"
						v-if="!_isSupplierCustomerWeightsLoading"
					>
						{{ _weightLimits?.SupplierOverweightPricePerKg ?? 'N/A' }}
					</div>
					<q-spinner-oval style="margin-bottom: 3px" v-else />
				</div>
			</div>
		</div>
		<q-separator class="q-my-md"></q-separator>
		<div>
			<h5>Audit Log</h5>
			<div v-if="!drawerData.Audit.length" class="q-pb-sm">
				No audit data recorded against this item
			</div>
			<div
				v-for="audit in drawerData.Audit"
				:key="audit.ImportHistoryId"
				class="q-pb-sm"
			>
				<div class="q-pl-sm">
					<b>{{ audit.CreatedAt.Display }}</b>
				</div>
				<div class="q-pl-md">{{ audit.StepDetail }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, computed, watch } from 'vue';
import {
	ActualLiftStatusEnum,
	DataMatchingBinResultDTO,
	DataMatchingOverrideStatusDto,
	DataMatchingOverrideWeightDto,
	DataMatchingScheduleDTOShallow,
	WeightLimitsDTO,
} from 'greenzonegateway.classes/lib/classes';
import { GZSimpleEntryPopup } from '@gz/quasar-components-vue3';
import { BaseService } from '@/services/BaseService';

//defines
const _emit = defineEmits(['binChanged', 'scheduleChanged']);
const _props = defineProps({
	drawerData: { type: Object, required: true },
	isMenuOpen: Boolean,
});
const _service = new BaseService();
const _weightLimits = ref<WeightLimitsDTO>({
	ReportImportId: 0,
	CustomerOverweightPricePerKg: null,
	SupplierOverweightPricePerKg: null,
	CustomerLimitKg: null,
	SupplierLimitKg: null,
	IsCustomerCharged: false,
});

//flags
const _isWeightInputLoading = ref(false);
const _isSupplierCustomerWeightsLoading = ref(false);

const _weightInputRules = computed(() => {
	if (_newScheduleWeight.value) {
		if (_newScheduleWeight.value > 20000) {
			return 'Value cannot exceed 20,000';
		}
		if (_newScheduleWeight.value < 0) {
			return 'Value must be positive';
		}
		if (CountDecimals(_newScheduleWeight.value) > 2) {
			return 'Value at most can have 2 decimal points';
		}
	}
	return false;
});

//vars
const _newScheduleWeight = ref<number>();

function CountDecimals(num: number) {
	let asText = num.toString();
	let [_, back] = asText.split('.');
	if (back) {
		return back.length;
	}
	return 0;
}

watch(
	() => _props.drawerData?.BinResult,
	(oldVal: any, newVal: any) => {
		if (_props.isMenuOpen && oldVal !== newVal) {
			LoadWeightsData(_props.drawerData?.BinResult);
		}
	},
	{ deep: true }
);

//#region API Calls
function LoadWeightsData(binResult: DataMatchingBinResultDTO) {
	_weightLimits.value = {
		ReportImportId: 0,
		CustomerOverweightPricePerKg: null,
		SupplierOverweightPricePerKg: null,
		CustomerLimitKg: null,
		SupplierLimitKg: null,
		IsCustomerCharged: false,
	};
	_isSupplierCustomerWeightsLoading.value = true;
	_service
		.Get<WeightLimitsDTO>(
			`/DataMatchingWeights/WeightLimits?reportImportId=${binResult.ReportImportId}`
		)
		.then((response: WeightLimitsDTO) => {
			_weightLimits.value = response;
		})
		.finally(() => {
			_isSupplierCustomerWeightsLoading.value = false;
		});
}

function PostNewWeight(service: DataMatchingScheduleDTOShallow) {
	let data: DataMatchingOverrideWeightDto = {
		ReportImportId: _props.drawerData?.ReportImportId,
		Ssid: service.ScheduleId,
		NewWeight: _newScheduleWeight.value || null,
		IgnoreNewWeight: true,
	};
	_service
		.Post<DataMatchingOverrideWeightDto>(
			'DataMatchingWeights/OverrideWeight',
			data
		)
		.then((schedule: DataMatchingScheduleDTOShallow[]) => {
			_emit('binChanged', {
				schedule: schedule,
				internalSiteRowId: _props.drawerData?.InternalSiteRowId,
			});
		});
}

function PostStatus(service: any, liftStatus: ActualLiftStatusEnum | null) {
	let data: DataMatchingOverrideStatusDto = {
		ReportImportId: _props.drawerData?.ReportImportId,
		Ssid: service.ScheduleId,
		IgnoreNewActualLiftStatus: true,
		NewStatus: liftStatus,
	};
	_service
		.Post<DataMatchingOverrideStatusDto>(
			'DataMatchingWeights/OverrideStatus',
			data
		)
		.then((dataOut: DataMatchingScheduleDTOShallow) => {
			_emit('binChanged', {
				schedule: dataOut,
				internalSiteRowId: _props.drawerData?.InternalSiteRowId,
			});
		});
}
//#endregion
</script>

<style scoped>
.heading-a {
	font-size: 14pt;
}
.heading-b {
	font-size: 12pt;
}
</style>
