<template>
	<GZDraggableModal ref="_siteMappingModal">
		<template #header>
			<q-toolbar class="bg-accent text-white">
				<q-toolbar-title class="text-h5"
					>{{ siteMappingData.GroupedResult.SupplierName }} - Site
					Mappings</q-toolbar-title
				>
			</q-toolbar>
		</template>
		<template #body>
			<q-card style="min-width: 1000px" v-if="siteMappingData">
				<q-card-section>
					<div class="row">
						<div class="col-3 q-mr-md">
							<div class="row"><b>Site</b></div>
							<div class="row">
								{{ siteMappingData.GroupedResult.MatchedSiteName || 'N/A' }}
							</div>
						</div>
						<div class="col-1 q-mr-md">
							<div class="row"><b>Postcode</b></div>
							<div class="row">
								{{ siteMappingData.GroupedResult.MatchedSitePostcode || 'N/A' }}
							</div>
						</div>
						<div class="col-4">
							<div class="row"><b>Reference</b></div>
							<div class="row">
								{{ siteMappingData.GroupedResult.MatchedSiteAddress || 'N/A' }}
							</div>
						</div>
						<div class="col">
							<q-select
								v-model="_customerSelected"
								:options="_customerList"
								:loading="_isLoadingCustomers"
								@input-value="debouncedGetCustomerList"
								@update:model-value="GetSitesList()"
								ref="_customerDropdownRef"
								class="full-width"
								label="Customer"
								option-value="CustomerId"
								option-label="CustomerName"
								use-input
								dense
								outlined
								fill-input
								hide-selected
							>
								<template #option="scope">
									<q-item v-bind="scope.itemProps">
										<q-item-section>
											<q-item-label>{{ scope.opt.CustomerName }}</q-item-label>
											<q-item-label>{{
												scope.opt.CustomerInvoiceAddress.Postcode
											}}</q-item-label>
										</q-item-section>
									</q-item>
								</template>
								<q-tooltip
									v-if="_customerSelected"
									anchor="top middle"
									self="bottom middle"
								>
									<span>{{ _customerSelected.CustomerName }}</span>
								</q-tooltip>
							</q-select>
							<q-select
								v-model="_siteSelected"
								:options="_siteListFiltered"
								:disable="!_customerSelected"
								:loading="_isLoadingSites"
								@filter="filterFn"
								class="full-width q-pt-sm"
								label="Site"
								option-value="SiteId"
								option-label="SiteName"
								use-input
								fill-input
								hide-selected
								dense
								outlined
							>
								<template #option="scope">
									<q-item v-bind="scope.itemProps">
										<q-item-section>
											<q-item-label>{{ scope.opt.SiteName }}</q-item-label>
											<q-item-label
												>{{ scope.opt.ServiceAddress.Postcode }} -
												{{ scope.opt.SiteReference }}</q-item-label
											>
										</q-item-section>
									</q-item>
								</template>
								<q-tooltip
									v-if="_siteSelected"
									anchor="top middle"
									self="bottom middle"
								>
									<span>{{ _siteSelected.SiteName }}</span>
								</q-tooltip>
							</q-select>
							<q-toggle
								@click="GetSitesList"
								:label="_isRelevantSearch ? 'Most Relevant' : 'All'"
								v-model="_isRelevantSearch"
							></q-toggle>
						</div>
					</div>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn @click="Close">Cancel</q-btn>
					<q-btn
						color="primary"
						:disable="!_siteSelected"
						@click="PostSaveSitemap"
						>Save</q-btn
					>
				</q-card-actions>
			</q-card>
		</template>
	</GZDraggableModal>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineExpose, defineEmits, nextTick } from 'vue';
import { debounce, QSelect } from 'quasar';
import { BaseService } from '@/services/BaseService';
import { GZDraggableModal } from '@gz/quasar-components-vue3';
import {
	Customer,
	DataMatchingUpdateSiteMapDto,
	Site,
} from 'greenzonegateway.classes/lib/classes';

//defines
defineExpose({ Close, Open });
const _props = defineProps({
	siteMappingData: Object,
	mappingsMode: Boolean,
	isBinSiteMode: { type: Boolean, default: false, require: false },
});
const _emit = defineEmits(['siteUpdated', 'submit']);
const _service = new BaseService();

//refs
const _siteMappingModal = ref<typeof GZDraggableModal>();
const _customerDropdownRef = ref<QSelect>();

//flags
const _isLoadingCustomers = ref(false);
const _isLoadingSites = ref(false);
const _isRelevantSearch = ref(true);

//vars
const _customerList = ref<Customer[]>([]);
const _siteList = ref<Site[]>([]);
const _siteListFiltered = ref<Site[]>([]);

const _customerSelected = ref<Customer>();
const _siteSelected = ref<Site>();

const debouncedGetCustomerList = debounce(
	(query: string) => GetCustomerList(query),
	500
);

function Close() {
	Reset();
	_siteMappingModal.value?.hide();
}

function Open() {
	_siteMappingModal.value?.show();
}

function Reset() {
	_isRelevantSearch.value = true;
	_customerSelected.value = undefined;
	_siteSelected.value = undefined;
	_customerList.value = [];
	_siteListFiltered.value = [];
	_siteList.value = [];
}

function filterFn(val: string, update: any) {
	if (val === '') {
		update(() => {
			_siteListFiltered.value = _siteList.value;
		});
		return;
	}
	update(() => {
		const needle = val.toLowerCase();
		_siteListFiltered.value = _siteList.value.filter(
			(v) =>
				v.SiteName.toLowerCase().indexOf(needle) > -1 ||
				v.SiteReference.toLowerCase().indexOf(needle) > -1
		);
	});
}

//#region API Calls
function GetCustomerList(searchQuery: string) {
	if (!searchQuery || searchQuery.length < 2) {
		return;
	}
	_isLoadingCustomers.value = true;
	_service
		.Get<Customer[]>(`Customers/List?search=${searchQuery}`)
		.then((customers) => {
			_customerList.value = customers;
			nextTick(() => {
				_customerDropdownRef.value?.showPopup();
			});
		})
		.finally(() => {
			_isLoadingCustomers.value = false;
		});
}

function GetSitesList() {
	if (!_customerSelected.value) {
		return;
	}
	let url = `Sites/Customer/${_customerSelected.value.CustomerId}`;
	if (_isRelevantSearch.value) {
		url += `?supplierId=${_props.siteMappingData?.GroupedResult.SupplierId}`;
	}
	_isLoadingSites.value = true;
	_service
		.Get<Site[]>(url)
		.then((sites) => {
			_siteList.value = sites;
			_siteListFiltered.value = sites;
		})
		.finally(() => {
			_isLoadingSites.value = false;
		});
}

function PostSaveSitemap() {
	if (_props.isBinSiteMode) {
		_emit('submit', _siteSelected.value?.SiteId);
		Close();
		return;
	}
	const _data: DataMatchingUpdateSiteMapDto = {
		SiteMapId:
			_props.siteMappingData?.GroupedResult?.MatchedSiteMap?.Id ?? null,
		SiteId: Number(_siteSelected.value?.SiteId),
		ReportImportId:
			_props.siteMappingData?.GroupedResult.BinResults[0].ReportImportId,
	};
	_service
		.Post<DataMatchingUpdateSiteMapDto>(
			'DataMatchingWeights/SaveSitemap',
			_data
		)
		.then((site) => {
			_emit('siteUpdated', {
				site: site,
				internalSiteRowId: _props.siteMappingData?.InternalSiteRowId,
				reportImportId:
					_props.siteMappingData?.GroupedResult.BinResults[0].ReportImportId,
			});
			Close();
		});
}
//#endregion
</script>

<style></style>
