<style lang="scss" scoped>
:deep(thead tr th) {
	position: sticky;
	z-index: 1;
}

:deep(thead tr:last-child th) {
	top: 48px;
}

:deep(thead tr:first-child th) {
	top: 0;
}

:deep(tbody) {
	scroll-margin-top: 48px;
}
p {
	margin: 0;
	padding-bottom: 4px;
}
</style>
<template>
	<div>
		<q-dialog v-model="_modalVisible">
			<EditDetailsModalContent
				:processed="_processedSupplierInvoices"
				@update:processed="UpdateTable($event)"
				@close="Close()"
			></EditDetailsModalContent>
		</q-dialog>
		<q-dialog v-model="_modalVisibleBulkUpdate">
			<BulkUpdateModal
				:selected-items="_selected"
				@close="BulkClose()"
				:type="bulkUpdateType"
				@update:bulk="UpdateBulk($event)"
			>
			</BulkUpdateModal>
		</q-dialog>
		<q-table
			:selected="_selected"
			:rows-per-page-options="[0]"
			:filter="search"
			:columns="headers"
			:rows="items"
			:fixed-header="true"
			:loading="isLoading"
			:selected-rows-label="getSelectedString"
			@update:selected="_selected = $event"
			style="height: 80vh; position: relative; width: 100%"
			class="overflow-y-auto"
			virtual-scroll
			row-key="Id"
			selection="multiple"
			binary-state-sort
			return-object
			:table-header-style="{
				backgroundColor: $q.dark.mode ? 'var(--q-dark)' : 'var(--q-light)',
			}"
		>
			<template v-slot:body="props">
				<q-tr :props="props">
					<q-td body-selection="props">
						<q-checkbox v-model="props.selected" />
					</q-td>
					<q-td key="ProcessedSupplierInvoice.SupplierName" :props="props">
						<q-tooltip
							location="top"
							v-if="props.row.ProcessedSupplierInvoice.Supplier !== null"
						>
							<p class="text-weight-bold">
								{{ props.row.ProcessedSupplierInvoice.Supplier.SupplierName }}
							</p>
						</q-tooltip>
						<p class="text-weight-bold">
							{{
								props.row.ProcessedSupplierInvoice.SupplierName === 'Unknown' &&
								props.row.ProcessedSupplierInvoice.Supplier !== null
									? props.row.ProcessedSupplierInvoice.Supplier.SupplierName
									: props.row.ProcessedSupplierInvoice.SupplierName
							}}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.SupplierNameConfidence)"
							>{{ props.row.SupplierNameConfidence }}%</q-chip
						>
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{
								props.row.ProcessedSupplierInvoice.SageReference != null
									? props.row.ProcessedSupplierInvoice.SageReference.Reference
									: ''
							}}
						</p>
					</q-td>
					<q-td key="ProcessedSupplierInvoice.InvoiceNo" :props="props">
						<p class="text-weight-bold">
							{{ props.row.ProcessedSupplierInvoice.InvoiceNo }}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.InvoiceNoConfidence)"
						>
							{{ props.row.InvoiceNoConfidence }}%
						</q-chip>
					</q-td>
					<q-td key="ProcessedSupplierInvoice.InvoiceDate" :props="props">
						<p class="text-weight-bold">
							{{ FormatDate(props.row.ProcessedSupplierInvoice.InvoiceDate) }}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.InvoiceDateConfidence)"
						>
							{{ props.row.InvoiceDateConfidence }}%
						</q-chip>
					</q-td>
					<q-td>
						<q-tooltip
							location="top"
							v-if="props.row.ProcessedSupplierInvoice.Customer !== null"
						>
							{{ props.row.ProcessedSupplierInvoice.SiteAddress }}
							<q-td>
								<p>
									{{
										props.row.ProcessedSupplierInvoice.Customer?.CustomerName
									}}
								</p>
							</q-td>
						</q-tooltip>
						<p class="text-weight-bold">
							{{ props.row.ProcessedSupplierInvoice.Customer?.CustomerName }}
						</p>
						<div style="display: flex; align-items: center">
							<p style="flex: 1">
								{{ props.row.ProcessedSupplierInvoice.Customer?.AccountNumber }}
							</p>
							<div style="flex: 3">
								<q-chip
									class="text-weight-bold"
									:color="FormatPercentage(props.row.CustomerConfidence)"
								>
									{{ props.row.CustomerConfidence }}%
								</q-chip>
							</div>
						</div>
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{ formatToCurrency(props.row.ProcessedSupplierInvoice.Net) }}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.NetConfidence)"
						>
							{{ props.row.NetConfidence }}%
						</q-chip>
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{ formatToCurrency(props.row.ProcessedSupplierInvoice.VAT) }}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.VATConfidence)"
						>
							{{ props.row.VATConfidence }}%
						</q-chip>
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{ formatToCurrency(props.row.ProcessedSupplierInvoice.Total) }}
						</p>
						<q-chip
							class="text-weight-bold"
							:color="FormatPercentage(props.row.TotalConfidence)"
						>
							{{ props.row.TotalConfidence }}%
						</q-chip>
					</q-td>

					<q-td class="text-center">
						<q-icon
							:color="getColor(props.row.ProcessedSupplierInvoice.Approved)"
							size="md"
							:name="
								props.row.ProcessedSupplierInvoice.Approved
									? 'mdi-check'
									: 'mdi-close'
							"
						></q-icon>
						<!-- <q-chip
							:color="getColor(props.row.ProcessedSupplierInvoice.Approved)"
						>
							{{ props.row.ProcessedSupplierInvoice.Approved }}
						</q-chip> -->
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{
								props.row.ProcessedSupplierInvoice.SupplierInvoiceFile
									.SupplierSupplementaryInvoiceFiles.length
							}}
						</p>
					</q-td>
					<q-td class="text-center">
						<p class="text-weight-bold">
							{{
								props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.User !=
								null
									? props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.User
											.FullName
									: ''
							}}
						</p>
					</q-td>
					<!--ANCHOR: bug here-->
					<q-td key="view" :props="props" class="text-center">
						<q-btn
							flat
							dense
							round
							size="md"
							class="q-mx-sm"
							q-bind="props"
							@click="EditMatch(props)"
						>
							<q-icon
								color="secondary"
								name="mdi-pencil"
								size="sm"
								title="Edit"
							/>
						</q-btn>
						<q-btn
							flat
							dense
							round
							size="md"
							class="q-mx-sm"
							q-bind="props"
							:v-if="
								props.row.ProcessedSupplierInvoice.SupplierInvoiceFile.FilePath
							"
							@Click="DownloadInvoice(props.row.ProcessedSupplierInvoice.Id)"
							target="_blank"
						>
							<q-icon
								color="secondary"
								name="mdi-download"
								size="sm"
								title="Download"
							>
							</q-icon>
						</q-btn>
					</q-td>
					<q-menu touch-position context-menu>
						<q-list dense style="min-width: 100px">
							<q-item
								style="padding: 16px"
								clickable
								v-close-popup
								:disable="selectedItems.length < 1"
								@click="EmitApprove"
							>
								<q-item-section
									>Approve
									{{ '(' + selectedItems.length + ')' }}</q-item-section
								>
							</q-item>
							<q-item
								style="padding: 16px"
								clickable
								v-close-popup
								:disable="selectedItems.length < 1"
								@click="EmitArchive"
							>
								<q-item-section
									>Archive
									{{ '(' + selectedItems.length + ')' }}</q-item-section
								>
							</q-item>
							<q-item
								style="padding: 16px"
								clickable
								v-close-popup
								:disable="selectedItems.length < 1"
								@click="SelectedBulkType(BulkTypeEnum.Supplier)"
							>
								<q-item-section
									>Update Supplier
									{{ '(' + selectedItems.length + ')' }}</q-item-section
								>
							</q-item>
							<q-item
								style="padding: 16px"
								clickable
								v-close-popup
								:disable="selectedItems.length < 1"
								@click="SelectedBulkType(BulkTypeEnum.Customer)"
							>
								<q-item-section
									>Update Customer
									{{ '(' + selectedItems.length + ')' }}</q-item-section
								>
							</q-item>
							<q-item
								style="padding: 16px"
								clickable
								v-close-popup
								:disable="selectedItems.length < 1"
								@click="SelectedBulkType(BulkTypeEnum.DateRange)"
							>
								<q-item-section
									>Update Date Range
									{{ '(' + selectedItems.length + ')' }}</q-item-section
								>
							</q-item>
						</q-list>
					</q-menu>
				</q-tr>
			</template>
			<template v-slot:header-selection="scope">
				<q-checkbox v-model="scope.selected" />
			</template>

			<template v-slot:no-data>
				<div class="full-width row flex-center q-gutter-sm">
					<q-icon size="2em" name="mdi-alert" />
					<span>No data available</span>
				</div>
			</template>

			<template v-slot:pagination> </template>
		</q-table>
	</div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import EditDetailsModalContent from './Modals/EditDetailsModalContent.vue';
import BulkUpdateModal from './Modals/BulkUpdateModal.vue';
import { BaseService } from '@/services/BaseService';
import { defineProps, defineEmits } from 'vue';
import moment from 'moment';
import { useQuasar } from 'quasar';
import { customerStore } from '@/store/customer';
const _modalVisible = ref(false);
const _modalVisibleBulkUpdate = ref(false);
const _processedSupplierInvoices = ref();
const _customersStore = customerStore();
const _service = new BaseService();
const $q = useQuasar();
const enum BulkTypeEnum {
	Customer = 'Customer',
	Supplier = 'Supplier',
	DateRange = 'DateRange',
}
const bulkUpdateType = ref(0);
function SelectedBulkType(value: BulkTypeEnum) {
	if (value === BulkTypeEnum.Customer) {
		bulkUpdateType.value = 1;
		_modalVisibleBulkUpdate.value = true;
	} else if (value === BulkTypeEnum.Supplier) {
		bulkUpdateType.value = 2;
		_modalVisibleBulkUpdate.value = true;
	} else {
		bulkUpdateType.value = 3;
		_modalVisibleBulkUpdate.value = true;
	}
}

const props = defineProps({
	items: Array,
	headers: Array,
	itemKey: String,
	itemsPerPage: Number,
	isLoading: { type: Boolean, default: false },
	search: String,
	selectedItems: Array,
});

const _selected = computed({
	get() {
		return props.selectedItems || [];
	},
	set(val) {
		emit('update:selectedItems', val);
	},
});

const emit = defineEmits([
	'updateTable',
	'updateBulk',
	'update:search',
	'update:selectedItems',
	'update:approve',
	'update:archive',
]);

function EmitApprove() {
	emit('update:approve');
}
function EmitArchive() {
	emit('update:archive');
}

function Close() {
	_modalVisible.value = false;
	_selected.value = [];
}

function BulkClose() {
	_modalVisibleBulkUpdate.value = false;
	_selected.value = [];
}
function UpdateTable(event: any) {
	if (!event) {
		return;
	}
	emit('updateTable', event);
	Close();
}

function UpdateBulk(event: any) {
	if (!event) {
		return;
	}
	emit('updateBulk', event);
	BulkClose();
}

function EditMatch(item: any) {
	_processedSupplierInvoices.value = item.row.ProcessedSupplierInvoice;

	// Deselecting rows and adding current one to selected
	_selected.value = [];
	_selected.value.push(item.row.ProcessedSupplierInvoice);

	if (!_customersStore.customers.length) {
		_customersStore.updateCustomersList();
	}

	_modalVisible.value = true;
}

function getSelectedString() {
	return _selected.value.length === 0
		? ''
		: `${_selected.value.length} record${
				_selected.value.length > 1 ? 's' : ''
		  };`;
}

function DownloadInvoice(id: number) {
	_service
		.Get<any>(
			'Suppliers/ProcessedPurchaseInvoices/Download?processedSupplierInvoiceId=' +
				`${id}`
		)
		.then((res: string) => {
			window.open(res, '_blank');
		});
}

function getColor(approved: boolean) {
	if (approved === true) return 'green';
	else return 'red';
}

function FormatDate(date: string) {
	if (!date) {
		return 'Not Found';
	} else {
		return moment(date).format('DD/MM/YYYY');
	}
}

function FormatPercentage(number: number) {
	if (number >= 90) {
		return 'green';
	} else if (number < 90 && number >= 80) {
		return 'warning';
	} else if (number < 80) {
		return 'red';
	}
}
const formatter = new Intl.NumberFormat('en-GB', {
	style: 'currency',
	currency: 'GBP',
	minimumFractionDigits: 2,
});
function formatToCurrency(number: number) {
	return formatter.format(number);
}
</script>
