<template>
	<GZDraggableModal ref="scheduleMappingModal">
		<template #header>
			<q-toolbar class="bg-accent text-white">
				<q-toolbar-title class="text-h5"
					>{{
						_selectedBin ? _selectedBin.ServiceDescription + ' -' : ''
					}}
					Schedule Select</q-toolbar-title
				>
			</q-toolbar>
		</template>
		<template #body>
			<q-card style="min-width: 500px; min-height: 370px">
				<q-card-section>
					<div class="row">
						<div class="col">
							<div class="row">
								<q-select
									v-model="_selectedBin"
									:options="_binsOnSite"
									:loading="_isLoading"
									:option-label="'ServiceDescription'"
									class="full-width"
									label="Selected Bin"
									dense
									outlined
								></q-select>
							</div>
							<div class="q-pa-md" v-if="_selectedBin">
								<div class="row">
									<div class="col-1"></div>
									<div class="col">Date</div>
									<div class="col">Weight</div>
									<div class="col">Status</div>
								</div>
								<div
									class="row q-pt-md"
									v-for="schedule in _selectedBin.Schedules"
									:key="schedule.ScheduleId"
								>
									<div
										class="col-1"
										@mouseover="schedule.IsVisible = true"
										@mouseleave="schedule.IsVisible = false"
									>
										<q-icon
											name="mdi-check"
											style="background-color: rgba(0, 0, 0, 0.05)"
											@click="AddScheduleToList(schedule.ScheduleId)"
											:style="{ color: GetScheduleColor(schedule) }"
										/>
									</div>
									<div class="col">{{ schedule.ScheduleDate?.Display }}</div>
									<div class="col">{{ schedule.ActualWeightKg.Name }}</div>
									<div class="col">{{ schedule.ActualLiftStatus.Name }}</div>
								</div>
							</div>
							<div
								v-else
								style="
									min-height: 238px;
									align-content: center;
									text-align: center;
								"
							>
								<span style="color: gray"
									>Select a bin from dropdown above</span
								>
							</div>
						</div>
					</div>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn @click="Close">Cancel</q-btn>
					<q-btn color="primary" @click="PostSchedule">Save</q-btn>
				</q-card-actions>
			</q-card>
		</template>
	</GZDraggableModal>
</template>

<script setup lang="ts">
import { BaseService } from '@/services/BaseService';
import { GZDraggableModal } from '@gz/quasar-components-vue3';
import {
	DataMatchingBin,
	DatamatchingGroupedResultDTO,
	DataMatchingScheduleSelectDTO,
	DataMatchingSelectedSchedulesDto,
} from 'greenzonegateway.classes/lib/classes';
import {
	ref,
	defineEmits,
	defineExpose,
	defineProps,
	nextTick,
	onMounted,
} from 'vue';

//defines
defineExpose({ Close, Open });
const _emit = defineEmits(['binUpdated']);
const _props = defineProps({
	reportImportId: String,
	InternalSiteRowId: Number,
});
const _service = new BaseService();

//refs
const scheduleMappingModal = ref<typeof GZDraggableModal>();

//flags
const _isLoading = ref(false);

//vars
const _selectedBin = ref<DataMatchingBin>();
const _binsOnSite = ref<DataMatchingBin[]>([]);
const _selectedSchedules = ref<number[]>([]);

//#region Exposed functions
function Close() {
	Reset();
	scheduleMappingModal.value?.hide();
}

function Open() {
	scheduleMappingModal.value?.show();
	nextTick(() => GetSchedule());
}
//#endregion

function Reset() {
	_selectedBin.value = undefined;
	_binsOnSite.value = [];
}

function GetScheduleColor(schedule: any) {
	if (schedule.IsSelected) {
		return 'green';
	}
	if (schedule.IsVisible) {
		return 'gray';
	}
	return 'rgba(0,0,0,0)';
}

function AddScheduleToList(ScheduleId: number) {
	if (!_selectedBin.value) {
		return;
	}
	_selectedBin.value.Schedules.forEach((schedule) => {
		if (schedule.ScheduleId === ScheduleId) {
			schedule.IsSelected = schedule.IsSelected ? false : true;
		} else {
			schedule.IsSelected = false;
		}
	});
	RefreshSelectedSchedules();
}

function RefreshSelectedSchedules() {
	_selectedSchedules.value = [];
	_binsOnSite.value.forEach((bin) => {
		bin.Schedules.forEach((schedule) => {
			if (schedule.IsSelected) {
				_selectedSchedules.value.push(schedule.ScheduleId);
			}
		});
	});
}

function PrimeSelectedSchedules() {
	_binsOnSite.value.forEach((bin) => {
		bin.Schedules.forEach((schedule) => {
			if (schedule?.IsSelected) {
				_selectedSchedules.value.push(schedule.ScheduleId);
			}
		});
	});
}

//#region API Calls
function GetSchedule() {
	if (!_props.reportImportId) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<DataMatchingScheduleSelectDTO>(
			`DataMatchingWeights/MatchedSchedules?reportImportId=${_props.reportImportId}`
		)
		.then((schedules) => {
			_binsOnSite.value = schedules.BinsOnSite;
			if (_binsOnSite.value.length) {
				_selectedBin.value = _binsOnSite.value[0];
			}
			PrimeSelectedSchedules();
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostSchedule() {
	const _data: DataMatchingSelectedSchedulesDto = {
		ReportImportId: Number(_props.reportImportId),
		SelectedSchedules: _selectedSchedules.value,
	};
	_service
		.Post<DataMatchingSelectedSchedulesDto>(
			'DataMatchingWeights/UpdateMatchedSchedules',
			_data
		)
		.then((site: DatamatchingGroupedResultDTO) => {
			_emit('binUpdated', {
				site: site,
				internalSiteRowId: _props.InternalSiteRowId,
				reportImportId: _props.reportImportId,
			});
			Close();
		});
}
//#endregion
</script>

<style></style>
