<style lang="scss" scoped>
:deep(.q-table__top),
:deep(.q-table__bottom),
:deep(thead tr:first-child th) {
	background-color: var(--q-primary) !important;
}

:deep(thead tr th) {
	position: sticky;
	z-index: 1;
}

:deep(thead tr:last-child th) {
	top: 48px;
}

:deep(thead tr:first-child th) {
	top: 0;
}

:deep(tbody) {
	scroll-margin-top: 48px;
}
</style>
<template>
	<div class="home internal-container" style="width: 100%; height: 100%">
		<q-layout class="header-bar" view="hHh Lpr fff">
			<q-toolbar class="bg-accent text-white q-pt-sm">
				<q-toolbar-title style="padding: 12px !important"
					>Supplier Defaults</q-toolbar-title
				>
			</q-toolbar>

			<q-page-container>
				<div class="pa-4 layout-container">
					<div class="row">
						<div class="col-6 q-pa-md">
							<q-card class="p-0" style="height: 100%">
								<q-card-section class="bg-accent text-white">
									<div class="text-h6">New Supplier Defaults</div>
								</q-card-section>
								<div class="row q-pa-md">
									<div class="col-12">
										<q-form
											@submit="PostSupplierDefaults()"
											@reset="OnReset()"
											class="q-gutter-md"
										>
											<div style="padding-left: 4px">
												<q-generic-select
													:items="_suppliersStore.getSuppliers"
													name="SupplierName"
													labeling="Supplier"
													v-model="_modelValue.SupplierId"
													second-label="AccountNumber"
													:rules="rules"
												></q-generic-select>
												<q-generic-select
													:items="_taxCodes"
													name="Name"
													labeling="Tax Codes"
													v-model="_modelValue.TaxCodeId"
													:rules="rules"
												></q-generic-select>

												<q-generic-select
													:items="_paymentTypeArray"
													name="Name"
													labeling="Payment Type"
													v-model="_modelValue.PaymentType"
													:rules="rules"
												></q-generic-select>
											</div>
											<q-separator />
											<div>
												<q-btn label="Submit" type="submit" color="primary" />
												<q-btn
													label="Reset"
													type="reset"
													color="primary"
													flat
													:loading="_isLoading"
													class="q-ml-sm"
												/>
											</div>
										</q-form>
									</div>
								</div>
							</q-card>
						</div>
						<div class="col-6 q-pa-md">
							<q-card style="height: 100%">
								<q-table
									:columns="_headers"
									:rows="_supplierInvoiceDefaults"
									:rows-per-page-options="[0]"
									:virtual-scroll-sticky-size-start="20"
									item-value="name"
									class="elevation-1"
									:loading="_isTableLoading"
									style="height: inherit"
								>
									<template v-slot:body="props">
										<q-tr :props="props">
											<q-td key="SupplierName">{{
												props.row.TaxCode.Name
											}}</q-td>
											<q-td key="Name">{{
												props.row.Supplier.SupplierName
											}}</q-td>
											<q-td>{{ PaymentTypeEnum[props.row.PaymentType] }}</q-td>
										</q-tr>
									</template>
									<template v-slot:no-data>
										<div class="full-width row flex-center q-gutter-sm">
											<q-icon size="2em" name="mdi-alert" />
											<span>No data available</span>
										</div>
									</template>
									<template v-slot:pagination> </template>
								</q-table>
							</q-card>
						</div>
					</div>
				</div>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { BaseService } from '@/services/BaseService';
import { supplierStore } from '@/store/suppliers';
import QGenericSelect from '@/components/QGenericSelect.vue';
import { ITaxCode } from 'greenzonegateway.classes/lib/classes/Generic';
import {
	ISupplierDefaults,
	PaymentTypeEnum,
} from 'greenzonegateway.classes/lib/classes/dataMatching';
const rules = ref([(val: any) => val != null || 'This field is required']);

const _paymentTypeArray: any = Object.keys(PaymentTypeEnum)
	.filter((v) => isNaN(Number(v)))
	.map((Name: any) => {
		let val = PaymentTypeEnum[Name as keyof typeof PaymentTypeEnum];
		Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

		return {
			Value: val,
			Name,
		};
	});

const _suppliersStore = supplierStore();
const _service = new BaseService();
const _supplierInvoiceDefaults = ref([]);
const _isLoading = ref<boolean>(false);
const _isTableLoading = ref(false);
const _selectedPaymentType: any = ref(null);
const _taxCodeId: any = ref(null);
const _supplierId: any = ref(null);
const _headers = [
	{
		label: 'Tax Code',
		align: 'left',
		sortable: false,
		field: 'TaxCode.Name',
	},
	{ label: 'Supplier', align: 'left', field: 'Supplier.SupplierName' },
	{ label: 'Payment Type', align: 'left', field: 'PaymentType' },
];

const _taxCodes = ref([] as ITaxCode[]);
const _supplierDefaults = ref<ISupplierDefaults>({
	TaxCodeId: _taxCodeId,
	SupplierId: _supplierId,
	PaymentType: _selectedPaymentType,
});
const _modelValue = ref<ISupplierDefaults>({
	TaxCodeId: _taxCodeId,
	SupplierId: _supplierId,
	PaymentType: _selectedPaymentType,
});
function GetSupplierDefaults() {
	_isTableLoading.value = true;
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/SupplierDefaults')
		.then((mapping: any) => {
			_supplierInvoiceDefaults.value = mapping;
			_isTableLoading.value = false;
		});
}
function getTaxCodes() {
	_service
		.Get<any>('Suppliers/ProcessedPurchaseInvoices/TaxCodes')
		.then((taxcodes: any) => {
			_taxCodes.value = taxcodes;
		});
}

async function PostSupplierDefaults(): Promise<void> {
	_isLoading.value = true;
	_supplierDefaults.value.PaymentType = _modelValue.value.PaymentType.Value;
	_supplierDefaults.value.SupplierId = _modelValue.value.SupplierId.SupplierId;
	_supplierDefaults.value.TaxCodeId = _modelValue.value.TaxCodeId.Id;
	_service
		.Post<any>(
			'Suppliers/ProcessedPurchaseInvoices/SupplierDefaults/Create',
			_supplierDefaults.value
		)
		.then(() => {
			_selectedPaymentType.value = null;
			_taxCodeId.value = null;
			_supplierId.value = null;

			GetSupplierDefaults();
		})
		.finally(() => {
			_isLoading.value = false;
		});
}
function OnReset() {
	_modelValue.value.TaxCodeId = null;
	_modelValue.value.PaymentType = null;
	_modelValue.value.SupplierId = null;
}
onMounted(() => {
	GetSupplierDefaults();
	getTaxCodes();
});
</script>
