import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"display":"contents"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "col-8 q-pl-md",
  style: {"align-content":"center"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 1,
  class: "col-8 q-pl-md",
  style: {"align-content":"center"}
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { style: {"background-color":"rgba(0, 0, 0, 0.1)"} }
const _hoisted_11 = {
  class: "col q-pl-md",
  style: {"align-content":"center"}
}
const _hoisted_12 = {
  class: "col q-pl-md",
  style: {"align-content":"center"}
}
const _hoisted_13 = {
  class: "row",
  style: {"align-items":"center"}
}
const _hoisted_14 = {
  class: "col-6",
  style: {"align-content":"center"}
}
const _hoisted_15 = {
  class: "col",
  style: {"align-content":"center"}
}
const _hoisted_16 = {
  key: 0,
  class: "col",
  style: {"align-content":"center"}
}
const _hoisted_17 = {
  key: 1,
  class: "col",
  style: {"align-content":"center"}
}

import { watch, ref } from 'vue';
import {
	DataMatchingBinResultDTO,
	DatamatchingGroupedResultDTO,
	DataMatchingProblemEnum,
	OnSiteCountDto,
} from 'greenzonegateway.classes/lib/classes';
import GZIndicator from '@/components/GZIndicator.vue';
import { BaseService } from '@/services/BaseService';

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number | null | undefined;
	expand: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsBinRow',
  props: {
    settings: {},
    rowIndex: {},
    row: {},
    binResult: {}
  },
  emits: [
	'showModalScheduleMapping',
	'showModalBinMappings',
	'clearSelections',
],
  setup(__props: any, { emit: __emit }) {

const _service = new BaseService();

const _isLoadingOnSite = ref(false);

const _props = __props;
const _emit = __emit;

function GetBinRowColor(problemsLength: number, weightEnteredDate: string) {
	let colorOut = '';
	weightEnteredDate ? (colorOut = 'var(--q-positive)') : null;
	problemsLength > 0 && !weightEnteredDate
		? (colorOut = 'var(--q-negative)')
		: null;
	return colorOut;
}

function ScheduleSelectTooltipText(binResult: DataMatchingBinResultDTO) {
	let outputString = '';

	!binResult.onSite ? (outputString = ' - DISABLED: No schedules found') : '';
	binResult.WeightEntered?.Display
		? (outputString = ' - DISABLED: Bin saved')
		: '';

	return outputString;
}

function TranslateProblemEnum(enumNum: number) {
	if (!enumNum) {
		return;
	}
	let strOut = DataMatchingProblemEnum[enumNum].split(/(?=[A-Z])/).join(' ');
	return strOut.charAt(0) + strOut.substring(1).toLowerCase();
}

function ShowBinMappingsModal(
	rowData: DatamatchingGroupedResultDTOInternal,
	binResult: DataMatchingBinResultDTO,
	index: number
) {
	_emit('showModalBinMappings', { rowData, binResult, index });
}

function ShowModalScheduleMapping(
	rowData: DatamatchingGroupedResultDTO,
	bin: DataMatchingBinResultDTO
) {
	_emit('showModalScheduleMapping', { rowData, bin });
}

function LoadOnSiteData(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	_isLoadingOnSite.value = true;
	_service
		.Get<OnSiteCountDto[]>(
			`/DataMatchingWeights/OnSiteCount?reportImportId=${rowData.BinResults[0].ReportImportId}`
		)
		.then((response) => {
			if (!rowData.BinResults) {
				return;
			}
			for (let i = 0; i < response.length; i++) {
				rowData.BinResults.forEach((Bin) => {
					if (!Bin.ReportImportId) {
						return;
					}
					Bin.onSite = response.find(
						(responseItem) => responseItem.ReportImportId === Bin.ReportImportId
					).QuantityOnSite;
				});
			}
		})
		.finally(() => {
			_isLoadingOnSite.value = false;
		});
}

watch(
	() => _props.row.expand,
	() => {
		if (_props.row.expand) {
			LoadOnSiteData(_props.row);
		}
	},
	{ deep: true }
);

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner_oval = _resolveComponent("q-spinner-oval")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_td, null, {
      default: _withCtx(() => [
        (
					_ctx.binResult.WeightEntered &&
					GetBinRowColor(
						_ctx.binResult.Problems.length,
						_ctx.binResult.WeightEntered.Display
					)
				)
          ? (_openBlock(), _createBlock(GZIndicator, {
              key: 0,
              color: 
					GetBinRowColor(
						_ctx.binResult.Problems.length,
						_ctx.binResult.WeightEntered.Display
					)
				
            }, {
              default: _withCtx(() => [
                (
						!_ctx.binResult.WeightEntered.Display || _ctx.binResult.Problems.length > 0
					)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.binResult.WeightEntered.Display)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Bin not yet commited "))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.binResult.Problems, (problem) => {
                          return (_openBlock(), _createElementBlock("div", { key: problem }, _toDisplayString(TranslateProblemEnum(problem)), 1))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["color"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "row",
          style: _normalizeStyle({ height: _ctx.settings.slimItems ? '24px' : '48px' })
        }, [
          (!_ctx.settings.slimItems)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (
							!_ctx.binResult.WeightEntered?.Display || _ctx.binResult.Problems.length > 0
						)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.binResult.WeightEntered?.Display)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Bin not yet commited "))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.binResult.Problems, (problem) => {
                          return (_openBlock(), _createElementBlock("div", { key: problem }, _toDisplayString(TranslateProblemEnum(problem)), 1))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.binResult.SupplierServiceDescriptionDisplayLine2), 1)
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.binResult.SupplierServiceDescriptionDisplayLine1), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (
							!_ctx.binResult.WeightEntered?.Display || _ctx.binResult.Problems.length > 0
						)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.binResult.WeightEntered?.Display)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Bin not yet commited "))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.binResult.Problems, (problem) => {
                          return (_openBlock(), _createElementBlock("div", { key: problem }, _toDisplayString(TranslateProblemEnum(problem)), 1))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.binResult.SupplierServiceDescriptionDisplayLine2) + " ", 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.binResult.SupplierServiceDescriptionDisplayLine1), 1)
                ])
              ])),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.binResult.SupplierServiceDate?.Display), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.binResult.SupplierActualWeightKgDisplay), 1)
        ], 4)
      ]),
      _: 1
    }),
    _createVNode(_component_q_td, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_btn, {
          icon: "mdi-cup-outline",
          size: _ctx.settings.slimItems ? `sm` : `md`,
          round: "",
          flat: "",
          disable: !!_ctx.binResult.WeightEntered?.Display,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (ShowBinMappingsModal(_ctx.row, _ctx.binResult, _ctx.rowIndex)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, {
              class: "bg-accent",
              anchor: "top middle"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, "Bin Mapping" + _toDisplayString(!!_ctx.binResult.WeightEntered?.Display ? ` - DISABLED: Bin saved` : ``), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["size", "disable"]),
        _createVNode(_component_q_btn, {
          icon: "mdi-file-document-outline",
          size: _ctx.settings.slimItems ? `sm` : `md`,
          disable: !!_ctx.binResult.WeightEntered?.Display || !_ctx.binResult.onSite,
          round: "",
          flat: "",
          class: "q-ml-md",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (ShowModalScheduleMapping(_ctx.row, _ctx.binResult)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, {
              class: "bg-accent",
              anchor: "top middle"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, "Schedule Select" + _toDisplayString(ScheduleSelectTooltipText(_ctx.binResult)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["size", "disable"])
      ]),
      _: 1
    }),
    _createVNode(_component_q_td, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.binResult.MatchedServiceDescription), 1),
          _createElementVNode("div", _hoisted_15, " Quantity: " + _toDisplayString(_ctx.binResult.MatchedQuantity), 1),
          (!_isLoadingOnSite.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " On Site: " + _toDisplayString(_ctx.binResult.onSite || 'N/A'), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[2] || (_cache[2] = _createTextVNode(" On Site: ")),
                _createVNode(_component_q_spinner_oval, { style: {"margin-bottom":"3px"} })
              ]))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_td)
  ]))
}
}

})