import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row d-flex justify-around" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6 q-px-sm" }
const _hoisted_4 = { class: "col-6 q-px-sm" }
const _hoisted_5 = { class: "col-6 q-px-sm" }
const _hoisted_6 = { class: "col-6 q-px-sm" }
const _hoisted_7 = { class: "col-6 q-px-sm" }
const _hoisted_8 = { class: "col-6 q-px-sm" }
const _hoisted_9 = { class: "row items-center justify-end" }
const _hoisted_10 = { class: "col-6 q-px-sm" }
const _hoisted_11 = { class: "row items-center justify-end" }
const _hoisted_12 = { class: "col-6 q-px-sm" }
const _hoisted_13 = { class: "row items-center justify-end" }
const _hoisted_14 = { class: "col-12 q-pb-sm" }
const _hoisted_15 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_16 = { class: "col-7" }
const _hoisted_17 = { class: "col-5" }
const _hoisted_18 = {
  key: 0,
  class: "col-12 q-px-sm"
}
const _hoisted_19 = { class: "col-12 q-pb-sm" }
const _hoisted_20 = {
  key: 1,
  class: "col-12 q-px-sm"
}
const _hoisted_21 = { class: "col-12 q-pb-sm" }

import { ref, computed, onMounted } from 'vue';
import debounce from 'quasar/src/utils/debounce/debounce.js';import date from 'quasar/src/utils/date/date.js';;
import { DisputeReasonsEnum } from 'greenzonegateway.classes/lib/classes/enums';
import { BaseService } from '@/services/BaseService';
import { customerStore } from '@/store/customer';
import { supplierStore } from '@/store/suppliers';
import { GZGenericSelect } from '@gz/quasar-components-vue3';
import { ICustomer, ISite } from 'greenzonegateway.classes/lib/classes';
import { ISageReference } from 'greenzonegateway.classes/lib/classes/Generic';
import moment = require('moment');

export default /*@__PURE__*/_defineComponent({
  __name: 'EditDetailsModalContent',
  props: {
	processed: Object,
	processedInvoiceID: Number,
},
  emits: ['close', 'update:processed'],
  setup(__props, { emit: __emit }) {

const _nameRules = [(v: string) => !!v || 'Field is required'];
const _supplierNameRules = [
	(v: string) => v != null || 'This field is required',
];

const _customersStore = customerStore();
const _suppliersStore = supplierStore();
const _service = new BaseService();
const _filteredCustomers = ref<ICustomer[]>([]);
const _site = ref<ISite[]>([]);
const _postcode = ref('');
const _updatedDate = ref('');
const _updatedStartDate = ref('');
const _updatedEndDate = ref('');
const _isLoading = ref(false);
const _disabled = ref(false);
const _disputed = ref(false);
const editModalForm = ref();
const _disputedCode = ref(null);
const _sageReferences = ref<ISageReference[]>([]);
const _emit = __emit;

const props = __props;

const _disputeReasonArray: any = Object.keys(DisputeReasonsEnum)
	.filter((v) => isNaN(Number(v)))
	.map((Name: any) => {
		var val = DisputeReasonsEnum[Name as keyof typeof DisputeReasonsEnum];
		Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

		return {
			Value: val,
			Name,
		};
	});

const _selectProcessed: any = ref(Object.assign({}, props.processed));
const _delayedGetSites = debounce(GetPostCodes, 500);
const _delaySageReferences = debounce(GetSageReferences, 500);
const _updateDateRange = updateDateModels;
const _customers = computed({
	get() {
		return _filteredCustomers.value.length > 0
			? _filteredCustomers.value
			: _customersStore.customers;
	},
	set(val) {
		_customersStore.customers = val;
	},
});

onMounted(async () => {
	_updatedDate.value = FormatDate(
		_selectProcessed.value.InvoiceDate
	).toString();

	_updatedStartDate.value = FormatDate(
		_selectProcessed.value.StartDate
	).toString();

	_updatedEndDate.value = FormatDate(_selectProcessed.value.EndDate).toString();

	if (_selectProcessed.value.Customer?.CustomerId === 0) {
		_selectProcessed.value.Customer = 0;
	}
});

function FormatDate(date: string) {
	if (!date) {
		return 'Not Found';
	} else {
		return moment(date).format('DD/MM/YYYY');
	}
}

const fromToDateRule = (value: string): boolean | string => {
	if (!_updatedStartDate.value || !_updatedEndDate.value) {
		return false || 'Date Required';
	}

	let from = date.extractDate(_updatedStartDate.value, 'DD/MM/YYYY');
	let to = date.extractDate(_updatedEndDate.value, 'DD/MM/YYYY');

	return from <= to || 'From Date must be earlier than or equal to To Date';
};

function updateDateModels() {
	_selectProcessed.value.InvoiceDate =
		moment(_updatedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00';

	_updatedStartDate.value = moment(_selectProcessed.value.InvoiceDate)
		.clone()
		.startOf('month')
		.format('DD/MM/YYYY')
		.toString();
	_updatedEndDate.value = moment(_selectProcessed.value.InvoiceDate)
		.clone()
		.endOf('month')
		.format('DD/MM/YYYY')
		.toString();
}

async function GetPostCodes() {
	_customersStore.isLoading = true;
	_service
		.Get<any>('/Customers/AI/GetPostCodes?postcode=' + _postcode.value)
		.then((postcode: any) => {
			_site.value = postcode;
			let list = [];
			for (let i = 0; i < _site.value.length; i++) {
				list.push(_site.value[i].Customer);
			}

			_filteredCustomers.value = [...new Set(list)] as ICustomer[];
		})
		.finally(() => {
			_customersStore.isLoading = false;
		});
}

async function GetSageReferences() {
	if (_selectProcessed.value.Supplier !== null) {
		_sageReferences.value = [];
		_selectProcessed.value.SageReference = null;
		_service
			.Get<any>(
				'/Suppliers/List/SageReferences?supplierId=' +
					_selectProcessed.value.Supplier.SupplierId
			)
			.then((response: any) => {
				let list = [];
				list = response;
				for (let i = 0; i < list.length; i++) {
					_sageReferences.value.push(list[i].SageReference);
				}
			});
	}
}

async function Submit(): Promise<void> {
	_isLoading.value = true;
	_selectProcessed.value.InvoiceDate =
		moment(_updatedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00';
	_selectProcessed.value.StartDate =
		moment(_updatedStartDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
		'T00:00:00';
	_selectProcessed.value.EndDate =
		moment(_updatedEndDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') +
		'T00:00:00';
	if (_selectProcessed.value.DisputeCode !== null) {
		for (let i = 0; i < _selectProcessed.value.DisputeCode.length; i++) {
			_disputedCode.value += _selectProcessed.value.DisputeCode[i].Value;
		}
		_selectProcessed.value.Approved = true;
	}

	editModalForm.value.validate().then((success: any) => {
		if (success) {
			_service
				.Patch<any>('Suppliers/ProcessedPurchaseInvoices/Update', {
					processedInvoiceIds: [_selectProcessed.value.Id],
					operations: [
						{
							op: 'replace',
							path: '/CustomerId',
							value: _selectProcessed.value.Customer.CustomerId ?? 0,
						},
						{
							op: 'replace',
							path: '/SupplierId',
							value:
								_selectProcessed.value.Supplier != null
									? _selectProcessed.value.Supplier.SupplierId
									: null,
						},
						{
							op: 'replace',
							path: '/Archived',
							value: _selectProcessed.value.Archived,
						},
						{
							op: 'replace',
							path: '/Approved',
							value: _selectProcessed.value.Approved,
						},
						{
							op: 'replace',
							path: '/InvoiceNo',
							value: _selectProcessed.value.InvoiceNo,
						},
						{
							op: 'replace',
							path: '/InvoiceDate',
							value: _selectProcessed.value.InvoiceDate,
						},
						{
							op: 'replace',
							path: '/StartDate',
							value: _selectProcessed.value.StartDate,
						},
						{
							op: 'replace',
							path: '/EndDate',
							value: _selectProcessed.value.EndDate,
						},
						{
							op: 'replace',
							path: '/Net',
							value: _selectProcessed.value.Net,
						},
						{
							op: 'replace',
							path: '/VAT',
							value: _selectProcessed.value.VAT,
						},
						{
							op: 'replace',
							path: '/Total',
							value: _selectProcessed.value.Total,
						},
						{
							op: 'replace',
							path: '/DisputeCode',
							value: _disputedCode.value,
						},
						{
							op: 'replace',
							path: '/DisputeReason',
							value: _selectProcessed.value.DisputeReason,
						},
						{
							op: 'replace',
							path: '/SageReferenceId',
							value:
								_selectProcessed.value.SageReference != null
									? _selectProcessed.value.SageReference.Id
									: null,
						},
					],
				})
				.then(() => {
					_emit('update:processed', _selectProcessed.value);
				})
				.finally(() => {
					_isLoading.value = false;
				});
		} else {
			_isLoading.value = false;
		}
	});
}

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"width":"800px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "bg-accent text-white" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, { class: "text-h5" }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode("Edit Details")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_form, {
        ref_key: "editModalForm",
        ref: editModalForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", null, _toDisplayString(_selectProcessed.value.SiteAddress), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_q_input, {
                      label: "Supplier Name",
                      outlined: "",
                      eager: "true",
                      modelValue: _selectProcessed.value.SupplierName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_selectProcessed.value.SupplierName) = $event)),
                      readonly: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_q_input, {
                      label: "Invoice No",
                      outlined: "",
                      rules: _nameRules,
                      modelValue: _selectProcessed.value.InvoiceNo,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_selectProcessed.value.InvoiceNo) = $event)),
                      readonly: _disabled.value
                    }, null, 8, ["modelValue", "readonly"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_input, {
                      label: "Net",
                      outlined: "",
                      required: "",
                      disabled: "",
                      rules: _nameRules,
                      modelValue: _selectProcessed.value.Net,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_selectProcessed.value.Net) = $event)),
                      readonly: _disabled.value
                    }, null, 8, ["modelValue", "readonly"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_q_input, {
                      label: "VAT",
                      outlined: "",
                      required: "",
                      rules: _nameRules,
                      modelValue: _selectProcessed.value.VAT,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_selectProcessed.value.VAT) = $event)),
                      readonly: _disabled.value
                    }, null, 8, ["modelValue", "readonly"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_q_input, {
                      label: "Total",
                      outlined: "",
                      required: "",
                      disabled: _disabled.value,
                      rules: _nameRules,
                      modelValue: _selectProcessed.value.Total,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_selectProcessed.value.Total) = $event)),
                      readonly: _disabled.value
                    }, null, 8, ["disabled", "modelValue", "readonly"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_q_input, {
                      label: "Invoice Date",
                      modelValue: _updatedDate.value,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_updatedDate).value = $event)),
                      readonly: _disabled.value,
                      rules: _nameRules,
                      outlined: ""
                    }, {
                      append: _withCtx(() => [
                        (!_disabled.value)
                          ? (_openBlock(), _createBlock(_component_q_icon, {
                              key: 0,
                              name: "mdi-calendar-range",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: _updatedDate.value,
                                      "onUpdate:modelValue": [
                                        _cache[5] || (_cache[5] = ($event: any) => ((_updatedDate).value = $event)),
                                        _unref(_updateDateRange)
                                      ],
                                      mask: "DD/MM/YYYY"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_9, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: "Close",
                                            color: "primary",
                                            flat: ""
                                          }, null, 512), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "readonly"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_q_input, {
                      label: "From Date",
                      modelValue: _updatedStartDate.value,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_updatedStartDate).value = $event)),
                      readonly: _disabled.value,
                      rules: [fromToDateRule],
                      outlined: ""
                    }, {
                      append: _withCtx(() => [
                        (!_disabled.value)
                          ? (_openBlock(), _createBlock(_component_q_icon, {
                              key: 0,
                              name: "mdi-calendar-range",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: _updatedStartDate.value,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_updatedStartDate).value = $event)),
                                      mask: "DD/MM/YYYY",
                                      title: "Date"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_11, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: "Close",
                                            color: "primary",
                                            flat: ""
                                          }, null, 512), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "readonly", "rules"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_q_input, {
                      label: "To Date",
                      modelValue: _updatedEndDate.value,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_updatedEndDate).value = $event)),
                      readonly: _disabled.value,
                      rules: [fromToDateRule],
                      outlined: ""
                    }, {
                      append: _withCtx(() => [
                        (!_disabled.value)
                          ? (_openBlock(), _createBlock(_component_q_icon, {
                              key: 0,
                              name: "mdi-calendar-range",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: _updatedEndDate.value,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_updatedEndDate).value = $event)),
                                      mask: "DD/MM/YYYY"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_13, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: "Close",
                                            color: "primary",
                                            flat: ""
                                          }, null, 512), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "readonly", "rules"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_q_toggle, {
                      color: "primary",
                      label: "Dispute Invoice",
                      modelValue: _disputed.value,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_disputed).value = $event)),
                      inset: "",
                      disable: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_unref(GZGenericSelect), {
                        class: "q-px-sm",
                        multiple: false,
                        items: _unref(_suppliersStore).getSuppliers,
                        name: "SupplierName",
                        label: "Supplier",
                        modelValue: _selectProcessed.value.Supplier,
                        "onUpdate:modelValue": [
                          _cache[12] || (_cache[12] = ($event: any) => ((_selectProcessed.value.Supplier) = $event)),
                          _unref(_delaySageReferences)
                        ],
                        rules: _supplierNameRules
                      }, null, 8, ["items", "modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_unref(GZGenericSelect), {
                        class: "q-px-sm",
                        multiple: false,
                        items: _sageReferences.value,
                        modelValue: _selectProcessed.value.SageReference,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_selectProcessed.value.SageReference) = $event)),
                        name: "Reference",
                        label: "Sage Reference",
                        rules: _nameRules
                      }, null, 8, ["items", "modelValue"])
                    ])
                  ]),
                  (_disputed.value === true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_unref(GZGenericSelect), {
                            multiple: true,
                            items: _unref(_disputeReasonArray),
                            name: "Name",
                            label: "Dispute Reasons",
                            modelValue: _selectProcessed.value.DisputeCode,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_selectProcessed.value.DisputeCode) = $event))
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _createVNode(_component_q_input, {
                          modelValue: _selectProcessed.value.DisputeReason,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_selectProcessed.value.DisputeReason) = $event)),
                          filled: "",
                          clearable: "",
                          type: "textarea",
                          color: "red-12",
                          label: "Enter Dispute Reason"
                        }, null, 8, ["modelValue"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_unref(GZGenericSelect), {
                            multiple: false,
                            loading: _unref(_customersStore).isLoading,
                            disabled: _unref(_customersStore).isLoading,
                            items: _customers.value,
                            name: "CustomerName",
                            label: "Customer",
                            modelValue: _selectProcessed.value.Customer,
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_selectProcessed.value.Customer) = $event))
                          }, null, 8, ["loading", "disabled", "items", "modelValue"])
                        ]),
                        _createVNode(_component_q_input, {
                          label: "Site Postcode Search",
                          outlined: "",
                          "onUpdate:modelValue": [
                            _unref(_delayedGetSites),
                            _cache[17] || (_cache[17] = ($event: any) => ((_postcode).value = $event))
                          ],
                          modelValue: _postcode.value
                        }, null, 8, ["onUpdate:modelValue", "modelValue"])
                      ]))
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: "Cancel",
                color: "accent",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_emit('close', true)))
              }),
              _createVNode(_component_q_btn, {
                outline: "",
                color: "positive",
                label: _disputed.value ? 'Dispute' : 'Submit',
                onClick: _cache[19] || (_cache[19] = _withModifiers(($event: any) => (Submit()), ["prevent","stop"])),
                loading: _isLoading.value
              }, null, 8, ["label", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}
}

})