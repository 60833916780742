import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"max-height":"100vh"} }
const _hoisted_2 = { style: {"width":"230px"} }
const _hoisted_3 = { style: {"width":"200px"} }
const _hoisted_4 = { class: "row full-width" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = {
  class: "col-7",
  style: {"align-self":"center","text-align":"center"}
}
const _hoisted_7 = {
  key: 0,
  style: {"color":"white"}
}
const _hoisted_8 = {
  class: "col-3",
  style: {"text-align":"center"}
}
const _hoisted_9 = { class: "full-width row flex-center q-gutter-sm" }

import { computed, onMounted, Ref, ref, nextTick } from 'vue';
import { BaseService } from '@/services/BaseService';
import {
	SidePanel,
	SiteMappingModal,
	BinMappingModal,
	ScheduleSelectModal,
	DetailsSiteRow,
	DetailsBinRow,
} from '../components/WeightDataMatching';
import { GZSlideout, GZPagination } from '@gz/quasar-components-vue3';
import { useRoute } from 'vue-router';
import QTable from 'quasar/src/components/table/QTable.js';;
import { mdiCog, mdiFilterVariant } from '@quasar/extras/mdi-v7';
import {
	DatamatchDataEntryDto,
	DataMatchingBinResultDTO,
	DatamatchingGroupedResultDTO,
	DataMatchingImportHistoryDTO,
	DataMatchingScheduleDTOShallow,
	OnSiteCountDto,
} from 'greenzonegateway.classes/lib/classes';

interface DataMatchingBinResultDTOInternal extends DataMatchingBinResultDTO {
	InternalSiteRowId: number | null | undefined;
}

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number;
	expand: boolean;
}

interface SiteUpdateEvent {
	internalSiteRowId: number;
	site: DatamatchingGroupedResultDTO[];
	reportImportId: number | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OverweightDataMatchingDetails',
  setup(__props) {

const datamatchingTable = ref<QTable>();
const binMappingModal = ref<typeof BinMappingModal>();
const siteMappingModal = ref<typeof SiteMappingModal>();
const scheduleMappingModal = ref<typeof ScheduleSelectModal>();

const _reportImportId = ref();
const _scheduleRowInternalSiteId = ref();

function ShowModalScheduleMapping(propsIn: {
	rowData: DatamatchingGroupedResultDTO;
	bin: DataMatchingBinResultDTO;
}) {
	_reportImportId.value = propsIn.bin.ReportImportId;
	_scheduleRowInternalSiteId.value = propsIn.rowData.InternalSiteRowId;
	binMappingModal.value?.Close();
	siteMappingModal.value?.Close();
	scheduleMappingModal.value?.Open();
}

const _route = useRoute();
const _service = new BaseService();
const _items = ref<DatamatchingGroupedResultDTOInternal[]>([]);
const _selected = ref();
const _search = ref('');
const _isLoading = ref(false);
const _pagination = ref({
	sortBy: 'desc',
	descending: false,
	page: 1,
	rowsPerPage: 40,
});
const _siteMappingData = ref({
	GroupedResult: {},
	RowIndex: 0,
	InternalSiteRowId: 0,
});
const _binMappingData = ref({
	GroupedResult: {},
	BinResult: {},
	InternalSiteRowId: 0,
});

const _drawerOut = ref(false);
const _drawerData = ref({
	Audit: [] as DataMatchingImportHistoryDTO[],
	ReportImportId: '',
	BinResult: {} as DataMatchingBinResultDTOInternal,
	InternalSiteRowId: 0,
});
const _rowsPerPageOptions = ref([10, 20, 30, 40]);
const _showProblemsOnly = ref(false);
const _showUncommittedOnly = ref(false);
const _showIgnoredOnly = ref(false);
const _settings = ref<{ slimGroups: boolean; slimItems: boolean }>({
	slimGroups: false,
	slimItems: false,
});

const _itemsComputed = computed(() => {
	if (!_items.value.length) {
		return [];
	}
	let itemsToReturn: DatamatchingGroupedResultDTO[] = _items.value;
	if (_search.value) {
		const needle = _search.value.toLowerCase();
		itemsToReturn = itemsToReturn.filter(
			(row: DatamatchingGroupedResultDTO) => {
				return (
					row.MatchedSiteAddress?.toLowerCase().includes(needle) ||
					row.MatchedSiteCity?.toLowerCase().includes(needle) ||
					row.MatchedSiteName?.toLowerCase().includes(needle) ||
					row.MatchedSitePostcode?.toLowerCase().includes(needle) ||
					row.SupplierSiteAddress?.toLowerCase().includes(needle) ||
					row.SupplierSiteCity?.toLowerCase().includes(needle) ||
					row.SupplierSiteName?.toLowerCase().includes(needle) ||
					row.SupplierSitePostcode?.toLowerCase().includes(needle)
				);
			}
		);
	}
	if (_showProblemsOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) => !!items.HasProblems
		);
	}
	if (_showUncommittedOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) => !items.IsSiteCommitted
		);
	}
	if (_showIgnoredOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) =>
				items.BinResults?.find((binResult) => binResult.IsIgnored)
		);
	}
	if (!_showIgnoredOnly.value) {
		itemsToReturn = itemsToReturn.filter(
			(items: DatamatchingGroupedResultDTO) =>
				items.BinResults?.find((binResult) => !binResult.IsIgnored)
		);
	}
	return itemsToReturn;
});

const columns = [
	{
		name: (row: DatamatchingGroupedResultDTO) => row.SupplierName,
		label: 'Supplier Report',
		align: 'left',
		sortable: false,
	},
	{},
	{
		name: 'GreenzoneAI',
		label: 'Greenzone AI',
		align: 'left',
		sortable: false,
	},
	{},
];

function FiltersEnabled() {
	if (_showProblemsOnly.value || _showUncommittedOnly.value) {
		return true;
	}
	return false;
}

function UpdateSiteRow(event: SiteUpdateEvent) {
	let index = _items.value.findIndex(
		(item) => item.InternalSiteRowId === event.internalSiteRowId
	);
	event.site[0].InternalSiteRowId = event.internalSiteRowId;
	_items.value[index] = event.site[0];
	nextTick(() => {
		_items.value[index].expand = true;
		LoadOnSiteData(_items.value[index]);
		LoadAudit(event.reportImportId.toString());
		_drawerData.value.BinResult =
			_items.value[index].BinResults[_drawerData.value.BinIndex];
	});
}

function SearchTable(rows: DatamatchingGroupedResultDTO[], searchVal: string) {
	const needle = searchVal.toLowerCase();
	const rowsOut = rows.filter((row: DatamatchingGroupedResultDTO) => {
		return (
			row.MatchedSiteAddress?.toLowerCase().includes(needle) ||
			row.MatchedSiteCity?.toLowerCase().includes(needle) ||
			row.MatchedSiteName?.toLowerCase().includes(needle) ||
			row.MatchedSitePostcode?.toLowerCase().includes(needle) ||
			row.SupplierSiteAddress?.toLowerCase().includes(needle) ||
			row.SupplierSiteCity?.toLowerCase().includes(needle) ||
			row.SupplierSiteName?.toLowerCase().includes(needle) ||
			row.SupplierSitePostcode?.toLowerCase().includes(needle)
		);
	});

	return rowsOut;
}

function OpenSidePanel(
	binResult: DataMatchingBinResultDTO,
	index: number,
	row: DataMatchingBinResultDTOInternal
) {
	_drawerOut.value = true;
	LoadAudit(binResult.ReportImportId.toString());
	_drawerData.value.ReportImportId = binResult.ReportImportId.toString();
	_drawerData.value.BinResult = binResult as DataMatchingBinResultDTOInternal;
	_drawerData.value.BinIndex = index;
	_drawerData.value.InternalSiteRowId = row.InternalSiteRowId || 0;
}

function ClearSelections() {
	_items.value.forEach((item) =>
		item.BinResults?.forEach((bin) => (bin.selected = undefined))
	);
}

//This function looks more intimidating than it is, annotated for clarity.
//It's trying to replace the schedule in one of the site bins, that's all it does.
function HandleSidePanelBinChanged(eventIn: {
	schedule: DataMatchingScheduleDTOShallow;
	internalSiteRowId: number;
}) {
	let binResultIndex = null;
	//We find the site index
	let index = _items.value.findIndex((item) => {
		if (!item.BinResults) {
			return;
		}
		if (item.InternalSiteRowId === eventIn.internalSiteRowId) {
			for (let i = 0; i < item.BinResults.length; i++) {
				if (
					item.BinResults[i].ReportImportId ===
					Number.parseInt(_drawerData.value.ReportImportId)
				) {
					//We find the bin index while we are at it
					binResultIndex = i;
					return true;
				}
			}
		}
	});
	if (binResultIndex !== null && binResultIndex > -1) {
		//And finally we find the index of the schedule we are replacing
		let oldScheduleIndex = _items.value[index].BinResults[
			binResultIndex
		].MatchedSchedules.findIndex(
			(oldSchedule: DataMatchingScheduleDTOShallow) => {
				return oldSchedule.ScheduleId === eventIn.schedule.ScheduleId;
			}
		);
		//Then use all the indicies we found and replace the schedule.
		_items.value[index].BinResults[binResultIndex].MatchedSchedules[
			oldScheduleIndex
		] = eventIn.schedule;
		//Ensure that drawer data is updated too
		_drawerData.value.BinResult = _items.value[index].BinResults[
			binResultIndex
		] as DataMatchingBinResultDTOInternal;
		LoadAudit(_drawerData.value.ReportImportId);
	}
}

function ShowSiteMappingsModal(eventIn: {
	rowData: DatamatchingGroupedResultDTOInternal;
	index: number;
}) {
	if (!eventIn.rowData || !eventIn.rowData.BinResults) {
		return;
	}
	_siteMappingData.value.GroupedResult = eventIn.rowData;
	_siteMappingData.value.InternalSiteRowId = eventIn.rowData.InternalSiteRowId;
	scheduleMappingModal.value?.Close();
	binMappingModal.value?.Close();
	siteMappingModal.value?.Open();
}

function ShowBinMappingsModal(propsIn: {
	rowData: DatamatchingGroupedResultDTOInternal;
	binResult: DataMatchingBinResultDTO;
	index: number;
}) {
	_binMappingData.value.GroupedResult = propsIn.rowData;
	_binMappingData.value.BinResult = propsIn.binResult;
	_binMappingData.value.InternalSiteRowId = propsIn.rowData.InternalSiteRowId;
	siteMappingModal.value?.Close();
	scheduleMappingModal.value?.Close();
	binMappingModal.value?.Open();
}

function LoadAudit(reportImportId: string) {
	_service
		.Get<any>(`/DataMatchingWeights/Audits/${reportImportId}`)
		.then((dataIn: DataMatchingImportHistoryDTO[]) => {
			_drawerData.value.Audit = dataIn;
		});
}

function LoadOnSiteData(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	_isLoading.value = true;
	_service
		.Get<OnSiteCountDto[]>(
			`/DataMatchingWeights/OnSiteCount?reportImportId=${rowData.BinResults[0].ReportImportId}`
		)
		.then((response) => {
			if (!rowData.BinResults) {
				return;
			}
			for (let i = 0; i < response.length; i++) {
				rowData.BinResults.forEach((Bin) => {
					if (!Bin.ReportImportId) {
						return;
					}
					Bin.onSite = response.find(
						(responseItem) => responseItem.ReportImportId === Bin.ReportImportId
					).QuantityOnSite;
				});
			}
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function GetSpreadSheetDetails() {
	_items.value = [];
	_isLoading.value = true;
	_service
		.Get<DatamatchingGroupedResultDTOInternal[]>(
			`/DataMatchingWeights/Results?importFileId=${_route.params.id}`
		)
		.then((response) => {
			let idIncrement = 0;
			for (let i = 0; i < response.length; i++) {
				response[i].InternalSiteRowId = idIncrement;
				_items.value.push(response[i]);
				idIncrement++;
			}
		})
		.catch((err) => {
			console.log(err);
		})
		.finally(() => {
			_isLoading.value = false;
		});
}

function PostBinSave(
	ReportImportId: string,
	internalSiteRowId: number,
	undo: boolean
) {
	let data: DatamatchDataEntryDto = {
		ReportImportId: Number.parseInt(ReportImportId),
	};
	let urlEnding = undo ? 'UndoCommit' : 'CommitData';
	_isLoading.value = true;
	_service
		.Post<DatamatchDataEntryDto>(`/DataMatchingWeights/${urlEnding}`, data)
		.then((response: DatamatchingGroupedResultDTO[]) => {
			UpdateSiteRow({
				reportImportId: ReportImportId,
				internalSiteRowId: internalSiteRowId,
				site: response,
			});
		})
		.finally(() => {
			_isLoading.value = false;
			LoadAudit(ReportImportId);
		});
}

function PostIgnoreBin(
	isBinIgnored: boolean,
	internalSiteRowId: number,
	ReportImportId: string
) {
	let data = {
		IsIgnored: !isBinIgnored,
		ReportImportId: Number.parseInt(ReportImportId),
	};
	_isLoading.value = true;
	_service
		.Post<DatamatchDataEntryDto>('/DataMatchingWeights/IgnoreRow', data)
		.then((response: DatamatchingGroupedResultDTO[]) => {
			UpdateSiteRow({
				reportImportId: ReportImportId,
				internalSiteRowId: internalSiteRowId,
				site: response,
			});
		})
		.finally(() => {
			_isLoading.value = false;
			LoadAudit(ReportImportId);
		});
}

function SaveSettings() {
	localStorage.setItem('ODMSettings', JSON.stringify(_settings.value));
}

function LoadSettings() {
	let loadedSettings = localStorage.getItem('ODMSettings');
	if (loadedSettings) {
		_settings.value = JSON.parse(loadedSettings);
	}
}

onMounted(() => {
	LoadSettings();
	GetSpreadSheetDetails();
});

return (_ctx: any,_cache: any) => {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_toolbar, { class: "bg-accent text-white q-pr-none" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar_title, null, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Weight Data Matching - Spreadsheet Details - BETA RELEASE")
          ])),
          _: 1
        }),
        _createVNode(_component_q_input, {
          type: "text",
          outlined: "",
          dense: "",
          label: "Filter",
          modelValue: _search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_search).value = $event)),
          dark: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          round: "",
          flat: !FiltersEnabled(),
          outline: FiltersEnabled(),
          size: "large",
          class: "q-ml-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _unref(mdiFilterVariant),
              size: "md"
            }, null, 8, ["name"]),
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("span", null, "Filters", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_menu, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_toggle, {
                    modelValue: _showProblemsOnly.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_showProblemsOnly).value = $event)),
                    label: "Show Problems Only"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_toggle, {
                    modelValue: _showUncommittedOnly.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_showUncommittedOnly).value = $event)),
                    label: "Show Uncommitted Only"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_toggle, {
                    modelValue: _showIgnoredOnly.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_showIgnoredOnly).value = $event)),
                    label: "Show Ignored Only"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["flat", "outline"]),
        _createVNode(_component_q_btn, {
          round: "",
          flat: "",
          size: "large",
          class: "q-mx-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: _unref(mdiCog),
              size: "md"
            }, null, 8, ["name"]),
            _createVNode(_component_q_tooltip, { location: "top" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createElementVNode("span", null, "Settings", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_menu, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_q_toggle, {
                    modelValue: _settings.value.slimGroups,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_settings.value.slimGroups) = $event)),
                    onClick: SaveSettings,
                    label: "Slim Groups",
                    class: "q-pr-md"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_toggle, {
                    modelValue: _settings.value.slimItems,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_settings.value.slimItems) = $event)),
                    onClick: SaveSettings,
                    label: "Slim Items",
                    class: "q-pr-md"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(GZSlideout), {
      style: {"margin-left":"56px"},
      selectedItems: _selected.value,
      breakpoint: 900,
      layoutStyle: 'height: 100vh; position: relative; width: 100%',
      overlay: false,
      drawerStyle: 'overflow: hidden !important',
      drawerWidth: "520",
      modelValue: _drawerOut.value,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_drawerOut).value = $event))
    }, {
      menu: _withCtx(() => [
        _createVNode(_component_q_toolbar, {
          class: "bg-accent",
          style: {"height":"32px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_btn, {
                  flat: "",
                  dense: "",
                  class: "q-ma-sm",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_drawerOut.value = false))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "mdi-chevron-double-right",
                      color: "white"
                    })
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_drawerData.value.BinResult?.WeightEntered?.Display)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Saved On: " + _toDisplayString(_drawerData.value.BinResult?.WeightEntered?.Display), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_q_btn, {
                  dense: "",
                  outline: "",
                  color: "white",
                  class: "q-ma-sm q-px-sm",
                  loading: _isLoading.value,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (
									PostBinSave(
										_drawerData.value.ReportImportId,
										_drawerData.value.InternalSiteRowId,
										!!_drawerData.value.BinResult?.WeightEntered?.Display
									)
								))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(!_drawerData.value.BinResult?.WeightEntered?.Display
										? 'Save'
										: 'Undo'), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_q_scroll_area, {
          style: {"height":"80vh"},
          id: "side-panel-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(SidePanel), {
              class: "q-pb-md",
              isMenuOpen: _drawerOut.value,
              drawerData: _drawerData.value,
              onBinChanged: HandleSidePanelBinChanged
            }, null, 8, ["isMenuOpen", "drawerData"])
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createVNode(_unref(QTable), {
          ref_key: "datamatchingTable",
          ref: datamatchingTable,
          columns: columns,
          rows: _itemsComputed.value,
          "fixed-header": true,
          loading: _isLoading.value,
          filter: _search.value,
          "filter-method": SearchTable,
          "row-key": (row) => row.InternalSiteRowId,
          pagination: _pagination.value,
          "onUpdate:pagination": _cache[8] || (_cache[8] = ($event: any) => ((_pagination).value = $event)),
          style: {"height":"80vh","position":"relative","width":"100%"},
          "hide-pagination": "",
          "virtual-scroll": "",
          "binary-state-sort": "",
          square: "",
          flats: ""
        }, {
          body: _withCtx((props) => [
            _createVNode(_unref(DetailsSiteRow), {
              settings: _settings.value,
              propsIn: props,
              "onUpdate:propsIn": ($event: any) => (props = $event),
              onShowModalScheduleMapping: ShowModalScheduleMapping,
              onShowModalBinMappings: ShowBinMappingsModal,
              onShowModalSiteMappings: ShowSiteMappingsModal,
              onOpenSidePanel: OpenSidePanel,
              onClearSelections: ClearSelections
            }, null, 8, ["settings", "propsIn", "onUpdate:propsIn"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.BinResults, (BinResult, index) => {
              return _withDirectives((_openBlock(), _createBlock(_component_q_tr, {
                key: BinResult.ReportImportId,
                style: _normalizeStyle({
								backgroundColor: BinResult.selected ? 'rgba(0,0,0,0.04)' : null,
							}),
                onClick: ($event: any) => {
								OpenSidePanel(BinResult, index, props.row);
								ClearSelections();
								BinResult.selected = true;
							}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_menu, {
                    "context-menu": "",
                    "touch-position": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_list, {
                        dense: "",
                        style: {"width":"200px"}
                      }, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                            clickable: "",
                            onClick: ($event: any) => (
											PostIgnoreBin(
												BinResult.IsIgnored,
												props.row.InternalSiteRowId,
												BinResult.ReportImportId
											)
										)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(BinResult.IsIgnored ? 'Re-list Bin' : 'Ignore Bin'), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])), [
                            [_directive_close_popup]
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  (
									(_showIgnoredOnly.value && BinResult.IsIgnored) ||
									(!_showIgnoredOnly.value && !BinResult.IsIgnored)
								)
                    ? (_openBlock(), _createBlock(_unref(DetailsBinRow), {
                        key: 0,
                        settings: _settings.value,
                        binResult: BinResult,
                        row: props.row,
                        rowIndex: props.rowIndex,
                        "onUpdate:propsIn": ($event: any) => (props = $event),
                        onShowModalScheduleMapping: ShowModalScheduleMapping,
                        onShowModalBinMappings: ShowBinMappingsModal,
                        onClearSelections: ClearSelections
                      }, null, 8, ["settings", "binResult", "row", "rowIndex", "onUpdate:propsIn"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["style", "onClick"])), [
                [_vShow, props.row.expand]
              ])
            }), 128))
          ]),
          "no-data": _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_q_icon, {
                size: "2em",
                name: "mdi-alert"
              }),
              _cache[13] || (_cache[13] = _createElementVNode("span", null, "No data available", -1))
            ])
          ]),
          _: 1
        }, 8, ["rows", "loading", "filter", "row-key", "pagination"]),
        _createVNode(_unref(GZPagination), {
          class: "q-pt-sm q-px-sm",
          "items-length": _itemsComputed.value.length,
          pagination: _pagination.value,
          rowsPerPageList: _rowsPerPageOptions.value
        }, null, 8, ["items-length", "pagination", "rowsPerPageList"])
      ]),
      _: 1
    }, 8, ["selectedItems", "modelValue"]),
    _createVNode(_unref(SiteMappingModal), {
      ref_key: "siteMappingModal",
      ref: siteMappingModal,
      onSiteUpdated: UpdateSiteRow,
      siteMappingData: _siteMappingData.value
    }, null, 8, ["siteMappingData"]),
    _createVNode(_unref(BinMappingModal), {
      ref_key: "binMappingModal",
      ref: binMappingModal,
      onBinUpdated: UpdateSiteRow,
      binMappingData: _binMappingData.value
    }, null, 8, ["binMappingData"]),
    _createVNode(_unref(ScheduleSelectModal), {
      ref_key: "scheduleMappingModal",
      ref: scheduleMappingModal,
      onBinUpdated: UpdateSiteRow,
      reportImportId: _reportImportId.value,
      InternalSiteRowId: _scheduleRowInternalSiteId.value
    }, null, 8, ["reportImportId", "InternalSiteRowId"])
  ]))
}
}

})